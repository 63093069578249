import axios from "axios";

import API from "../../API";
const urlApi = API.URL + "countries";

async function getCountries() {
  try {
    let setting = {
      method: "GET",
      url: urlApi,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(urlApi, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export default getCountries;
