import axios from "axios";
import API from "../../API";
const urlApi = API.URL + "pawnstatus/";

export async function getCountPawnStatuses(country) {
  try {
    let newUrl = urlApi + "front/" + country;
    let setting = {
      method: "GET",
      url: newUrl,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(newUrl, setting);
    const data = response.data;
    return data;
  } catch (error) {
    // Manejar el error en caso de que la solicitud falle
    console.error(error);
    return null;
  }
}

export async function getPawnStatus() {
  try {
    let setting = {
      method: "GET",
      url: urlApi,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(urlApi, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
