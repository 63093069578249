import React, { useEffect, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../../contexts/ContextProvider";

import "../css/LateralEdit.css";
import { getArticleById } from "../../services/api/articles";
import TextDescripcion from "../components/labelDescription";

export const DetalleLateralArticles = ({ idArticle }) => {
  const { setFlagDetalle } = useStateContext();

  const [type, setType] = useState("");
  const [avatar0, setAvatar0] = useState("");
  const [avatar1, setAvatar1] = useState("");
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");

  useEffect(() => {
    setDatos();
  }, []);

  const setDatos = async () => {
    try {
      await getArticleById(idArticle.articleId)
        .then((response) => {
          setAvatar0(response.articlesImages[0].url);
          setAvatar1(response.articlesImages[1].url);
          setType(response.type);
          setBrand(response.brand);
          setModel(response.model);
          setDescription(response.description);
          setCategory(response.category.name);
        })
        .catch((e) => console.log(e));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
      <div
        className="float-right h-screen dark:text-gray-200  bg-white dark:bg-[#484B52] w-400"
        style={{ overflow: "scroll" }}
      >
        <div className="flex justify-between items-center pt-4 ml-4">
          <p className="font-bold text-lg">Detalle Artículo</p>
          <button
            type="button"
            onClick={() => setFlagDetalle(false)}
            style={{ color: "rgb(153, 171, 180)", borderRadius: "50%" }}
            className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
          >
            <MdOutlineCancel />
          </button>
        </div>
        {/* TODO: Datos del usuario */}
        <div style={{ margin: "1rem" }}>
          <p style={{ fontWeight: "bold", color: "#0087f6" }}>
            Datos del Artículo
          </p>
          <hr />

          <TextDescripcion
            tittle1="Marca"
            description1={brand}
            tittle2="Model"
            description2={model}
          />

          <TextDescripcion
            tittle1="Categoría"
            description1={category}
            tittle2="Tipo de Artículo"
            description2={type}
          />

          <div>
            <p style={{ fontWeight: "bolder" }}> Descipción</p>
            <p style={{ marginLeft: "10px", textAlign: "justify" }}>
              {" "}
              {description}{" "}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <img
              src={avatar0}
              alt="Imagen centrada 1"
              width="200"
              height="150"
              style={{ borderRadius: "10px" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              marginTop: "10px",
            }}
          >
            <img
              src={avatar1}
              alt="Imagen centrada 2"
              width="200"
              height="150"
              style={{ borderRadius: "10px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetalleLateralArticles;
