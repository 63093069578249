import React, { useEffect, useState } from "react";

import { format } from "date-fns";
import { es } from "date-fns/locale";
import { useParams, Link } from "react-router-dom";
import { getAccountsReceivableDetailById } from "../services/api/payments";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const DetailAccountsReceivable = () => {
  const { id } = useParams();
  const [nombre, setNombre] = useState("");
  const [finalDate, setFinalDate] = useState("");
  const [diasRestantes, setDiasRestantes] = useState("");
  const [usuario, setUsuario] = useState("");
  const [asesor, setAsesor] = useState("");
  const [base, setBase] = useState("");
  const [interest, setInteres] = useState("");
  const [amount, setAmount] = useState("");

  useEffect(() => {
    setDatos();
  }, []);

  const setDatos = async () => {
    try {
      await getAccountsReceivableDetailById(id)
        .then((response) => {
          setNombre(response.type);
          setDiasRestantes(response.diasrestantes);
          setFinalDate(
            format(new Date(response.finaldate), "dd 'de' MMMM 'de' yyyy", {
              locale: es,
            })
          );
          setUsuario(response.usuario);
          setAsesor(response.asesor);
          setAmount(response.amount);
          setBase(response.base);
          setInteres(response.interest);
        })
        .catch((e) => console.log(e));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="m-4 mt-1 p-5 bg-white rounded-3xl">
      <Link to="/accountsReceivable" className="mb-4 ml-2">
        <ArrowBackIcon fontSize="large" />
      </Link>
      <div style={{ textAlign: "center", marginBottom: "40px" }}>
        <h1 style={{ fontWeight: "bold", fontSize: "30px" }}>
          Detalle Cuentas por cobrar
        </h1>
      </div>
      {/* <img
        src={avatar0}
        alt="Imagen"
        style={{
          borderRadius: "20px",
          // width: "120px",
          height: "100px",
          backgroundSize: "cover",
        }}
      /> */}
      <div className="grid grid-cols-2 gap-2 text-center">
        <div>
          <div className="mb-2">
            <span className="font-bold">Articulo:</span> {nombre}
          </div>
          <div className="mb-2">
            <span className="font-bold">Usuario: </span> {usuario}
          </div>
          <div className="mb-2">
            <span className="font-bold">Asesor: </span> {asesor}
          </div>
          <div className="mb-2">
            <span className="font-bold">Fecha Final:</span> {finalDate}
          </div>
        </div>
        <div>
          <div className="mb-2">
            <span className="font-bold">Dias restantes:</span>{" "}
            {diasRestantes.days + " dias"}
          </div>

          <div className="mb-2">
            <span className="font-bold">Capital: </span> {base + " $"}
          </div>
          <div className="mb-2">
            <span className="font-bold">Interes: </span> {interest + " $"}
          </div>
          <div className="mb-2">
            <span className="font-bold">Monto: </span> {amount + " $"}
          </div>
        </div>
      </div>
    </div>
  );
};
export default DetailAccountsReceivable;
