import React, { useEffect, useState } from "react";
import { Header } from "../components";
import {
	getCountPawns,
	getCountPayments,
	getContByMonth,
} from "../services/api/pawns";
import { ResponsiveLine } from "@nivo/line";
import { contabilidadPrimero, mesesDelAño } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import {
	Card,
	CardContent,
	Grid,
	Menu,
	Paper,
	Typography,
} from "@mui/material";
import MultipleLineChart from "../components/graph/MultipleLineChart";
import LineChart from "../components/graph/LineChart";
import AccessibleForwardOutlinedIcon from "@mui/icons-material/AccessibleForwardOutlined";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import {
	TableHead,
	TableContainer,
	TableCell,
	TableBody,
	FormControl,
	Select,
	MenuItem,
	TableRow,
	Table,
	Button,
} from "@mui/material";
import "./css/Contability.css";
const Contability = () => {
	const [datos, setDatos] = useState([]);
	const [datosByMonth, setDatosByMonth] = useState([]);
	const [selectedMonth, setSelectedMonth] = useState("Agosto");

	const [showCapital, setShowCapital] = useState("");
	const [showInterest, setShowInterest] = useState("");
	const [showValue, setShowValue] = useState("");
	const [showPago, setShowPago] = useState("");

	const [showMonthCards, setShowMonthCards] = useState(false);
	const { currentColor, currentCountry } = useStateContext();
	const [pagos, setPagos] = useState(null);
	const [pagoTotal, setPagoTotal] = useState("");

	const [graphCategories, setGraphCategories] = useState([]);
	const [graphValues, setGraphValues] = useState([]);

	const [graphValuesInterest, setGraphValuesInterest] = useState([]);
	const [graphValuesBase, setGraphValuesBase] = useState([]);
	const [graphValuesValue, setGraphValuesValue] = useState([]);

	const [graph2Categories, setGraph2Categories] = useState([]);
	const [graph2Values, setGrap2hValues] = useState([]);

	// suseEffect(() => {
	//   const initialShowMonths = {};
	//   mesesDelAño.forEach((mes) => {
	//     initialShowMonths[mes.nombre] = false;
	//   });
	// }, [currentCountry]);

	useEffect(() => {
		actualizarDatos();
	}, [selectedMonth]);

	// useEffect(() => {
	//   getCountPawns(localStorage.getItem("country")).then((resultado) => {
	//     console.log("nuevo");
	//     console.log(resultado);
	//     for (let index = 0; index < resultado.length; index++) {
	//       for (let index2 = 0; index2 < mesesDelAño.length; index2++) {
	//         if (mesesDelAño[index2].valor == resultado[index].month) {
	//           console.log("Entró");
	//           resultado[index].mes = mesesDelAño[index2].nombre;
	//           setShowCapital(0);
	//         }
	//       }
	//     }
	//   });
	// }, [selectedMonth]);

	const toogleTable = () => {
		setShowMonthCards(!showMonthCards);
	};

	const handleNativeSelectChange = (event) => {
		setSelectedMonth(event.target.value);
	};

	async function actualizarDatos() {
		let data1 = await getCountPawns(localStorage.getItem("country"));
		for (let index = 0; index < data1.length; index++) {
			for (let index2 = 0; index2 < mesesDelAño.length; index2++) {
				if (mesesDelAño[index2].valor == data1[index].month)
					data1[index].mes = mesesDelAño[index2].nombre;
			}
		}
		let data2 = await getCountPayments(localStorage.getItem("country"));
		for (let index = 0; index < data2.length; index++) {
			for (let index2 = 0; index2 < mesesDelAño.length; index2++) {
				if (mesesDelAño[index2].valor == data2[index].month) {
					data2[index].mes = mesesDelAño[index2].nombre;
				}
			}
		}
		setPagos(data2);
		let newData1 = await getContByMonth(localStorage.getItem("country"));

		let SumPagos = 0;

		for (let index = 0; index < data2.length; index++) {
			SumPagos += data2[index].pago;
		}

		setPagoTotal(SumPagos);

		if (data1) {
			setShowInterest(data1[0].interest);
			setShowCapital(data1[0].base);
			setShowValue(data1[0].value);
		}

		for (let i = 0; i < contabilidadPrimero.length; ++i) {
			for (let j = 0; j < data1.length; ++j)
				if (data1[j].month == contabilidadPrimero[i].mes) {
					if (data1[j].base) contabilidadPrimero[i].capital = data1[j].base;
					if (data1[j].interest)
						contabilidadPrimero[i].interest = data1[j].interest;
					if (data1[j].value) contabilidadPrimero[i].monto = data1[j].value;
				}
			for (let j = 0; j < data2.length; ++j)
				if (data2[j].month == contabilidadPrimero[i].mes)
					if (data2[j].pago) contabilidadPrimero[i].pago = data2[j].pago;
		}

		const nombres = [
			"Articulos varios",
			"Joyas",
			"Celulares",
			"Laptops y Computadoras",
			"Electrodomesticos",
		];

		const vectorConNombresYMeses = [];

		nombres.forEach((nombre) => {
			for (let mes = 1; mes <= 12; mes++) {
				vectorConNombresYMeses.push({
					id: vectorConNombresYMeses.length + 1,
					name: nombre,
					month: mes,
					monthName: contabilidadPrimero[mes - 1].nombre,
					interest: 0,
					value: 0,
					base: 0,
				});
			}
		});

		for (let i = 0; i < vectorConNombresYMeses.length; ++i) {
			for (let j = 0; j < newData1.length; ++j) {
				if (
					vectorConNombresYMeses[i].month == newData1[j].mes &&
					vectorConNombresYMeses[i].name == newData1[j].name
				) {
					if (newData1[j].interest)
						vectorConNombresYMeses[i].interest = newData1[j].interest;
					if (newData1[j].value)
						vectorConNombresYMeses[i].value = newData1[j].value;
					if (newData1[j].base)
						vectorConNombresYMeses[i].base = newData1[j].base;
				}
			}
		}

		setDatos(contabilidadPrimero);

		let arrCategories = [];
		let arrValues = [
			{
				name: "Pagos",
				data: obtenerKpisData(contabilidadPrimero, "pago"),
			},
			{
				name: "Interes",
				data: obtenerKpisData(contabilidadPrimero, "interest"),
			},
			{
				name: "Capital",
				data: obtenerKpisData(contabilidadPrimero, "capital"),
			},
			{
				name: "Monto",
				data: obtenerKpisData(contabilidadPrimero, "monto"),
			},
		];

		let arrValuesCategoriesInterest = [
			{
				name: "Articulos varios",
				data: obtenerKpisCategoriasInterest(
					vectorConNombresYMeses,
					"Articulos varios"
				),
			},
			{
				name: "Joyas",
				data: obtenerKpisCategoriasInterest(vectorConNombresYMeses, "Joyas"),
			},
			{
				name: "Celulares",
				data: obtenerKpisCategoriasInterest(
					vectorConNombresYMeses,
					"Celulares"
				),
			},
			{
				name: "Laptops y Computadoras",
				data: obtenerKpisCategoriasInterest(
					vectorConNombresYMeses,
					"Laptops y Computadoras"
				),
			},
			{
				name: "Electrodomesticos",
				data: obtenerKpisCategoriasInterest(
					vectorConNombresYMeses,
					"Electrodomesticos"
				),
			},
		];

		let arrValuesCategoriesValue = [
			{
				name: "Articulos varios",
				data: obtenerKpisCategoriasValue(
					vectorConNombresYMeses,
					"Articulos varios"
				),
			},
			{
				name: "Joyas",
				data: obtenerKpisCategoriasValue(vectorConNombresYMeses, "Joyas"),
			},
			{
				name: "Celulares",
				data: obtenerKpisCategoriasValue(vectorConNombresYMeses, "Celulares"),
			},
			{
				name: "Laptops y Computadoras",
				data: obtenerKpisCategoriasValue(
					vectorConNombresYMeses,
					"Laptops y Computadoras"
				),
			},
			{
				name: "Electrodomesticos",
				data: obtenerKpisCategoriasValue(
					vectorConNombresYMeses,
					"Electrodomesticos"
				),
			},
		];

		let arrValuesCategoriesBase = [
			{
				name: "Articulos varios",
				data: obtenerKpisCategoriasBase(
					vectorConNombresYMeses,
					"Articulos varios"
				),
			},
			{
				name: "Joyas",
				data: obtenerKpisCategoriasBase(vectorConNombresYMeses, "Joyas"),
			},
			{
				name: "Celulares",
				data: obtenerKpisCategoriasBase(vectorConNombresYMeses, "Celulares"),
			},
			{
				name: "Laptops y Computadoras",
				data: obtenerKpisCategoriasBase(
					vectorConNombresYMeses,
					"Laptops y Computadoras"
				),
			},
			{
				name: "Electrodomesticos",
				data: obtenerKpisCategoriasBase(
					vectorConNombresYMeses,
					"Electrodomesticos"
				),
			},
		];

		setGraphValuesBase(arrValuesCategoriesBase);
		setGraphValuesValue(arrValuesCategoriesValue);
		setGraphValuesInterest(arrValuesCategoriesInterest);

		contabilidadPrimero.forEach((e) => {
			arrCategories.push(e.nombre);
		});
		setGraphCategories(arrCategories);

		setGraphValues(arrValues);

		setDatosByMonth(vectorConNombresYMeses);
	}
	const obtenerKpisCategoriasValue = (data, name) => {
		let resp = [];
		data.forEach((e) => {
			if (e.name === name) resp.push(e.value.toFixed(2));
		});
		return resp;
	};

	const obtenerKpisCategoriasBase = (data, name) => {
		let resp = [];
		data.forEach((e) => {
			if (e.name === name) resp.push(e.base.toFixed(2));
		});
		return resp;
	};

	const obtenerKpisCategoriasInterest = (data, name) => {
		let resp = [];
		data.forEach((e) => {
			if (e.name === name) resp.push(e.interest.toFixed(2));
		});
		return resp;
	};

	const obtenerKpisData = (data, nombre) => {
		let resp = [];
		data.forEach((e) => {
			resp.push(Number(e[nombre]).toFixed(2));
		});
		return resp;
	};
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={12}>
				<Header category="Contabilidad" />
			</Grid>

			<Grid item xs={12} md={6} lg={3}>
				<Card>
					<CardContent>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div style={{ display: "flex", alignItems: "center" }}>
								<div
									className="circle"
									style={{ backgroundColor: "#F39522" }}></div>
								<span style={{ marginLeft: 8 }}>Capital</span>
							</div>
							<HowToRegOutlinedIcon />
						</div>
						<b style={{ fontSize: 24 }}>
							{showCapital ? showCapital.toFixed(2) : null}
							{localStorage.getItem("country") === "Ecuador"
								? "$"
								: localStorage.getItem("country") === "Chile"
								? " CLP"
								: null}
						</b>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} md={6} lg={3}>
				<Card>
					<CardContent>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div style={{ display: "flex", alignItems: "center" }}>
								<div className="circle"></div>
								<span style={{ marginLeft: 8 }}>Interes</span>
							</div>
							<HowToRegOutlinedIcon />
						</div>
						<b style={{ fontSize: 24 }}>
							{showInterest ? showInterest.toFixed(2) : null}
							{localStorage.getItem("country") === "Ecuador"
								? "$"
								: localStorage.getItem("country") === "Chile"
								? " CLP"
								: null}
						</b>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} md={6} lg={3}>
				<Card>
					<CardContent>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div style={{ display: "flex", alignItems: "center" }}>
								<div
									className="circle"
									style={{ backgroundColor: "#6ED591" }}></div>
								<span style={{ marginLeft: 8 }}>Monto Total</span>
							</div>
							<HowToRegOutlinedIcon />
						</div>
						<b style={{ fontSize: 24 }}>
							{showValue ? showValue.toFixed(2) : null}
							{localStorage.getItem("country") === "Ecuador"
								? "$"
								: localStorage.getItem("country") === "Chile"
								? " CLP"
								: null}
						</b>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} md={6} lg={3}>
				<Card>
					<CardContent>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<div style={{ display: "flex", alignItems: "center" }}>
								<div
									className="circle"
									style={{ backgroundColor: "#BA6ED5" }}></div>
								<span style={{ marginLeft: 8 }}>Pagos</span>
							</div>
							<HowToRegOutlinedIcon />
						</div>
						<b style={{ fontSize: 24 }}>
							{pagoTotal ? pagoTotal.toFixed(2) : ""}
							{localStorage.getItem("country") === "Ecuador"
								? "$"
								: localStorage.getItem("country") === "Chile"
								? " CLP"
								: null}
						</b>
					</CardContent>
				</Card>
			</Grid>

			<div
				style={{
					width: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}>
				{graphValues.length != 0 && (
					<MultipleLineChart
						text="Facturas"
						values={graphValues}
						categories={graphCategories}
					/>
				)}
			</div>

			<div
				style={{
					width: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}>
				<p
					style={{
						textAlign: "center",
						fontWeight: "bolder",
						color: "#444444",
					}}>
					Interes
				</p>
			</div>
			<Grid item xs={12} md={12}>
				{graphValuesInterest.length != 0 && (
					<MultipleLineChart
						text="  "
						values={graphValuesInterest}
						categories={graphCategories}
					/>
				)}
			</Grid>

			<div
				style={{
					width: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}>
				<p
					style={{
						textAlign: "center",
						fontWeight: "bolder",
						color: "#444444",
					}}>
					Base
				</p>
			</div>
			<Grid item xs={12} md={12}>
				{graphValuesBase.length != 0 && (
					<MultipleLineChart
						text="  "
						values={graphValuesBase}
						categories={graphCategories}
					/>
				)}
			</Grid>

			<div
				style={{
					width: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}>
				<p
					style={{
						textAlign: "center",
						fontWeight: "bolder",
						color: "#444444",
					}}>
					Valor
				</p>
			</div>
			<Grid item xs={12} md={12}>
				{graphValuesValue.length != 0 && (
					<MultipleLineChart
						text="  "
						values={graphValuesValue}
						categories={graphCategories}
					/>
				)}
			</Grid>

			{/* <Grid item xs={12} md={12}>
        {graphValues.length != 0 && (
          <LineChart
            text="Ingresos Totales"
            //values={graph2Values}
            values={[
              {
                name: "Desktops",
                data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
              },
            ]}
            //categories={graph2Categories}
            categories={[
              "Enero",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
            ]}
          />
        )}
      </Grid> */}
			<Grid item xs={12} md={12}>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						marginBottom: 10,
					}}>
					<div>
						<p
							style={{
								fontWeight: "bold",
							}}>
							Ingresos por categorías
						</p>
					</div>

					<div style={{ display: "flex", alignItems: "center" }}>
						<h1 style={{ margin: "5px" }}>
							<b>Mes:</b>
						</h1>
						<FormControl sx={{ minWidth: 120 }} size="small">
							<Select
								labelId="demo-select-small-label"
								id="demo-select-small"
								value={selectedMonth}
								onChange={handleNativeSelectChange}
								style={{ backgroundColor: "#ffffff", borderRadius: "8px" }}>
								{mesesDelAño.map((item) => (
									<MenuItem key={item.nombre} value={item.nombre}>
										{item.nombre}
									</MenuItem>
								))}
								{/* <MenuItem key={"Total"} value={"Total"}>
                  Total
                </MenuItem> */}
								{/*  <MenuItem value={false}>Eliminados</MenuItem> */}
							</Select>
						</FormControl>
					</div>
				</div>
				<TableContainer component={Paper}>
					<Table aria-label="a dense table">
						<TableHead>
							<TableRow>
								<TableCell align="center">Categorías</TableCell>
								<TableCell align="center">Ingresos</TableCell>
								<TableCell align="center">Comisión</TableCell>
								<TableCell align="center">Ganancia Neta</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{datosByMonth.map((item) =>
								item.monthName == selectedMonth ? (
									<TableRow>
										<TableCell align="center">{item.name}</TableCell>
										<TableCell align="center">
											{item.base.toFixed(2)}{" "}
											{localStorage.getItem("country") === "Ecuador"
												? "$"
												: localStorage.getItem("country") === "Chile"
												? " CLP"
												: null}
										</TableCell>
										<TableCell align="center">
											{item.value.toFixed(2)}{" "}
											{localStorage.getItem("country") === "Ecuador"
												? "$"
												: localStorage.getItem("country") === "Chile"
												? " CLP"
												: null}{" "}
										</TableCell>
										<TableCell align="center">
											{item.interest.toFixed(2)}{" "}
											{localStorage.getItem("country") === "Ecuador"
												? "$"
												: localStorage.getItem("country") === "Chile"
												? " CLP"
												: null}
										</TableCell>
									</TableRow>
								) : (
									""
								)
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			{/* <Grid item xs={12} md={12}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <div>
            <p
              style={{
                fontWeight: "bold",
              }}
            >
              Ingresos por Clientes
            </p>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">Cliente</TableCell>
                <TableCell align="center">Tipo</TableCell>
                <TableCell align="center">F. de Pago</TableCell>
                <TableCell align="center">Modelo</TableCell>
                <TableCell align="center">Marca</TableCell>
                <TableCell align="center">Balance</TableCell>
                <TableCell align="center">Monto</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">61</TableCell>
                <TableCell align="center">Laura Sanchez</TableCell>
                <TableCell align="center">Corriente</TableCell>
                <TableCell align="center">01/09/2023</TableCell>
                <TableCell align="center">4 Slim</TableCell>
                <TableCell align="center">PlayStation</TableCell>
                <TableCell align="center">$140.56</TableCell>
                <TableCell align="center">$ 140.56</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid> */}
		</Grid>
	);
};

export default Contability;
