import React from "react";

const TextDescripcion = ({ tittle1, description1, tittle2, description2 }) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", margin: "10px" }}>
      <div style={{ flex: "1" }}>
        <p style={{ fontWeight: "bolder" }}> {tittle1}</p>
        <p style={{ marginLeft: "10px" }}> {description1} </p>
      </div>

      <div style={{ flex: "1" }}>
        <p style={{ fontWeight: "bolder" }}> {tittle2}</p>
        <p style={{ marginLeft: "10px" }}> {description2} </p>
      </div>
    </div>
  );
};
export default TextDescripcion;
