import { React, useEffect, useState } from "react";
import "./css/EditUsers.css";
import { Navigate } from "react-router-dom";

import Swal from "sweetalert2";

import { TextField, Box, InputAdornment, Button } from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";

import { useParams, Link } from "react-router-dom";
import { boxStyles } from "./css/inserUsersStyle";
import { useStateContext } from "../contexts/ContextProvider";

import { insertSales } from "../services/api/sales";
import { getPawnDetail } from "../services/api/pawns";
import { getArticlesImagesByIdArticle } from "../services/api/articles";

const InsertVencidos = () => {
	const { id } = useParams();
	// Compraventa
	const [description, setDescription] = useState("");

	const [avatar0, setAvatar0] = useState("");
	const [avatar1, setAvatar1] = useState("");

	const [isInsert, setIsInsert] = useState(false);
	const [newPrice, setNewPrice] = useState("");
	const [articleName, setArticleName] = useState("");
	const [base, setBase] = useState(0);
	const [interest, setInterest] = useState(0);
	const [amount, setAmount] = useState(0);
	const [observation, setObservation] = useState("");
	const [descriptionPawn, setDescriptionPawn] = useState("");
	const [asesor, setAsesor] = useState("");
	const { currentColor } = useStateContext();

	useEffect(() => {
		setDatos();
	}, []);

	const setDatos = async () => {
		// console.log("hOLI");
		await getPawnDetail(id)
			.then((response) => {
				setArticleName(response.type);
				setInterest(response.interest);
				setAmount(response.value);
				setObservation(response.observation);
				setDescriptionPawn(response.description);
				setBase(response.base);
				setAsesor(response.asesor);

				getArticlesImagesByIdArticle(response.articleid) // Colocar Imagenes
					.then((response) => {
						setAvatar0(response[0].url);
						setAvatar1(response[1].url);
					})
					.catch((e) => console.log(e));
			})
			.catch((e) => console.log(e));
	};

	const handleDescriptionChange = (event) => {
		setDescription(event.target.value);
	};

	const handleNewPriceChange = (event) => {
		const inputValue = event.target.value;
		if (!isNaN(inputValue)) {
			setNewPrice(inputValue);
		}
	};

	const guardarDatos = async () => {
		if (description.length > 0) {
			if (newPrice > 0) {
				const sales = {
					pawnId: id,
					price: newPrice,
					description: description,
				};
				insertSales(sales);
				setIsInsert(true);
			} else {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Ingrese un precio correcto!",
				});
			}
		} else {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Ingrese todos los campos!",
			});
		}
	};

	return (
		<div className="m-4 mt-1 p-5 bg-white rounded-3xl">
			{isInsert ? <Navigate to="/vencidos" /> : null}

			<Link to="/vencidos" className="mb-4 ml-2">
				<ArrowBackIcon fontSize="large" />
			</Link>

			<div style={{ textAlign: "center", marginBottom: "40px" }}>
				<h1 style={{ fontWeight: "bold", fontSize: "30px" }}>
					Insertar Producto a la Venta
				</h1>
			</div>

			<div style={{ display: "flex", marginBottom: "40px" }}>
				<img
					src={avatar0}
					alt="Imagen 1"
					className="w-32 h-32 rounded-lg border-2 my-4 mx-auto"
				/>
				<img
					src={avatar1}
					alt="Imagen 2"
					className="w-32 h-32 rounded-lg border-2 my-4 mx-auto"
				/>
			</div>

			<div
				className="grid grid-cols-2 gap-2 text-center"
				style={{ display: "flex", justifyContent: "space-around" }}>
				<div style={{ width: "350px" }}>
					<div className="mb-2">
						<span className="font-bold">Artículo: </span> {articleName}
					</div>

					<div className="mb-2">
						<span className="font-bold">Descripción: </span> {descriptionPawn}
					</div>
					<div className="mb-2">
						<span className="font-bold">Asesor: </span> {asesor}
					</div>

					<div className="mb-2">
						<span className="font-bold">Capital: </span>{" "}
						{localStorage.getItem("country") === "Ecuador"
							? base.toFixed(2) + " $"
							: localStorage.getItem("country") === "Chile"
							? base.toFixed(0) + " CLP"
							: null}
					</div>

					<div className="mb-2">
						<span className="font-bold">Interes: </span>{" "}
						{localStorage.getItem("country") === "Ecuador"
							? interest.toFixed(2) + " $"
							: localStorage.getItem("country") === "Chile"
							? interest.toFixed(0) + " CLP"
							: null}
					</div>

					<div className="mb-2">
						<span className="font-bold">Monto: </span>{" "}
						{localStorage.getItem("country") === "Ecuador"
							? amount.toFixed(2) + " $"
							: localStorage.getItem("country") === "Chile"
							? amount.toFixed(0) + " CLP"
							: null}
					</div>
				</div>
				<div>
					<Box sx={boxStyles}>
						<TextField
							label="Nuevo Precio"
							value={newPrice}
							onChange={handleNewPriceChange}
							inputProps={{
								pattern: "[0-9]*",
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<LocalAtmIcon />
									</InputAdornment>
								),
							}}
						/>
					</Box>
					<Box sx={boxStyles}>
						<TextField
							label="Description "
							value={description}
							multiline
							onChange={handleDescriptionChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<AccountCircle />
									</InputAdornment>
								),
							}}
						/>
					</Box>
				</div>
			</div>

			<div style={{ display: "flex", justifyContent: "center" }}>
				<Button
					onClick={guardarDatos}
					variant="contained"
					style={{ backgroundColor: currentColor, marginRight: "100px" }}>
					Aceptar
				</Button>

				<Link to="/vencidos">
					<Button style={{ backgroundColor: currentColor }} variant="contained">
						Cancelar
					</Button>
				</Link>
			</div>
		</div>
	);
};

export default InsertVencidos;
