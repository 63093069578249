import { Route, Routes } from "react-router-dom";
import {
  AccountsReceivable,
  Administrativo,
  Articles,
  Clients,
  Contability,
  CustomerPayments,
  Dashboard,
  DetailAccountsReceivable,
  DetailArticle,
  DetailCustomerPayments,
  DetailPawn,
  DetailMotorized,
  DetailUser,
  DetailSale,
  InsertUsers,
  InsertPawn,
  InsertMotorized,
  InsertVencidos,
  EditArticles,
  EditUsers,
  EditMotorizeds,
  EditPawn,
  Login,
  MyProfile,
  Motorist,
  Pawns,
  Users,
  Vencidos,
  Sales,
} from "../pages";

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/accountsReceivable" element={<AccountsReceivable />} />
      <Route
        path="/accountsReceivable/detail/:id"
        element={<DetailAccountsReceivable />}
      />
      <Route path="/administrativo" element={<Administrativo />} />
      <Route path="/articles" element={<Articles />} />
      <Route path="/articles/detail/:id" element={<DetailArticle />} />
      <Route path="/clients" element={<Clients />} />
      <Route path="/contability" element={<Contability />} />
      <Route path="/customerPayments" element={<CustomerPayments />} />
      <Route
        path="/customerPayments/detail/:id"
        element={<DetailCustomerPayments />}
      />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/editArticles/:id" element={<EditArticles />} />
      <Route path="/editMotorizeds/:id" element={<EditMotorizeds />} />
      <Route path="/editPawn/:id" element={<EditPawn />} />
      <Route path="/editUsers/:id" element={<EditUsers />} />
      <Route path="/insertMotorized" element={<InsertMotorized />} />
      <Route path="/insertPawn" element={<InsertPawn />} />
      <Route path="/insertUsers" element={<InsertUsers />} />
      <Route path="/insertVencidos/:id" element={<InsertVencidos />} />
      <Route path="/login" element={<Login />} />
      <Route path="/motorist" element={<Motorist />} />
      <Route path="/motorized/detail/:id" element={<DetailMotorized />} />
      <Route path="/pawns" element={<Pawns />} />
      <Route path="/pawns/detail/:id" element={<DetailPawn />} />
      <Route path="/sales" element={<Sales />} />
      <Route path="/sales/detail/:id" element={<DetailSale />} />
      <Route path="/users" element={<Users />} />
      <Route path="/users/detail/:id" element={<DetailUser />} />
      <Route path="/vencidos/" element={<Vencidos />} />
      <Route path="/myProfile/" element={<MyProfile />} />
 

    </Routes>
  );
};
export default Routers;
