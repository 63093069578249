import { React, useEffect, useState } from "react";
import "./css/EditUsers.css";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { insertUser } from "../services/api/users";
import getRols from "../services/api/rols";
import {
  TextField,
  Box,
  InputLabel,
  FormControl,
  Button,
  Select,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useStateContext } from "../contexts/ContextProvider";

import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import getCountries from "../services/api/country";
import { boxStyles } from "./css/inserUsersStyle";
import dayjs from "dayjs";
import { uploadImageUser } from "../services/firebase/config";
// import "./css/insertUsers.css";

const InsertUsers = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [cedula, setCedula] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [rol, setRol] = useState("1");
  const [roles, setRoles] = useState([]);
  const [pass1, setPass1] = useState("");
  const [fechaSeleccionada, setFechaSeleccionada] = useState(null);
  const [pass2, setPass2] = useState("");
  const [image, setImage] = useState(null);
  const [link, setLink] = useState("");
  const [flag, setFlag] = useState(false);
  const { currentColor } = useStateContext();
  const [country, setCountry] = useState({ country: "Chile", id: "1" });
  const [countries, setCountries] = useState([]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    getRols()
      .then((response) => {
        setRoles(response);
      })
      .catch((e) => console.log(e));

    getCountries()
      .then((response) => {
        setCountries(response);
      })
      .catch((e) => console.log(e));
  }, [rol]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCountryChange = (event) => {
    const selectedCountryValue = event.target.value;
    const selectedCountryObject = countries.find(
      (country) => country.country === selectedCountryValue
    );
    setCountry(selectedCountryObject);
  };

  const handleCedulaChange = (event) => {
    setCedula(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handlePass1Change = (event) => {
    setPass1(event.target.value);
  };

  const handlePass2Change = (event) => {
    setPass2(event.target.value);
  };

  const handleFechaChange = (event) => {
    setFechaSeleccionada(event);
  };

  const handleRoleChange = (event) => {
    setRol(event.target.value);
  };

  const colocarImagen = async (event) => {
    const selectedFile = event.target.files[0];
    setImage(selectedFile);
    const imageURL = URL.createObjectURL(selectedFile);
    setLink(imageURL);
  };

  const guardarDatos = async () => {
    if (
      name.length !== 0 ||
      lastName.length !== 0 ||
      email.length !== 0 ||
      cedula.length !== 0 ||
      phone.length !== 0 ||
      address.length !== 0 ||
      pass1.length !== 0
    ) {
      if (pass1 === pass2) {
        const urlImg = await uploadImageUser(image);
        const usuario = {
          firstName: name,
          avatar: urlImg,
          lastName: lastName,
          email: email,
          cedula: cedula,
          birthDay: fechaSeleccionada,
          phone: phone,
          address: address,
          rolId: rol,
          country: country,
          password: pass1,
          type: true,
        };
        console.log(usuario);
        const nueva = await insertUser(usuario);
        if (nueva) {
          setFlag(true);
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Las contraseñas son diferentes!",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Ingrese todos los campos!",
      });
    }
  };

  return (
    <div className="m-4 mt-1 p-5 bg-white rounded-3xl">
      {flag ? <Navigate to="/users" /> : null}
      <Link to="/Users" className="mb-4 ml-2">
        <ArrowBackIcon fontSize="large" />
      </Link>
      <div style={{ textAlign: "center", marginBottom: "40px" }}>
        <h1 style={{ fontWeight: "bold", fontSize: "30px" }}>
          Insertar Usuario
        </h1>
      </div>

      <div
        className="grid grid-cols-2 gap-2 text-center"
        style={{ display: "flex", justifyContent: "space-around" }}
      >
        <div>
          <Box sx={boxStyles}>
            <TextField
              id="inputNombre"
              label="Nombre"
              value={name}
              onChange={handleNameChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={boxStyles}>
            <TextField
              id="inputApellido"
              label="Apellido"
              value={lastName}
              onChange={handleLastNameChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={boxStyles}>
            <TextField
              id="inputCedula"
              label="Cedula"
              value={cedula}
              onChange={handleCedulaChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BrandingWatermarkIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={boxStyles}>
            <TextField
              id="inputDireccion"
              label="Direccion"
              value={address}
              onChange={handleAddressChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MyLocationIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={boxStyles}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={fechaSeleccionada ? dayjs(fechaSeleccionada) : null}
                onChange={handleFechaChange}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </Box>
        </div>
        <div>
          <Box sx={boxStyles}>
            <TextField
              id="inputEmail"
              label="Email"
              value={email}
              onChange={handleEmailChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={boxStyles}>
            <TextField
              id="inputCelular"
              label="Celular"
              value={phone}
              onChange={handlePhoneChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SmartphoneIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={boxStyles}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Ingrese Contraseña
              </InputLabel>
              <OutlinedInput
                id="pass1"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Contraseña"
                value={pass1}
                onChange={handlePass1Change}
              />
            </FormControl>
          </Box>

          <Box sx={boxStyles}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Confirme Contraseña
              </InputLabel>
              <OutlinedInput
                id="pass2"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Contraseña"
                value={pass2}
                onChange={handlePass2Change}
              />
            </FormControl>
          </Box>

          {/* TODO: este es rol  */}

          {/* <Box sx={boxStyles}>
            <FormControl>
              <InputLabel variant="standard">Rol</InputLabel>
              <NativeSelect onChange={handleRoleChange} value={rol}>
                {roles.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.rol}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Box> */}
          {/* Este es el nuevo */}
          <Box sx={boxStyles}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">Rol</InputLabel>
              <Select
                native
                value={rol}
                onChange={handleRoleChange}
                label="Rol"
                inputProps={{
                  name: "rol",
                  id: "outlined-age-native-simple",
                }}
              >
                {roles.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.rol}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={boxStyles}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">País</InputLabel>
              <Select
                native
                value={country.country}
                onChange={handleCountryChange}
                label="Country"
                inputProps={{
                  name: "country",
                  id: "outlined-age-native-simple",
                }}
              >
                {countries.map((countryObj) => (
                  <option key={countryObj.id} value={countryObj.country}>
                    {countryObj.country}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
      </div>

      <div className="container">
        <img
          src={link}
          alt="Imagen"
          className="w-32 h-32 rounded-lg border-2 my-4 mx-auto"
          style={{
            borderRadius: "20px",
            backgroundSize: "cover",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "25px",
        }}
      >
        <Box sx={boxStyles}>
          <input type="file" name="" id="" onChange={colocarImagen} />
        </Box>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          style={{ backgroundColor: currentColor, marginRight: "100px" }}
          onClick={guardarDatos}
          variant="contained"
        >
          Aceptar
        </Button>
        <Link to="/Users">
          <Button style={{ backgroundColor: currentColor }} variant="contained">
            Cancelar
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default InsertUsers;
