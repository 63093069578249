import React, { useEffect, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../../contexts/ContextProvider";

import "../css/LateralEdit.css";
import { getUsersById } from "../../services/api/users";
import TextDescripcion from "../components/labelDescription";
import { format } from "date-fns";
import { es } from "date-fns/locale";

export const DetalleLateralUsers = ({ idUsers }) => {
  const { setFlagDetalle } = useStateContext();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [cedula, setCedula] = useState("");
  const [phone, setPhone] = useState("");
  const [rol, setRol] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [avatar, setAvatar] = useState("");

  useEffect(() => {
    setDatos();
  }, []);

  const setDatos = async () => {
    try {
      const response = await getUsersById(idUsers.userId);
      console.log(response);
      setAvatar(response.avatar);
      setCedula(response.cedula);
      setFirstName(response.firstName);
      setLastName(response.lastName);
      setEmail(response.email);
      setPhone(response.phone);
      setBirthDay(
        format(new Date(response.birthDay), "dd 'de' MMMM 'de' yyyy", {
          locale: es,
        })
      );
      setRol(response.rol.rol);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
      <div
        className="float-right h-screen dark:text-gray-200  bg-white dark:bg-[#484B52] w-400"
        style={{ overflow: "scroll" }}
      >
        <div className="flex justify-between items-center pt-4 ml-4">
          <p className="font-bold text-lg">Detalle Usuario</p>
          <button
            type="button"
            onClick={() => setFlagDetalle(false)}
            style={{ color: "rgb(153, 171, 180)", borderRadius: "50%" }}
            className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
          >
            <MdOutlineCancel />
          </button>
        </div>
        {/* TODO: Datos del usuario */}
        <div style={{ margin: "1rem" }}>
          <p style={{ fontWeight: "bold", color: "#0087f6" }}>
            Datos de Usuario
          </p>
          <hr />

          <TextDescripcion
            tittle1="Cedula"
            description1={cedula}
            tittle2="Celular"
            description2={phone}
          />

          <TextDescripcion
            tittle1="Nombre"
            description1={firstName + " " + lastName}
            tittle2="Email"
            description2={email}
          />

          <TextDescripcion
            tittle1="Fecha Nac."
            description1={birthDay}
            tittle2="Rol"
            description2={rol}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <img
              src={
                avatar
                  ? avatar
                  : "https://firebasestorage.googleapis.com/v0/b/pignoora.appspot.com/o/Experimental%2FUsers%2F7d6503e1-9805-49fd-9642-377c2483586a?alt=media&token=d0fb6042-94f6-409a-b9a7-fd0493e004ca"
              }
              alt="Imagen centrada"
              width="200"
              height="150"
              style={{ borderRadius: "10px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetalleLateralUsers;
