import { React, useEffect, useState } from "react";
import "./css/EditUsers.css";
import { Link } from "react-router-dom";
import getRols from "../services/api/rols";
import {
	TextField,
	Box,
	InputLabel,
	NativeSelect,
	FormControl,
	Button,
	InputAdornment,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MyLocationIcon from "@mui/icons-material/MyLocation";

import { boxStyles } from "./css/inserUsersStyle";
import dayjs from "dayjs";
import { uploadImageUser } from "../services/firebase/config";
// import "./css/insertUsers.css";

const InsertPawn = () => {
	const [nameArticle, setNameArticle] = useState("");
	const [email, setEmail] = useState("");
	const [address, setAddress] = useState("");
	const [selectedRol, setSelectedRol] = useState("");
	const [rol, setRol] = useState("");
	const [roles, setRoles] = useState([]);
	const [fechaSeleccionada, setFechaSeleccionada] = useState(null);
	const [file, setFile] = useState(null);

	useEffect(() => {
		getRols()
			.then((response) => {
				setRoles(response);
			})
			.catch((e) => console.log(e));
	}, [rol]);

	const handleNameArticleChange = (event) => {
		setNameArticle(event.target.value);
	};

	const handleEmailChange = (event) => {
		setEmail(event.target.value);
	};

	const handleAddressChange = (event) => {
		setAddress(event.target.value);
	};

	const handleFechaChange = (event) => {
		setFechaSeleccionada(event);
	};

	const handleRoleChange = (event) => {
		setRol(event.target.value);
		setSelectedRol(event.target.value);
		console.log(selectedRol);
	};

	const guardarDatos = async () => {
		const urlImg = await uploadImageUser(file);
		console.log(urlImg);
		// const article = {
		//   type: nameArticle,
		// };

		// await insertUser(usuario);
	};

	return (
		<div className="m-4 mt-1 p-5 bg-white rounded-3xl">
			<Link to="/Users" className="mb-4 ml-2">
				<ArrowBackIcon fontSize="large" />
			</Link>
			<div style={{ textAlign: "center", marginBottom: "40px" }}>
				<h1 style={{ fontWeight: "bold", fontSize: "30px" }}>
					Insertar Compraventa
				</h1>
			</div>

			<div className="grid grid-cols-2 gap-2 text-center">
				<div>
					<Box sx={boxStyles}>
						<TextField
							id="inputNombre"
							label="Artículo"
							value={nameArticle}
							onChange={handleNameArticleChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<AccountCircle />
									</InputAdornment>
								),
							}}
						/>
					</Box>

					<Box sx={boxStyles}>
						<TextField
							id="inputDireccion"
							label="Direccion"
							value={address}
							onChange={handleAddressChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<MyLocationIcon />
									</InputAdornment>
								),
							}}
						/>
					</Box>
					<Box>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								value={fechaSeleccionada ? dayjs(fechaSeleccionada) : null}
								onChange={handleFechaChange}
							/>
						</LocalizationProvider>
					</Box>
				</div>
				<div>
					<Box sx={boxStyles}>
						<TextField
							id="inputEmail"
							label="Email"
							value={email}
							onChange={handleEmailChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<AlternateEmailIcon />
									</InputAdornment>
								),
							}}
						/>
					</Box>

					<Box sx={boxStyles}>
						<FormControl>
							<InputLabel variant="standard">Rol</InputLabel>
							<NativeSelect onChange={handleRoleChange} value={rol}>
								{roles.map((role) => (
									<option key={role.id} value={role.id}>
										{role.rol}
									</option>
								))}
							</NativeSelect>
						</FormControl>
					</Box>
				</div>
			</div>

			<div className="container">
				{/* TODO: DAYS */}

				{/* TODO: password */}

				{/* TODO: AQUI TA SUBIR */}
				<Box sx={boxStyles}>
					{/* <UploadFile></UploadFile> */}
					<input
						type="file"
						name=""
						id=""
						onChange={(e) => setFile(e.target.files[0])}
					/>
				</Box>

				{/* Estado */}
			</div>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<Link to="/Pawns">
					<Button onClick={guardarDatos} variant="contained">
						Aceptar
					</Button>
					<Button variant="contained">Cancelar</Button>
				</Link>
			</div>
		</div>
	);
};

export default InsertPawn;
