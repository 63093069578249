import React, { useEffect, useState } from "react";

import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../../contexts/ContextProvider";
import {
  TextField,
  Select,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Button,
  IconButton,
} from "@mui/material";

import { styles } from "../format";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

import getRols from "../../services/api/rols";
import { uploadImageUser } from "../../services/firebase/config";
import { insertUser } from "../../services/api/users";

import Swal from "sweetalert2";
import getCountries from "../../services/api/country";
import "../css/LateralEdit.css";
import { EmailOutlined, PhoneAndroid } from "@material-ui/icons";
import InputFile from "./InputFile";

export const InsertLateralUsers = () => {
  const { setFlagInsertUser } = useStateContext();
  const [showPassword, setShowPassword] = useState(false);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [cedula, setCedula] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [rol, setRol] = useState("1");
  const [roles, setRoles] = useState([]);
  const [pass1, setPass1] = useState("");
  const [fechaSeleccionada, setFechaSeleccionada] = useState(null);
  const [pass2, setPass2] = useState("");
  const [image, setImage] = useState(null);
  const [link, setLink] = useState("");
  const [flag, setFlag] = useState(false);
  const { currentColor } = useStateContext();
  const [country, setCountry] = useState({ country: "Chile", id: "1" });
  const [countries, setCountries] = useState([]);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    getRols()
      .then((response) => {
        setRoles(response);
      })
      .catch((e) => console.log(e));
    getCountries()
      .then((response) => {
        setCountries(response);
      })
      .catch((e) => console.log(e));
  }, [rol]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCountryChange = (event) => {
    const selectedCountryValue = event.target.value;
    const selectedCountryObject = countries.find(
      (country) => country.country === selectedCountryValue
    );
    setCountry(selectedCountryObject);
  };

  const handleCedulaChange = (event) => {
    setCedula(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handlePass1Change = (event) => {
    setPass1(event.target.value);
  };

  const handlePass2Change = (event) => {
    setPass2(event.target.value);
  };

  const handleFechaChange = (event) => {
    setFechaSeleccionada(event);
  };

  const handleRoleChange = (event) => {
    setRol(event.target.value);
  };

  const guardarDatos = async () => {
    if (
      name.length !== 0 ||
      lastName.length !== 0 ||
      email.length !== 0 ||
      cedula.length !== 0 ||
      phone.length !== 0 ||
      address.length !== 0 ||
      pass1.length !== 0
    ) {
      if (pass1 === pass2) {
        const urlImg = await uploadImageUser(image);
        const usuario = {
          firstName: name,
          avatar: urlImg,
          lastName: lastName,
          email: email,
          cedula: cedula,
          birthDay: fechaSeleccionada,
          phone: phone,
          address: address,
          rolId: rol,
          country: country,
          password: pass1,
          type: true,
        };
        console.log(usuario);
        const nueva = await insertUser(usuario);
        if (nueva) {
          setFlagInsertUser(false);
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Las contraseñas son diferentes!",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Ingrese todos los campos!",
      });
    }
  };

  return (
    <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
      <div
        className="float-right h-screen dark:text-gray-200  bg-white dark:bg-[#484B52] w-400"
        style={{ overflow: "scroll" }}
      >
        <div className="flex justify-between items-center pt-4 ml-4">
          <p className="font-bold text-lg">Ingresar Usuario</p>
          <button
            type="button"
            onClick={() => setFlagInsertUser(false)}
            style={{ color: "rgb(153, 171, 180)", borderRadius: "50%" }}
            className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
          >
            <MdOutlineCancel />
          </button>
        </div>
        {/* TODO: Datos del usuario */}
        <div style={{ margin: "1rem" }}>
          <p style={{ fontWeight: "bold", color: "#0087f6" }}>
            Datos del usuario
          </p>
          <hr />

          {/* Nombre */}
          <div class="espacio-textfield">
            <p style={{ fontWeight: "bolder" }}> Nombre</p>
            <TextField
              label="Ingresa un Nombre"
              size="small"
              onChange={handleNameChange}
              sx={styles.fullWidthTextField}
            />
          </div>

          {/*  Apellido */}
          <div class="espacio-textfield">
            <p style={{ fontWeight: "bolder" }}> Apellidos</p>
            <TextField
              label="Ingresa los apellidos"
              size="small"
              onChange={handleLastNameChange}
              sx={styles.fullWidthTextField}
            />
          </div>

          {/*  cedula */}
          <div class="espacio-textfield">
            <p style={{ fontWeight: "bolder" }}> Cédula de Indentidad</p>
            <TextField
              label="Ingresa una cedula"
              size="small"
              onChange={handleCedulaChange}
              sx={styles.fullWidthTextField}
            />
          </div>

          {/* Fecha Nacimiento y Rol */}
          <div
            style={{ display: "flex", flexDirection: "row" }}
            class="espacio-textfield"
          >
            <div style={{ flex: 1, marginRight: "4px" }}>
              <p style={{ fontWeight: "bolder" }}> Fecha de Nacimiento</p>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={fechaSeleccionada ? dayjs(fechaSeleccionada) : null}
                  onChange={handleFechaChange}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>
            </div>

            <div style={{ flex: 1 }}>
              <p style={{ fontWeight: "bolder" }}> Rol</p>
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel htmlFor="outlined-age-native-simple">
                  Rol
                </InputLabel>
                <Select
                  native
                  value={rol}
                  onChange={handleRoleChange}
                  label="Rol"
                  inputProps={{
                    name: "rol",
                    id: "outlined-age-native-simple",
                  }}
                >
                  {roles.map((role) =>
                    role.rol != "Admin" ? (
                      <option key={role.id} value={role.id}>
                        {role.rol}
                      </option>
                    ) : null
                  )}
                </Select>
              </FormControl>
            </div>
          </div>

          {/*  Imagen */}
          <div class="espacio-textfield">
            <p style={{ fontWeight: "bolder" }}> Imagen</p>
          </div>
          <div class="espacio-textfield">
            <InputFile
              file={image}
              onChange={(e) => {
                const selectedFile = e.target.files[0];
                console.log(selectedFile);
                setImage(selectedFile);
                const imageURL = URL.createObjectURL(selectedFile);
                setLink(imageURL);
              }}
              fileType="imagen"
            />
          </div>
        </div>
        {/*  TODO: Datos del contacto */}
        <div style={{ margin: "1rem" }}>
          <p style={{ fontWeight: "bold", color: "#0087f6" }}>
            Datos del contacto
          </p>
          <hr />

          <div
            style={{ display: "flex", flexDirection: "row" }}
            class="espacio-textfield"
          >
            {/* email */}
            <div style={{ flex: 1, marginRight: "4px" }}>
              <p style={{ fontWeight: "bolder" }}> Email</p>
              <TextField
                label="Email"
                size="small"
                onChange={handleEmailChange}
                InputProps={{
                  endAdornment: (
                    <div style={styles.textField}>
                      <EmailOutlined sx={styles.icon} />
                    </div>
                  ),
                }}
                sx={styles.fullWidthTextField}
              />
            </div>
            {/* celular */}
            <div style={{ flex: 1, marginRight: "4px" }}>
              <p style={{ fontWeight: "bolder" }}> Celular</p>
              <TextField
                label="Celular"
                size="small"
                onChange={handlePhoneChange}
                InputProps={{
                  endAdornment: (
                    <div style={styles.textField}>
                      <PhoneAndroid sx={styles.icon} />
                    </div>
                  ),
                }}
                sx={styles.fullWidthTextField}
              />
            </div>
          </div>

          <div class="espacio-textfield">
            <p style={{ fontWeight: "bolder" }}> País</p>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel htmlFor="outlined-age-native-simple">País</InputLabel>
              <Select
                native
                value={country.country}
                onChange={handleCountryChange}
                label="Country"
                inputProps={{
                  name: "country",
                  id: "outlined-age-native-simple",
                }}
              >
                {countries.map((countryObj) => (
                  <option key={countryObj.id} value={countryObj.country}>
                    {countryObj.country}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>
          {/* Dirección */}
          <div class="espacio-textfield">
            <p> Dirección</p>
            <TextField
              label="Ingrese una dirección"
              size="small"
              onChange={handleAddressChange}
              sx={styles.fullWidthTextField}
            />
          </div>
        </div>

        {/*  Contraseña*/}
        <div style={{ margin: "1rem" }}>
          <p style={{ fontWeight: "bold", color: "#0087f6" }}>Contraseña</p>
          <hr />

          <div class="espacio-textfield">
            <p style={{ fontWeight: "bolder" }}> Contraseña</p>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel htmlFor="outlined-adornment-password">
                Ingresa una contraseña
              </InputLabel>
              <OutlinedInput
                id="pass1"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Contraseña"
                value={pass1}
                onChange={handlePass1Change}
              />
            </FormControl>
          </div>

          <div class="espacio-textfield">
            <p style={{ fontWeight: "bolder" }}> Confirma contraseña</p>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <InputLabel htmlFor="outlined-adornment-password">
                Confirme Contraseña
              </InputLabel>
              <OutlinedInput
                id="pass2"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Contraseña"
                value={pass2}
                onChange={handlePass2Change}
              />
            </FormControl>
          </div>
          <Button
            style={{ marginTop: "15px", borderRadius: "15px", width: "100%" }}
            variant="contained"
            onClick={guardarDatos}
          >
            Confirmar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InsertLateralUsers;
