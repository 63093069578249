import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { getPawnDetail } from "../services/api/pawns";
import { getPaymetsByPawn } from "../services/api/payments";

import { getArticlesImagesByIdArticle } from "../services/api/articles";
import { getContractByPawnsId } from "../services/api/pawnDocument";
import {
	Table,
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	TableBody,
} from "@mui/material/";

import { TileLayer, Marker, Popup } from "react-leaflet";
import { MapContainer } from "react-leaflet";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const DetailPawn = () => {
	const { id } = useParams();
	const [imagen1, setImagen1] = useState("");
	const [imagen2, setImagen2] = useState("");

	const [status, setStatus] = useState("");
	const [observation, setObservation] = useState("");
	const [model, setModel] = useState("");
	const [marca, setMarca] = useState("");
	const [usuario, setUsuario] = useState("");
	const [type, setType] = useState("");
	const [asesor, setAsesor] = useState("");
	const [description, setDescription] = useState("");
	const [createdAt, setcreatedAt] = useState("");
	const [locationX, setLocationX] = useState("");
	const [locationY, setLocationY] = useState("");
	const [urlDocument, setUrlDocument] = useState("");
	const [base, setBase] = useState(0);
	const [amount, setAmount] = useState(0);
	const [interest, setInterest] = useState(0);
	const [flagPago, setFlagPago] = useState(false);

	const [payments, setPayments] = useState([]);

	useEffect(() => {
		setDatos();
	});

	const setDatos = async () => {
		try {
			const response = await getPawnDetail(id);
			setModel(response.model);
			setMarca(response.brand);
			setStatus(response.status);
			setObservation(response.observation);
			setUsuario(response.usuario);
			setType(response.type);
			setAsesor(response.asesor);
			setDescription(response.description);
			setBase(response.base);
			setInterest(response.interest);
			setAmount(response.value);
			setcreatedAt(
				format(new Date(response.createdAt), "dd 'de' MMMM 'de' yyyy", {
					locale: es,
				})
			);

			if (response.location) {
				setLocationY(response.location.split(",")[1]);
				setLocationX(response.location.split(",")[0]);
			}

			await getPaymetsByPawn(id)
				.then((res) => {
					setPayments(res);
					if (res.length > 0) setFlagPago(true);
					else setFlagPago(false);
				})
				.catch((e) => console.log(e));

			await getArticlesImagesByIdArticle(response.articleid)
				.then((respon) => {
					setImagen1(respon[0].url);
					setImagen2(respon[1].url);
				})
				.catch((e) => console.log(e));

			await getContractByPawnsId(id)
				.then((response) => {
					setUrlDocument(response.url);
				})
				.catch((e) => console.log(e));
		} catch (error) {
			console.log(error);
		}
	};

	const MapG = () => {
		return (
			<MapContainer
				center={[locationY, locationX]}
				zoom={14}
				scrollWheelZoom={false}
				style={{ height: "400px", width: "100%" }}>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				<Marker position={[locationY, locationX]}>
					<Popup>Ubicación de la Compraventa</Popup>
				</Marker>
			</MapContainer>
		);
	};

	return (
		<div className="m-4 mt-1 p-5 bg-white rounded-3xl">
			<Link to="/pawns" className="mb-4 ml-2">
				<ArrowBackIcon fontSize="large" />
			</Link>

			<div style={{ textAlign: "center", marginBottom: "40px" }}>
				<h1 style={{ fontWeight: "bold", fontSize: "30px" }}>
					Detalle de la Compraventa
				</h1>
			</div>

			<div style={{ display: "flex" }}>
				<img
					src={imagen1}
					alt="Imagen"
					className="w-60 h-52 rounded-lg border-2 my-4 mx-auto"
				/>

				<img
					src={imagen2}
					alt="Imagen"
					className="w-60 h-52 rounded-lg border-2 my-4 mx-auto"
				/>
			</div>

			<div className="grid grid-cols-2 gap-2 text-center">
				<div>
					<div className="mb-2">
						<span className="font-bold">Marca:</span> {marca}
					</div>
					<div className="mb-2">
						<span className="font-bold">Modelo:</span> {model}
					</div>
					<div className="mb-2">
						<span className="font-bold">Estado:</span>
						{status === "solicited"
							? "Solicitado"
							: status === "pending"
							? "Pendiente"
							: status === "toPickUp"
							? "Por retirar"
							: status === "toDeliver"
							? "Por entregar"
							: status === "ByCollect"
							? "Por Cobrar"
							: status === "latePayment"
							? "Pago tardío"
							: status === "cancelled"
							? "Cancelado"
							: status === "success"
							? "Exitoso"
							: status === "received"
							? "Recibido"
							: status === "finalAppraisal"
							? "Avalúo final"
							: status}
					</div>
					<div className="mb-2">
						<span className="font-bold">Usuario:</span> {usuario}
					</div>
					<div className="mb-2">
						<span className="font-bold">Asesor:</span> {asesor}
					</div>
					<div className="mb-2">
						<span className="font-bold">Observación:</span> {observation}
					</div>
				</div>
				<div>
					<div className="mb-2">
						<span className="font-bold">Tipo: </span> {type}
					</div>
					<div className="mb-2">
						<span className="font-bold">Fecha de la Compraventa:</span>{" "}
						{createdAt}
					</div>
					<div className="mb-2">
						<span className="font-bold">Base:</span>{" "}
						{base
							? localStorage.getItem("country") === "Ecuador"
								? base.toFixed(2) + " $"
								: localStorage.getItem("country") === "Chile"
								? base.toFixed(0) + " CPL "
								: null
							: "No asignado"}
					</div>
					<div className="mb-2">
						<span className="font-bold">Interes:</span>{" "}
						{interest
							? localStorage.getItem("country") === "Ecuador"
								? interest.toFixed(2) + " $"
								: localStorage.getItem("country") === "Chile"
								? interest.toFixed(0) + " CPL "
								: null
							: "No asignado"}
					</div>
					<div className="mb-2">
						<span className="font-bold">Monto:</span>{" "}
						{amount
							? localStorage.getItem("country") === "Ecuador"
								? amount.toFixed(2) + " $"
								: localStorage.getItem("country") === "Chile"
								? amount.toFixed(0) + " CPL "
								: null
							: "No asignado"}
					</div>
				</div>
			</div>

			{flagPago ? (
				<div>
					<div style={{ textAlign: "center", marginBottom: "10px" }}>
						<h1 style={{ fontWeight: "bold", fontSize: "20px" }}>Pagos</h1>
					</div>

					<TableContainer>
						<Table
							sx={{ minWidth: 650 }}
							size="small"
							aria-label="a dense table">
							<TableHead>
								<TableRow>
									<TableCell align="center">ID</TableCell>
									<TableCell align="center">Fecha De Pago</TableCell>
									<TableCell align="center">Estado</TableCell>
									<TableCell align="center">Monto</TableCell>
									<TableCell align="center">Balance</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{payments && payments.length > 0 ? (
									payments.map((row) => (
										<TableRow key={row.id}>
											<TableCell align="center" component="th" scope="row">
												<Link to={"/pawns/Detail/" + row.id}>{row.id}</Link>
											</TableCell>
											<TableCell align="center" component="th" scope="row">
												<Link to={"/pawns/Detail/" + row.id}>
													{format(
														new Date(row.fechaPago),
														"dd 'de' MMMM 'del' yyyy",
														{
															locale: es,
														}
													)}
												</Link>
											</TableCell>
											<TableCell align="center" component="th" scope="row">
												<Link to={"/pawns/Detail/" + row.id}>
													{row.status === "pending"
														? "Pendiente"
														: row.status === "perReview"
														? "Por revisión"
														: row.status === "paymentConfirmed"
														? "Pago Confirmado"
														: row.status === "paymentDeclined"
														? "Pago Rechazado"
														: null}
												</Link>
											</TableCell>
											<TableCell align="center" component="th" scope="row">
												<Link to={"/pawns/Detail/" + row.id}>
													{localStorage.getItem("country") === "Ecuador"
														? row.amount.toFixed(2) + " $"
														: localStorage.getItem("country") === "Chile"
														? row.amount.toFixed(0) + " CLP"
														: null}
												</Link>
											</TableCell>
											<TableCell align="center" component="th" scope="row">
												<Link to={"/pawns/Detail/" + row.id}>
													{localStorage.getItem("country") === "Ecuador"
														? row.balance.toFixed(2) + " $"
														: localStorage.getItem("country") === "Chile"
														? row.balance.toFixed(0) + " CLP"
														: null}
												</Link>
											</TableCell>
										</TableRow>
									))
								) : (
									<TableRow>
										<TableCell colSpan={5} align="center">
											No se ah definido el avalúo final.
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			) : null}

			{locationX ? (
				<div>
					<div style={{ textAlign: "center", marginBottom: "10px" }}>
						<h1 style={{ fontWeight: "bold", fontSize: "20px" }}>Ubicación</h1>
					</div>
					<div className="h-400px w-full outline-none">
						<MapG />
					</div>
				</div>
			) : null}

			{urlDocument ? (
				<div>
					<div
						style={{
							textAlign: "center",
							marginTop: "20px",
							marginBottom: "10px",
						}}>
						<h1 style={{ fontWeight: "bold", fontSize: "20px" }}>Contrato </h1>
					</div>
					<iframe src={urlDocument} width="100%" height="500px">
						El navegador no admite la visualización de documentos PDF. Puedes
						descargarlo aquí:
						<a href={urlDocument}>Descargar PDF</a>
					</iframe>
				</div>
			) : null}
		</div>
	);
};

export default DetailPawn;
