import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

const firebaseConfig = {
  apiKey: "AIzaSyBECWN_lFNpR1zHfSUXZmB4rq_kgI7DxPw",
  authDomain: "pignoora.firebaseapp.com",
  projectId: "pignoora",
  storageBucket: "pignoora.appspot.com",
  messagingSenderId: "901876737486",
  appId: "1:901876737486:web:2ba26f51bb7f8cd6baf79f",
  measurementId: "G-NMPE94Y4CR",
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);

export async function uploadImageUser(file) {
  const ubication = "Experimental/Users/";
  return uploadFile(file, ubication);
}
export async function uploadImageArticle(file) {
  const ubication = "Experimental/Articles/";
  return uploadFile(file, ubication);
}
export async function uploadPawnDocument(file) {
  const ubication = "Experimental/PawnDocuments/";
  return uploadFile(file, ubication);
}
export async function uploadFile(file, ruta) {
  const storageRef = ref(storage, ruta + uuidv4());
  await uploadBytes(storageRef, file);
  const link = await getDownloadURL(storageRef);
  return link;
}
