import React, { useEffect } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { MdKeyboardArrowDown } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { UserProfile } from ".";
import { useStateContext } from "../contexts/ContextProvider";
import { useDispatch, useSelector } from "react-redux";
import { setLoged, setMe } from "../utils/redux/slices/app.slice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Logout } from "../utils/redux/slices/app.slice";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { Email, Home, PersonAdd, Settings } from "@material-ui/icons";
import { Logout as LogoutIcon } from "@mui/icons-material";
import { useState } from "react";

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
  </TooltipComponent>
);

const Navbar = () => {
  const {
    currentColor,
    activeMenu,
    setActiveMenu,
    handleClick,
    isClicked,
    setScreenSize,
    screenSize,
    flagChangeCountry,
    setChangeCountry,
  } = useStateContext();
  const [anchorEl, setAnchorEl] = useState(null);
  let history = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { me } = useSelector((store) => store.app);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [setScreenSize]);

  useEffect(() => {
    // if (screenSize <= 900) {
    //   setActiveMenu(false);
    // } else {
    //   setActiveMenu(true);
    // }
  }, [screenSize, setActiveMenu]);
  useEffect(() => {
    const findToken = localStorage.getItem("token")
      ? JSON.parse(localStorage.getItem("token"))
      : false;
    if (findToken) {
      dispatch(setMe(findToken));
    } else {
      dispatch(setLoged(false));
    }
  }, [dispatch]);
  const logoutEvent = () => {
    dispatch(Logout());
    window.location.reload(false);
  };
  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  return (
    <div className="flex justify-between p-2   relative bg-blue-500">
      <div style={{ marginLeft: "10px" }}>
        <Link to={"/"}>
          <img
            src={
              "https://firebasestorage.googleapis.com/v0/b/pignoora.appspot.com/o/Logo%20Emfipay-Blanco.png?alt=media&token=71a94362-ea9c-4dd4-8321-03352ce08747&_gl=1*7a8b5r*_ga*MTU5MjY5NjUwMi4xNjk4Nzg0Mjky*_ga_CW55HF8NVT*MTY5ODc4NDI5Mi4xLjEuMTY5ODc4NDM4OS4yMy4wLjA"
            }
            style={{ height: "40px" }}
          />

          {/* <img src={MiImagen} alt="Descripción de la imagen" /> */}
        </Link>
      </div>
      <div className="flex">
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <p>
            <span className="text-white text-14">Hola,</span>
            <span className="text-white ml-1 text-14">{me?.firstName}</span>
          </p>
          <Tooltip title="Perfil">
            <IconButton
              onClick={handleClickMenu}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar sx={{ width: 32, height: 32 }} src={me?.avatar}>
                M
              </Avatar>
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 9999,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={() => history("/myProfile")}>
            <ListItemIcon>
              <AccountCircleOutlinedIcon fontSize="small" />
            </ListItemIcon>
            Ver Perfil
          </MenuItem>

          <MenuItem onClick={() => setChangeCountry(!flagChangeCountry)}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Cambiar de País
          </MenuItem>
          <MenuItem onClick={logoutEvent}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            Cerrar sesión
          </MenuItem>
        </Menu>

        {isClicked.userProfile && <UserProfile />}
      </div>
    </div>
  );
};

export default Navbar;
