import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  MenuItem,
  FormControl,
  Select,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Grid,
  Paper,
  IconButton,
  TablePagination,
  TextField,
} from "@mui/material";

import { DetalleCenterChangeCountry } from "./descripcion/centerChangeCountry";

import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from "@mui/icons-material/Restore";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { EditLateralUsers } from "./components/lateralEditUsers";
import { DetalleLateralUsers } from "./descripcion/lateralDetalleUsers";

import { getUsersById, getClients, updateUser } from "../services/api/users";

import { Header } from "../components";
import { PacmanLoader } from "react-spinners";
import { useStateContext } from "../contexts/ContextProvider";
import TuneIcon from "@mui/icons-material/Tune";

import { styles } from "./format";
import "./css/format.css";

import "./css/Usuarios.css";

const Clients = () => {
  const {
    currentColor,
    flagChangeCountry,
    currentCountry,
    flagUpdateUser,
    setFlagUpdateUser,
    setFlagDetalle,
    flagDetalle,
  } = useStateContext();
  const [usuarios, setUsuarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState("");
  const [estado, setEstado] = useState(true);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openSwap, setOpenSwap] = useState("");
  const [actualizar, setActualizar] = useState(0);

  useEffect(() => {
    fetchUsuarios();
  }, [estado, currentCountry, actualizar]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenSwap(!open);
  };

  async function fetchUsuarios() {
    setLoading(true);
    try {
      const data = await getClients(
        estado,
        search,
        localStorage.getItem("country")
      );
      setUsuarios(data);
      setLoading(false);
    } catch (error) {
      console.error("Error al obtener los usuarios:", error);
      setLoading(false);
    }
  }

  const deleteUser = (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "No se puede revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Si, Elimínalo!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Eliminado!", "El registro fue elminado.", "success");
        //cambiar estado
        getUsersById(id)
          .then(async (response) => {
            response.status = !estado;
            await updateUser(response);
            await fetchUsuarios();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const handleSearchChange = async (event) => {
    setSearch(event.target.value);
    await fetchUsuarios();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleNativeSelectChange = (event) => {
    setEstado(event.target.value);
  };

  return (
    <div class="border">
      <div class="border-div">
        <Header category="Clientes" />
        <div class="border-div-div">
          <h1>
            <b>Estado:</b>
          </h1>
          <FormControl sx={{ minWidth: 120 }} size="small">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={estado}
              onChange={handleNativeSelectChange}
              style={{ backgroundColor: "#ffffff", borderRadius: "8px" }}
            >
              <MenuItem value={true}>Activo</MenuItem>
              <MenuItem value={false}>Eliminados</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <TextField
          label="Busca entre tus clientes"
          id="outlined-size-small"
          size="small"
          onChange={handleSearchChange}
          value={search}
          style={{ backgroundColor: "#ffffff" }}
          InputProps={{
            endAdornment: (
              <div style={styles.textField}>
                <SearchIcon sx={styles.icon} />
              </div>
            ),
          }}
          sx={styles.fullWidthTextField}
        />
        {/* <div class="tune">
          <IconButton aria-label="edit">
            <TuneIcon />
          </IconButton>
        </div> */}
      </div>

      {loading ? (
        <div className="loader-container">
          <PacmanLoader color={currentColor} size={40} />
        </div>
      ) : (
        <Grid item xs={12} md={12} lg={12}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">ID</TableCell>
                  <TableCell align="center">Foto</TableCell>
                  <TableCell align="center">Cedula</TableCell>
                  <TableCell align="center">Nombre</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center" style={{ width: "160px" }}>
                    Acciones
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usuarios && usuarios.length > 0 ? (
                  usuarios
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell align="center">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <img
                              src={
                                row.avatar
                                  ? row.avatar
                                  : "https://firebasestorage.googleapis.com/v0/b/pignoora.appspot.com/o/Experimental%2FUsers%2F7d6503e1-9805-49fd-9642-377c2483586a?alt=media&token=d0fb6042-94f6-409a-b9a7-fd0493e004ca"
                              }
                              alt="Imagen"
                              style={{
                                borderRadius: "100%",
                                width: "55px",
                                height: "55px",
                                backgroundSize: "cover",
                              }}
                            />
                          </div>
                        </TableCell>

                        <TableCell align="center">{row.cedula}</TableCell>
                        <TableCell align="center">
                          {row.firstName + " " + row.lastName}
                        </TableCell>
                        <TableCell align="center">{row.email}</TableCell>
                        <TableCell align="center">
                          <IconButton
                            aria-label="open"
                            onClick={() => {
                              setUserId(row.id);
                              setFlagDetalle(true);
                              fetchUsuarios();
                            }}
                          >
                            <ArrowForwardIosIcon></ArrowForwardIosIcon>
                          </IconButton>
                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              setUserId(row.id);
                              setFlagUpdateUser(true);
                              // fetchUsuarios();
                            }}
                          >
                            <EditIcon />
                          </IconButton>

                          <IconButton
                            aria-label="delete"
                            onClick={() => deleteUser(row.id)}
                          >
                            {estado ? <DeleteIcon /> : <RestoreIcon />}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No hay usuarios disponibles.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={usuarios ? usuarios.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
            />
          </TableContainer>
        </Grid>
      )}
      {flagChangeCountry && <DetalleCenterChangeCountry />}
      {flagUpdateUser && (
        <EditLateralUsers
          actualizar={actualizar}
          setActualizar={setActualizar}
          idUsers={{ userId }}
          flagUpdateUser={flagUpdateUser}
          toggleDrawer={() => toggleDrawer(false)}
        />
      )}
      {flagDetalle && <DetalleLateralUsers idUsers={{ userId }} />}
    </div>
  );
};

export default Clients;
