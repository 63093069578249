import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: null,
  user:null
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers: (state, action) =>{
        state.users = action.payload;
    },
    setUser: (state, action) => { 
        state.user = action.payload
    },

  }
});

export const {setUser,setIdUser} = userSlice.actions;
