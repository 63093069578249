import React, { createContext, useContext, useState } from "react";

const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};

export const ContextProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState("#03C9D7");
  const [currentMode, setCurrentMode] = useState("Light");
  const [themeSettings, setThemeSettings] = useState(false);
  // Insertar
  const [flagInsertUser, setFlagInsertUser] = useState(false);
  const [flagInsertMorotized, setFlagInsertMotorized] = useState(false);
  //Update
  const [flagUpdatePawn, setFlagUpdatePawn] = useState(false);
  const [flagUpdateUser, setFlagUpdateUser] = useState(false);
  const [flagUpdateArticle, setFlagUpdateArticle] = useState(false);
  const [flagUpdateMorotized, setFlagUpdateMotorized] = useState(false);
  //Detalle
  const [flagDetalle, setFlagDetalle] = useState(false);
  const [flagDetalle1, setFlagDetalle1] = useState(false);

  //Change Country
  const [flagChangeCountry, setChangeCountry] = useState(false);

  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [currentCountry, setCurrentCountry] = useState("Ecuador");
  const [menuIcon, setMenuIcon] = useState(true);
  const setCountry = (country) => {
    setCurrentCountry(country);
    localStorage.setItem("country", country);
  };

  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem("themeMode", e.target.value);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem("colorMode", color);
  };

  const handleClick = (clicked) =>
    setIsClicked((prevState) => ({ ...prevState, [clicked]: true }));

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider
      value={{
        currentColor,
        currentMode,
        currentCountry,
        activeMenu,
        screenSize,
        setScreenSize,
        handleClick,
        isClicked,
        initialState,
        setIsClicked,
        setActiveMenu,
        setCurrentColor,
        setCurrentMode,
        setMode,
        setCountry,
        setColor,
        themeSettings,
        setThemeSettings,
        flagInsertUser,
        setFlagInsertUser,
        flagUpdatePawn,
        setFlagUpdatePawn,
        flagUpdateUser,
        setFlagUpdateUser,
        flagUpdateArticle,
        setFlagUpdateArticle,
        flagInsertMorotized,
        setFlagInsertMotorized,
        flagUpdateMorotized,
        setFlagUpdateMotorized,
        flagDetalle,
        setFlagDetalle,
        flagDetalle1,
        setFlagDetalle1,
        flagChangeCountry,
        setChangeCountry,
        menuIcon,
        setMenuIcon,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
