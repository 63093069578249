import { React, useEffect, useState } from "react";
import "./css/EditUsers.css";
import Swal from "sweetalert2";
import { IconButton, TextField, Button, Box } from "@mui/material/";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import { getUsersById, updateUser } from "../services/api/users";
import ContactEmergencySharpIcon from "@mui/icons-material/ContactEmergencySharp";
import { format } from "date-fns";
import { es } from "date-fns/locale";

import "./css/MiPerfil.css";

const MyProfile = () => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [cedula, setCedula] = useState("");
	const [phone, setPhone] = useState("");
	const [address, setAddress] = useState("");
	const [birthDay, setBirthDay] = useState("");

	const [firstNameDefault, setFirstNameDefault] = useState("");
	const [lastNameDefault, setLastNameDefault] = useState("");
	const [emailDefault, setEmailDefault] = useState("");
	const [cedulaDefault, setCedulaDefault] = useState("");
	const [phoneDefault, setPhoneDefault] = useState("");
	const [addressDefault, setAddressDefault] = useState("");

	const [update, setUpdate] = useState(true);

	const [fechaSeleccionada, setFechaSeleccionada] = useState(null);

	useEffect(() => {
		setDatos();
	}, []);

	const handleEmailChange = (event) => {
		setEmailDefault(event.target.value);
	};

	const handleCedulaChange = (event) => {
		setCedulaDefault(event.target.value);
	};

	const handlePhoneChange = (event) => {
		setPhoneDefault(event.target.value);
	};

	const handleAddressChange = (event) => {
		setAddressDefault(event.target.value);
	};
	const handleFirstNameChange = (event) => {
		setFirstNameDefault(event.target.value);
	};
	const handleLastNameChange = (event) => {
		setLastNameDefault(event.target.value);
	};

	const handleFechaChange = (event) => {
		setFechaSeleccionada(event);
	};

	const setDatos = async () => {
		let objeto = JSON.parse(localStorage.getItem("token"));
		let response = await getUsersById(objeto.user.id);

		setCedulaDefault(response.cedula);
		setFirstNameDefault(response.firstName);
		setLastNameDefault(response.lastName);
		setEmailDefault(response.email);
		setPhoneDefault(response.phone);
		setAddressDefault(response.address);

		setCedula(response.cedula);
		setFirstName(response.firstName);
		setLastName(response.lastName);
		setEmail(response.email);
		setPhone(response.phone);
		setAddress(response.address);
		setFechaSeleccionada(response.birthDay);
		setBirthDay(
			format(new Date(response.birthDay), "dd 'de' MMMM 'de' yyyy", {
				locale: es,
			})
		);
	};

	const guardarDatos = async () => {
		if (
			firstName.length !== 0 ||
			lastName.length !== 0 ||
			email.length !== 0 ||
			cedula.length !== 0 ||
			phone.length !== 0 ||
			address.length !== 0
		) {
			const usuario = {
				firstName: firstName,
				lastName: lastName,
				email: email,
				cedula: cedula,
				birthDay: fechaSeleccionada,
				phone: phone,
				address: address,
				type: true,
			};
			console.log(usuario);
			// const nueva = await insertUser(usuario);
		} else {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Ingrese todos los campos!",
			});
		}
	};

	return (
		<div class="border">
			<div
				style={{
					display: "flex",
					alignItems: "center",
					paddingBottom: "10px",
				}}>
				<IconButton style={{ backgroundColor: "#d8effd" }}>
					<BadgeOutlinedIcon style={{ color: "#404040" }} />
				</IconButton>
				<h3 className="miPerfil-Titulo"> Mi Perfil </h3>
			</div>

			<hr />

			<div className="divTitInfoContacto">
				<h3 className="infoContacto"> Información de Contacto</h3>
				<button
					className="botonAzul"
					onClick={() => {
						setUpdate(!update);
					}}>
					{update ? "Actualizar" : "Cancelar"}
				</button>
			</div>

			{update ? (
				<div className="lateralDiv">
					<div className="divPrinc">
						<div className="divInfo">
							<h3 className="detailTitulo"> Cedula</h3>
							<span className="detailDato"> {cedula}</span>
						</div>

						<div className="divInfo">
							<h3 className="detailTitulo"> Nombre</h3>
							<span className="detailDato"> {firstName} </span>
						</div>

						<div className="divInfo">
							<h3 className="detailTitulo"> Apellido</h3>
							<span className="detailDato"> {lastName} </span>
						</div>
						<div className="divInfo">
							<h3 className="detailTitulo"> Fecha Nac</h3>
							<span className="detailDato"> {birthDay} </span>
						</div>
					</div>

					<div className="divPrinc">
						<div className="divInfo">
							<h3 className="detailTitulo"> Email</h3>
							<span className="detailDato"> {email}</span>
						</div>

						<div className="divInfo">
							<h3 className="detailTitulo"> Celular</h3>
							<span className="detailDato"> {phone} </span>
						</div>

						<div className="divInfo">
							<h3 className="detailTitulo"> Dirección</h3>
							<span className="detailDato"> {address} </span>
						</div>
					</div>
				</div>
			) : (
				<div className="lateralDiv">
					<div className="divPrinc">
						<div className="divInfo">
							<TextField
								label="Cedula"
								value={cedulaDefault}
								style={{ width: "100%" }}
								onChange={handleCedulaChange}
							/>
						</div>
						<div className="divInfo">
							<TextField
								label="Nombre"
								value={firstNameDefault}
								style={{ width: "100%" }}
								onChange={handleFirstNameChange}
							/>
						</div>
						<div className="divInfo">
							<TextField
								label="Apellido"
								style={{ width: "100%" }}
								value={lastNameDefault}
								onChange={handleLastNameChange}
							/>
						</div>

						<div className="divInfo">
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DatePicker
									value={fechaSeleccionada ? dayjs(fechaSeleccionada) : null}
									onChange={handleFechaChange}
								/>
							</LocalizationProvider>
						</div>
					</div>

					<div className="divPrinc">
						<div className="divInfo">
							<TextField
								label="Email"
								value={emailDefault}
								style={{ width: "100%" }}
								onChange={handleEmailChange}
							/>
						</div>
						<div className="divInfo">
							<TextField
								label="Celular"
								value={phoneDefault}
								style={{ width: "100%" }}
								onChange={handlePhoneChange}
							/>
						</div>
						<div className="divInfo">
							<TextField
								label="Dirección"
								style={{ width: "100%" }}
								value={addressDefault}
								onChange={handleAddressChange}
							/>
						</div>
					</div>
				</div>
			)}

			{!update ? (
				<div
					style={{ display: "flex", justifyContent: "center", width: "100%" }}>
					<Button
						onClick={guardarDatos}
						variant="contained"
						className="botonAceptar">
						Aceptar
					</Button>
				</div>
			) : null}
		</div>
	);
};

export default MyProfile;
