import axios from "axios";
import API from "../../API";
const urlApi = API.URL + "pawns";

export async function getPawns() {
  try {
    let setting = {
      method: "GET",
      url: urlApi,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(urlApi, setting);
    const data = response.data;
    return data;
  } catch (error) {
    // Manejar el error en caso de que la solicitud falle
    console.error(error);
    return null;
  }
}

export async function getPawnsContabilityByMont() {
  const newUrl = urlApi + "/contability";
  try {
    let setting = {
      method: "GET",
      url: newUrl,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(newUrl, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getCountPayments(country) {
  const newUrl = urlApi + "/contPayments/" + country;
  try {
    let setting = {
      method: "GET",
      url: newUrl,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(newUrl, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getCountPawnsPayments(country) {
  const newUrl = urlApi + "/countPawnsPayments/" + country;
  try {
    let setting = {
      method: "GET",
      url: newUrl,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(newUrl, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getCountByDay(country, date) {
  const newUrl = urlApi + "/getCountByDay/" + country + "?date=" + date;
  try {
    let setting = {
      method: "GET",
      url: newUrl,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(newUrl, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getCountPawns(country) {
  const newUrl = urlApi + "/contPawns/" + country;
  try {
    let setting = {
      method: "GET",
      url: newUrl,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(newUrl, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getContByMonth(country) {
  const newUrl = urlApi + "/detailContabilityByMonth/" + country;
  try {
    let setting = {
      method: "GET",
      url: newUrl,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(newUrl, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getPawnsTableLatePayment(estado, parametro, country) {
  const newUrl =
    urlApi +
    "/tableLatePay/" +
    estado +
    "?country=" +
    country +
    "&dato=" +
    parametro;
  try {
    let setting = {
      method: "GET",
      url: newUrl,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(newUrl, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
export async function getPawnsDashboardByCountry(country) {
  const newUrl = urlApi + "/dashboardByCountry/" + country;
  try {
    let setting = {
      method: "GET",
      url: newUrl,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(newUrl, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getPawnsTable({
  estado,
  currentStatus,
  search,
  country,
}) {
  const newUrl =
    urlApi +
    "/table/" +
    estado +
    "?country=" +
    country +
    "&dato=" +
    search +
    "&category=" +
    currentStatus;
  try {
    let setting = {
      method: "GET",
      url: newUrl,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(newUrl, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getPawnsByName(nombre) {
  const urlSearch = urlApi + "/ByParam?param=" + nombre;
  try {
    let setting = {
      method: "GET",
      url: urlSearch,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(urlSearch, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getPawnById(id) {
  let byId = urlApi + "/" + id;
  try {
    let setting = {
      method: "GET",
      url: byId,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(byId, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getPawnDetail(id) {
  let byId = urlApi + "/detail/" + id;
  try {
    let setting = {
      method: "GET",
      url: byId,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(byId, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function updatePawn(pawn) {
  console.log("modulo aparte" + pawn);
  const url = `${urlApi}/${pawn.id}`;
  try {
    const setting = {
      method: "PUT",
      url: url,
      headers: { "Content-Type": "application/json" },
      data: pawn,
    };
    const response = await axios(setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
