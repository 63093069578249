import axios from "axios";
import API from "../../API";

const urlApi = API.URL + "contracts";

export async function getContractByPawnId(idArticle) {
  const newUrl = urlApi + "/ByPawnId/" + idArticle;
  try {
    let setting = {
      method: "GET",
      url: newUrl,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(newUrl, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
