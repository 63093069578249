import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  FormControl,
  MenuItem,
  InputLabel,
  IconButton,
  Select,
  Table,
  TableRow,
  TableHead,
  TableContainer,
  TablePagination,
  TableCell,
  TableBody,
  Paper,
  Grid,
} from "@mui/material/";
import Swal from "sweetalert2";
import { DetalleCenterChangeCountry } from "./descripcion/centerChangeCountry";
import { Link } from "react-router-dom";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import { InsertLateralMotorized } from "./components/lateralInsertMotorized";
import { UpdateLateralMotorized } from "./components/lateralEditMotorizeds";
import { DetalleLateralMotorized } from "./descripcion/lateralDetalleMotorizado";

import { useStateContext } from "../contexts/ContextProvider";
import API from "../API";

import TuneIcon from "@mui/icons-material/Tune";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styles } from "./format";
import "./css/format.css";

import {
  getMotorizedsTable,
  getMotorizedById,
  updateMotorized,
} from "../services/api/motorized";

import { Header } from "../components";
import { PacmanLoader } from "react-spinners";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from "@mui/icons-material/Restore";
import SearchIcon from "@mui/icons-material/Search";

const Motorist = () => {
  const {
    currentColor,
    currentCountry,
    setFlagInsertMotorized,
    flagInsertMorotized,
    flagUpdateMorotized,
    flagChangeCountry,
    setFlagUpdateMotorized,
    flagDetalle,
    setFlagDetalle,
  } = useStateContext();
  const [motorizeds, setMotorizeds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [estado, setEstado] = useState(true);
  const [search, setSearch] = useState("");
  const [motorizedId, setMotorizedId] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    setDatos();
  }, [estado, search, currentCountry]);

  const deleteMotorist = (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "No se puede revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Si, Elimínalo!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Eliminado!", "El registro fue eliminado.", "success");
        getMotorizedById(id)
          .then(async (response) => {
            response.status = !estado;
            await updateMotorized(response);
            await setDatos();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  async function setDatos() {
    const dataMoto = await getMotorizedsTable(
      estado,
      search,
      localStorage.getItem("country")
    );
    setMotorizeds(dataMoto);
    setLoading(false);
  }

  const handleNativeSelectChange = (event) => {
    setEstado(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setDatos();
  };

  return (
    <div class="border">
      <div class="border-div">
        <Header category="Motorizado" />
        <div class="border-div-div">
          <h1>
            <b>Estado:</b>
          </h1>
          <FormControl sx={{ minWidth: 120 }} size="small">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={estado}
              onChange={handleNativeSelectChange}
              style={{ backgroundColor: "#ffffff", borderRadius: "8px" }}
            >
              <MenuItem value={true}>Activo</MenuItem>
              <MenuItem value={false}>Eliminados</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <TextField
          label="Busca entre tus clientes"
          id="outlined-size-small"
          size="small"
          onChange={handleSearchChange}
          value={search}
          style={{ backgroundColor: "#ffffff" }}
          InputProps={{
            endAdornment: (
              <div style={styles.textField}>
                <SearchIcon sx={styles.icon} />
              </div>
            ),
          }}
          sx={styles.fullWidthTextField}
        />
        <div class="tune">
          <IconButton aria-label="edit">
            <TuneIcon />
          </IconButton>
        </div>
      </div>

      {loading ? (
        <div className="loader-container">
          <PacmanLoader color={currentColor} size={30} />
        </div>
      ) : (
        <Grid item xs={12} md={12} lg={12}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">ID</TableCell>
                  <TableCell align="center">Nombre</TableCell>
                  <TableCell align="center">Avatar</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Placa</TableCell>
                  <TableCell align="center">Licencia</TableCell>
                  <TableCell align="center" style={{ width: "160px" }}>
                    Acciones
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {motorizeds && motorizeds.length > 0 ? (
                  motorizeds
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row" align="center">
                          {row.id}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.nombre}
                        </TableCell>
                        <TableCell align="center">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <img
                              src={
                                row.avatar
                                  ? row.avatar
                                  : "https://firebasestorage.googleapis.com/v0/b/pignoora.appspot.com/o/Experimental%2FUsers%2Fa9d5f51c-6580-40d4-879e-67e223b96db8?alt=media&token=14018de5-04a2-4a23-b3af-2f2d4d9ac17c"
                              }
                              alt="Imagen"
                              style={{
                                borderRadius: "20px",
                                width: "60px",
                                height: "60px",
                                backgroundSize: "cover",
                              }}
                            />
                          </div>
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.email}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.placa}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row.licencia}
                        </TableCell>

                        <TableCell component="th" scope="row" align="center">
                          <IconButton
                            aria-label="open"
                            onClick={() => {
                              setMotorizedId(row.id);
                              setFlagDetalle(true);
                              setDatos();
                            }}
                          >
                            <ArrowForwardIosIcon></ArrowForwardIosIcon>
                          </IconButton>

                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              setMotorizedId(row.id);
                              setFlagUpdateMotorized(true);
                              setDatos();
                            }}
                          >
                            <EditIcon />
                          </IconButton>

                          <IconButton
                            aria-label="delete"
                            onClick={() => deleteMotorist(row.id)}
                          >
                            {estado ? <DeleteIcon /> : <RestoreIcon />}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      No hay Motorizados disponibles.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={motorizeds ? motorizeds.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
            />
          </TableContainer>
        </Grid>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "16px",
        }}
      >
        <IconButton
          onClick={() => {
            setFlagInsertMotorized(true);
            setDatos();
          }}
          color="primary"
          style={{ color: currentColor }}
        >
          <AddCircleIcon fontSize="large" />
        </IconButton>
      </Box>
      {/* {flagInsertMorotized && <lateralInserMotorized />} */}

      {flagInsertMorotized && <InsertLateralMotorized />}
      {flagChangeCountry && <DetalleCenterChangeCountry />}
      {flagUpdateMorotized && (
        <UpdateLateralMotorized idMotorized={{ motorizedId }} />
      )}
      {flagDetalle && <DetalleLateralMotorized idMotorized={{ motorizedId }} />}
    </div>
  );
};

export default Motorist;
