import React, { useEffect, useState } from "react";
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Box,
  TextField,
  Chip,
  TablePagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Link } from "react-router-dom";
import { getSalesByParam } from "../services/api/sales";
import { Header } from "../components";
import { PacmanLoader } from "react-spinners";
import { useStateContext } from "../contexts/ContextProvider";
import "./css/Usuarios.css";

const Sales = () => {
  const { currentColor, currentCountry } = useStateContext();
  const [sales, setSales] = useState([]);

  const [loading, setLoading] = useState(true);
  const [estado, setEstado] = useState(true);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    fetchUsuarios();
  }, [estado, search, currentCountry]);

  async function fetchUsuarios() {
    setLoading(true);
    try {
      const dataSales = await getSalesByParam(
        estado,
        search,
        localStorage.getItem("country")
      );
      setSales(dataSales);
      setLoading(false);
    } catch (error) {
      console.error("Error al obtener los usuarios:", error);
      setLoading(false);
    }
  }

  const handleSearchChange = async (event) => {
    setSearch(event.target.value);
    // await fetchUsuarios();
  };

  const handleNativeSelectChange = (event) => {
    setEstado(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div className="m-4 mt-1 p-5 bg-white rounded-3xl">
      <Header category="Ventas" />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-helper-label">Estado</InputLabel>
          <Select
            labelId="label"
            id="demo-simple-select-helper"
            value={estado}
            label="Estado"
            onChange={handleNativeSelectChange}
          >
            <MenuItem value={true}>Activo</MenuItem>
            <MenuItem value={false}>Eliminados</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
          <TextField
            id="input-with-sx"
            label="Buscar"
            variant="standard"
            onChange={handleSearchChange}
            value={search}
          />
        </Box>
      </div>
      {loading ? (
        <div className="loader-container">
          <PacmanLoader color={currentColor} size={40} />
        </div>
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">Foto</TableCell>
                <TableCell align="center">Model</TableCell>
                <TableCell align="center">Marca</TableCell>
                <TableCell align="center">Categoría</TableCell>
                <TableCell align="center">Precio</TableCell>
                <TableCell align="center">Fecha Final</TableCell>
                <TableCell align="center">Descripción</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sales && sales.length > 0 ? (
                sales
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        <Link to={"/Sales/Detail/" + row.id}>{row.id}</Link>
                      </TableCell>
                      <TableCell align="center">
                        <Link to={"/Sales/Detail/" + row.id}>
                          <Box sx={{ width: "120px", height: "90px" }}>
                            <img
                              src={row.url}
                              alt="Imagen"
                              style={{
                                borderRadius: "20px",
                                width: "120px",
                                height: "90px",
                                backgroundSize: "cover",
                              }}
                            />
                          </Box>
                        </Link>
                      </TableCell>
                      <TableCell align="center">
                        <Link to={"/sales/detail/" + row.id}>{row.model}</Link>
                      </TableCell>
                      <TableCell align="center">
                        <Link to={"/sales/detail/" + row.id}>{row.brand}</Link>
                      </TableCell>
                      <TableCell align="center">
                        <Link to={"/sales/detail/" + row.id}>
                          <Chip
                            label={row.name}
                            color={
                              row.name === "Joyas"
                                ? "primary"
                                : row.name === "Laptops y Computadoras"
                                ? "secondary"
                                : row.name === "Celulares"
                                ? "error"
                                : row.name === "Electrodomesticos"
                                ? "warning"
                                : row.name === "Articulos varios"
                                ? "success"
                                : "info"
                            }
                          />
                        </Link>
                      </TableCell>

                      <TableCell align="center">
                        <Link to={"/sales/detail/" + row.id}>
                          {row.price}
                          {localStorage.getItem("country") === "Ecuador"
                            ? " $"
                            : localStorage.getItem("country") === "Chile"
                            ? " CLP"
                            : null}
                        </Link>
                      </TableCell>
                      <TableCell align="center">
                        <Link to={"/sales/detail/" + row.id}>
                          {row.finaldate}
                        </Link>
                      </TableCell>
                      <TableCell align="center">
                        <Link to={"/sales/detail/" + row.id}>
                          {row.description}
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No se encontraron usuarios
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={sales ? sales.length : 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(parseInt(e.target.value, 10));
          setPage(0);
        }}
      />
    </div>
  );
};

export default Sales;
