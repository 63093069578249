import { React, useEffect, useState } from "react";
import "./css/EditUsers.css";
import { Navigate } from "react-router-dom";

import Swal from "sweetalert2";

import { uploadImageArticle } from "../services/firebase/config";

import {
	TextField,
	Box,
	Select,
	InputLabel,
	FormControl,
	InputAdornment,
	Button,
} from "@mui/material";
import {
	AccountCircle,
	ArrowBack as ArrowBackIcon,
	LocalAtm as LocalAtmIcon,
	CardTravel as CardTravelIcon,
	BrandingWatermark as BrandingWatermarkIcon,
	Category as CategoryIcon,
} from "@mui/icons-material";

import { useParams, Link } from "react-router-dom";
import { boxStyles } from "./css/inserUsersStyle";

import { uploadPawnDocument } from "../services/firebase/config";
import {
	updateArticle,
	updateArticleImage,
	getArticlesImagesByIdArticle,
} from "../services/api/articles";
import { getPawnById, updatePawn } from "../services/api/pawns";
import { getPawnStatus } from "../services/api/pawnStatus";
import { getCategories } from "../services/api/categories";
import { getContractByPawnId } from "../services/api/contracts";
import {
	getContractByPawnsId,
	updateDocumentPawn,
	insertPawnDocument,
} from "../services/api/pawnDocument";

import { useStateContext } from "../contexts/ContextProvider";

const EditPawn = () => {
	const { id } = useParams();
	// Compraventa
	const [observation, setObservation] = useState("");
	const [estado, setEstado] = useState("");
	const [estados, setEstados] = useState([]);
	const [category, setCategory] = useState("");
	const [categories, setCategories] = useState([]);

	const [urlDocument, setUrlDocument] = useState("");
	const [flag, setFlag] = useState(false);
	const [pawnDocumentId, setPawnDocumentId] = useState("");
	const [contractId, setContractId] = useState("");

	const [documentFile, setDocumentFile] = useState(null);
	const [isInsert, setIsInsert] = useState(false);

	const [existeDocument, setExisteDocument] = useState(false);
	const [existeContract, setExisteContract] = useState(false);

	const [amount, setAmount] = useState("");
	const [base, setBase] = useState("");
	const [interes, setInteres] = useState("");
	const { currentColor } = useStateContext();

	//articulo
	const [type, setType] = useState("");
	const [brand, setBrand] = useState("");
	const [model, setModel] = useState("");
	const [articleId, setArticleId] = useState("");
	//IMAGENES
	const [id1, setId1] = useState("");
	const [id2, setId2] = useState("");
	const [image1, setImage1] = useState(null);
	const [image2, setImage2] = useState(null);
	const [link1, setLink1] = useState("");
	const [link2, setLink2] = useState("");
	const [flag1, setFlag1] = useState(false);
	const [flag2, setFlag2] = useState(false);

	useEffect(() => {
		setDatos();
	}, []);

	const setDatos = async () => {
		await getPawnById(id)
			.then((response) => {
				setObservation(response.article.description);
				setCategory(response.article.categoryId);
				setEstado(response.statusId);
				setModel(response.article.model);
				setBrand(response.article.brand);
				setType(response.article.type);

				setArticleId(response.article.id);
				getArticlesImagesByIdArticle(response.article.id)
					.then((res) => {
						console.log(res);
						if (res[0] !== undefined) {
							setLink1(res[0].url);
							setId1(res[0].id);
						}
						if (res[1] !== undefined) {
							setLink2(res[1].url);
							setId2(res[1].id);
						}
					})
					.catch((e) => {
						console.log(e);
					});

				if (response.base) {
					if (localStorage.getItem("country") === "Ecuador")
						setBase(response.base.toFixed(2));
					if (localStorage.getItem("country") === "Chile")
						setBase(response.base.toFixed(0));
				}
				if (response.value) {
					if (localStorage.getItem("country") === "Ecuador")
						setAmount(response.value.toFixed(2));
					if (localStorage.getItem("country") === "Chile")
						setAmount(response.value.toFixed(0));
				}
				if (response.interest) {
					if (localStorage.getItem("country") === "Ecuador")
						setInteres(response.interest.toFixed(2));
					if (localStorage.getItem("country") === "Chile")
						setInteres(response.interest.toFixed(0));
				}
			})
			.catch((e) => console.log());
		await getPawnStatus()
			.then((response) => {
				setEstados(response);
			})
			.catch((e) => console.log(e));
		await getContractByPawnsId(id)
			.then((response) => {
				if (!response) {
					setExisteDocument(true);
				}
				setUrlDocument(response.url);
				setPawnDocumentId(response.id);
			})
			.catch((e) => console.log(e));

		await getCategories()
			.then((response) => {
				setCategories(response);
			})
			.catch((e) => console.log(e));
		await getContractByPawnId(id)
			.then((response) => {
				if (response.id !== undefined) {
					setContractId(response.id);
					setExisteContract(true);
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const handleBaseChange = (event) => {
		const inputValue = event.target.value;
		if (!isNaN(inputValue)) {
			setBase(inputValue);
		}
	};

	const handleAmountChange = (event) => {
		const inputValue = event.target.value;
		if (!isNaN(inputValue)) {
			setAmount(inputValue);
		}
	};

	const handleInterestChange = (event) => {
		const inputValue = event.target.value;
		if (!isNaN(inputValue)) {
			setInteres(inputValue);
		}
	};

	const handleObservationChange = (event) => {
		setObservation(event.target.value);
	};

	const handleTypeChange = (event) => {
		setType(event.target.value);
	};

	const handleModelChange = (event) => {
		setModel(event.target.value);
	};

	const handleBrandChange = (event) => {
		setBrand(event.target.value);
	};

	const handleCategorieChange = (event) => {
		setCategory(event.target.value);
	};

	const handleEstadoChange = (event) => {
		setEstado(event.target.value);
	};

	const colocarImagen1 = async (event) => {
		const selectedFile = event.target.files[0];
		setImage1(selectedFile);
		const imageURL = URL.createObjectURL(selectedFile);
		setLink1(imageURL);
		setFlag1(true);
	};

	const colocarImagen2 = async (event) => {
		const selectedFile = event.target.files[0];
		setImage2(selectedFile);
		const imageURL = URL.createObjectURL(selectedFile);
		setLink2(imageURL);
		setFlag2(true);
	};

	const colocarDocumento = async (event) => {
		const selectedFile = event.target.files[0];
		setDocumentFile(selectedFile);
		const imageURL = URL.createObjectURL(selectedFile);
		setUrlDocument(imageURL);
		setFlag(true);
	};

	const guardarDatos = async () => {
		if (observation.length > 0) {
			if (amount >= 0 && base >= 0 && interes >= 0) {
				let pawnId = await getPawnById(id);
				pawnId.statusId = estado;
				pawnId.observation = observation;
				pawnId.interest = interes;
				pawnId.base = base;
				pawnId.value = amount;
				updatePawn(pawnId);

				//TODO: CONDICIONAL DE UPDATE O INSERT
				if (flag) {
					const newUrl = await uploadPawnDocument(documentFile);

					const pawnDocument = {
						id: pawnDocumentId,
						url: newUrl,
						contractId: contractId,
					};
					if (existeDocument) {
						insertPawnDocument(pawnDocument);
					} else {
						updateDocumentPawn(pawnDocument);
					}
				}
			} else {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Ingrese un valor correcto!",
				});
			}
		} else {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Ingrese todos los campos!",
			});
		}

		//Artíclo
		if (
			type.length > 0 &&
			brand.length > 0 &&
			model.length > 0 &&
			observation.length > 0
		) {
			if (flag1) {
				const url1 = await uploadImageArticle(image1);
				const articleImage1 = {
					id: id1,
					url: url1,
					articleId: articleId,
				};
				updateArticleImage(articleImage1);
			}

			if (flag2) {
				const url2 = await uploadImageArticle(image2);
				const articleImage2 = {
					id: id2,
					url: url2,
					articleId: articleId,
				};

				updateArticleImage(articleImage2);
			}

			const article = {
				id: articleId,
				type: type,
				brand: brand,
				cedula: model,
				description: observation,
				categoryId: category,
			};

			updateArticle(article);
			setIsInsert(true);
		} else {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Ingrese todos los campos!",
			});
		}
	};

	return (
		<div className="m-4 mt-1 p-5 bg-white rounded-3xl">
			{isInsert ? <Navigate to="/pawns" /> : null}

			<Link to="/pawns" className="mb-4 ml-2">
				<ArrowBackIcon fontSize="large" />
			</Link>

			<div style={{ textAlign: "center", marginBottom: "40px" }}>
				<h1 style={{ fontWeight: "bold", fontSize: "30px" }}>
					Editar Compraventa
				</h1>
			</div>

			<div
				className="grid grid-cols-2 gap-2 text-center"
				style={{ display: "flex", justifyContent: "space-around" }}>
				<div>
					<Box sx={boxStyles}>
						<TextField
							label="Tipo de Producto"
							value={type}
							onChange={handleTypeChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<CardTravelIcon />
									</InputAdornment>
								),
							}}
						/>
					</Box>

					<Box sx={boxStyles}>
						<TextField
							label="Marca"
							value={brand}
							onChange={handleBrandChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<BrandingWatermarkIcon />
									</InputAdornment>
								),
							}}
						/>
					</Box>
					<Box sx={boxStyles}>
						<TextField
							label="Modelo"
							value={model}
							onChange={handleModelChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<CategoryIcon />
									</InputAdornment>
								),
							}}
						/>
					</Box>

					<Box sx={boxStyles}>
						<TextField
							label="Base"
							value={base}
							onChange={handleBaseChange}
							inputProps={{
								pattern: "[0-9]*",
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<LocalAtmIcon />
									</InputAdornment>
								),
							}}
						/>
					</Box>
					<Box sx={boxStyles}>
						<TextField
							label="Interes"
							value={interes}
							onChange={handleInterestChange}
							inputProps={{
								pattern: "[0-9]*",
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<LocalAtmIcon />
									</InputAdornment>
								),
							}}
						/>
					</Box>
					<Box sx={boxStyles}>
						<TextField
							label="Monto"
							value={amount}
							onChange={handleAmountChange}
							inputProps={{
								pattern: "[0-9]*",
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<LocalAtmIcon />
									</InputAdornment>
								),
							}}
						/>
					</Box>
				</div>
				<div>
					<Box sx={boxStyles}>
						<FormControl variant="outlined">
							<InputLabel htmlFor="outlined-age-native-simple">
								Categoría
							</InputLabel>
							<Select
								native
								value={category}
								onChange={handleCategorieChange}
								label="Rol"
								inputProps={{
									name: "rol",
									id: "outlined-age-native-simple",
								}}>
								{categories.map((cate) => (
									<option key={cate.id} value={cate.id}>
										{cate.name}
									</option>
								))}
							</Select>
						</FormControl>
					</Box>
					<Box sx={boxStyles}>
						<TextField
							label="Observación "
							value={observation}
							multiline
							onChange={handleObservationChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<AccountCircle />
									</InputAdornment>
								),
							}}
						/>
					</Box>

					<Box sx={boxStyles}>
						<FormControl variant="outlined">
							<InputLabel htmlFor="outlined-state-native-simple">
								Estados
							</InputLabel>
							<Select
								native
								value={estado}
								onChange={handleEstadoChange}
								label="Estado"
								inputProps={{
									name: "rol",
									id: "outlined-state-native-simple",
								}}>
								{estados.map((est) => (
									<option key={est.id} value={est.id}>
										{est.status === "success"
											? "Exitoso"
											: est.status === "solicited"
											? "Solicitado"
											: est.status === "pending"
											? "Pendiente"
											: est.status === "toPickUp"
											? "Por retirar"
											: est.status === "toDeliver"
											? "Por entregar"
											: est.status === "ByCollect"
											? "Por Cobrar"
											: est.status === "latePayment"
											? "Pago tardío"
											: est.status === "cancelled"
											? "Cancelado"
											: est.status === "received"
											? "Recibido"
											: est.status === "finalAppraisal"
											? "Avalúo final"
											: est.status}
									</option>
								))}
							</Select>
						</FormControl>
					</Box>
				</div>
			</div>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<div style={{ marginRight: "200px" }}>
					<img
						src={link1}
						alt="Imagen"
						className="w-32 h-32 rounded-lg border-2 my-4 mx-auto"
						style={{
							borderRadius: "20px",
							backgroundSize: "cover",
						}}
					/>

					<Box sx={{ width: "120px", height: "90px" }}>
						<input
							type="file"
							name=""
							id=""
							onChange={colocarImagen1}
							accept="image/*"
						/>
					</Box>
				</div>
				<div>
					<img
						src={link2}
						alt="Imagen"
						className="w-32 h-32 rounded-lg border-2 my-4 mx-auto"
						style={{
							borderRadius: "20px",
							backgroundSize: "cover",
						}}
					/>

					<Box sx={{ width: "120px", height: "90px" }}>
						<input
							type="file"
							name=""
							id=""
							onChange={colocarImagen2}
							accept="image/*"
						/>
					</Box>
				</div>
			</div>

			{urlDocument ? (
				<div>
					<h1 style={{ margin: "20px" }}>
						<b>Contrato</b>
					</h1>
					<iframe
						title="Documento"
						src={urlDocument}
						width="100%"
						height="300px">
						El navegador no admite la visualización de documentos PDF. Puedes
						descargarlo aquí:
						<a href={urlDocument}>Descargar PDF</a>
					</iframe>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							marginTop: "25px",
						}}>
						<Box sx={{ width: "120px", height: "90px" }}>
							<input
								type="file"
								name=""
								id=""
								onChange={colocarDocumento}
								accept=".pdf, .doc, .docx"
							/>
						</Box>
					</div>
				</div>
			) : existeContract ? (
				<div style={{ textAlign: "center" }}>
					<p>Lo siento, no se encontró ningún documento.</p>
					<Box sx={{ width: "120px", height: "90px" }}>
						<input
							type="file"
							name=""
							id=""
							onChange={colocarDocumento}
							accept=".pdf, .doc, .docx"
						/>
					</Box>
				</div>
			) : null}

			<div style={{ display: "flex", justifyContent: "center" }}>
				<Button
					onClick={guardarDatos}
					variant="contained"
					style={{ backgroundColor: currentColor, marginRight: "100px" }}>
					Aceptar
				</Button>

				<Link to="/pawns">
					<Button style={{ backgroundColor: currentColor }} variant="contained">
						Cancelar
					</Button>
				</Link>
			</div>
		</div>
	);
};

export default EditPawn;
