import React from "react";
import Swal from "sweetalert2";
import { useStateContext } from "../contexts/ContextProvider";

import { Header } from "../components";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TablePagination,
  TableCell,
  TableRow,
  FormControl,
  MenuItem,
  InputLabel,
  TextField,
  Select,
  IconButton,
  Box,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from "@mui/icons-material/Restore";

import TuneIcon from "@mui/icons-material/Tune";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styles } from "./format";
import "./css/format.css";

import { updatePawn, getPawnDetail } from "../services/api/pawns";
import { getCuentasPorCobrar } from "../services/api/payments";
import { useEffect, useState } from "react";

const AccountsReceivable = () => {
  const [cuentas, setCuentas] = useState([]);
  const [estado, setEstado] = useState(true);
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { currentCountry } = useStateContext();

  useEffect(() => {
    fetchCuentasPorCobrar();
  }, [estado, search, currentCountry]);

  async function fetchCuentasPorCobrar() {
    try {
      const data = await getCuentasPorCobrar(
        estado,
        localStorage.getItem("country"),
        search
      );
      setCuentas(data);
    } catch (error) {
      console.error("Error al obtener los usuarios:", error);
    }
  }

  const deleteCtasPorCobrar = (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "No se puede revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Si, Elimínalo!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Eliminado!", "El registro fue elminado.", "success");
        //cambiar estado
        getPawnDetail(id)
          .then(async (response) => {
            response.status = !estado;
            await updatePawn(response);
            await fetchCuentasPorCobrar();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const handleSearchChange = async (event) => {
    setSearch(event.target.value);
    await fetchCuentasPorCobrar();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleNativeSelectChange = (event) => {
    setEstado(event.target.value);
  };

  return (
    <div className="m-4 mt-1 p-5 bg-white rounded-3xl">
      <Header category="Cuentas por cobrar" />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-helper-label">Estado</InputLabel>
          <Select
            labelId="label"
            id="demo-simple-select-helper"
            value={estado}
            label="Estado"
            onChange={handleNativeSelectChange}
          >
            <MenuItem value={true}>Activo</MenuItem>
            <MenuItem value={false}>Eliminados</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
          <TextField
            id="input-with-sx"
            label="Buscar"
            variant="standard"
            onChange={handleSearchChange}
            value={search}
          />
        </Box>
      </div>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center">ID</TableCell>
              <TableCell align="center">Cliente</TableCell>
              <TableCell align="center">Modelo</TableCell>
              <TableCell align="center">Imagen</TableCell>
              <TableCell align="center">F. de Pago</TableCell>
              <TableCell align="center">Dias Restantes</TableCell>
              <TableCell align="center">Valor</TableCell>
              <TableCell align="center">Restante</TableCell>

              <TableCell align="center">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cuentas && cuentas.length > 0 ? (
              cuentas
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell align="center">{row.usuario}</TableCell>
                    <TableCell align="center">{row.model}</TableCell>
                    <TableCell align="center">
                      <Box sx={{ width: "120px", height: "90px" }}>
                        <img
                          src={row.url}
                          alt="Imagen"
                          style={{
                            borderRadius: "20px",
                            width: "120px",
                            height: "90px",
                            backgroundSize: "cover",
                          }}
                        />
                      </Box>
                    </TableCell>
                    <TableCell align="center">{row.fechapago}</TableCell>
                    <TableCell align="center">
                      {row.diasrestantes.days > 1
                        ? row.diasrestantes.days + " días"
                        : row.diasrestantes.days * -1 + " días de plazo"}
                    </TableCell>
                    <TableCell align="center">
                      {localStorage.getItem("country") === "Ecuador"
                        ? row.amount.toFixed(2) + " $"
                        : localStorage.getItem("country") === "Chile"
                        ? row.amount.toFixed(0) + " CLP"
                        : null}
                    </TableCell>
                    <TableCell align="center">
                      {localStorage.getItem("country") === "Ecuador"
                        ? row.balance.toFixed(2) + " $"
                        : localStorage.getItem("country") === "Chile"
                        ? row.balance.toFixed(0) + " CLP"
                        : null}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        aria-label="delete"
                        onClick={() => deleteCtasPorCobrar(row.id)}
                      >
                        {estado ? <DeleteIcon /> : <RestoreIcon />}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  No hay Cuentas por cobrar disponibles.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={cuentas ? cuentas.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </TableContainer>
    </div>
  );
};
export default AccountsReceivable;
