import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { links } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";

import "./css/Slidebar.css";

const Sidebar = () => {
  const {
    currentColor,
    activeMenu,
    setActiveMenu,
    screenSize,
    menuIcon,
    setMenuIcon,
  } = useStateContext();

  // const handleCloseSideBar = () => {
  //   if (activeMenu !== undefined && screenSize <= 900) {
  //     setActiveMenu(false);
  //   }
  // };

  useEffect(() => {
    console.log(screenSize);
  }, [screenSize]);

  const handleCloseSideBar = () => {
    // if (activeMenu !== undefined) {
    //   setActiveMenu(false);
    // }
  };

  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";

  return (
    <div className="ml-2 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      <>
        <div className="flex justify-center items-center">
          <TooltipComponent content="Mesnu" position="BottomCenter">
            <button
              type="button"
              onClick={() => setActiveMenu(!activeMenu)}
              style={{ color: currentColor }}
              className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
            >
              <MdOutlineCancel />
            </button>
          </TooltipComponent>
        </div>

        {links.map((item) => (
          <div className="mt-5 ">
            {screenSize > 1000 ? <div class="div-tit">{item.title}</div> : null}

            <div class="div-org">
              {item.links.map((link) => (
                <NavLink
                  to={`/${link.dir}`}
                  key={link.name}
                  onClick={handleCloseSideBar}
                >
                  <div class="div-titulo">
                    {link.icon}

                    {screenSize > 1000 ? (
                      <div class="div-titulo-texto">{link.name}</div>
                    ) : null}
                  </div>
                </NavLink>
              ))}
            </div>
            <hr class="rounded"></hr>
          </div>
        ))}
      </>
    </div>
  );
};

export default Sidebar;
