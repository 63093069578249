import React, { useEffect, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../../contexts/ContextProvider";

import "../css/LateralEdit.css";
import { getPaymetsDetail } from "../../services/api/payments";
import { getArticlesImagesByIdArticle } from "../../services/api/articles";
import TextDescripcion from "../components/labelDescription";
import { format } from "date-fns";
import { es } from "date-fns/locale";

export const DetalleLateralCustomerPayments = ({ idCustomerPayments }) => {
  const { setFlagDetalle } = useStateContext();

  const [amount, setAmount] = useState(0);
  const [model, setModel] = useState("");
  const [brand, setBrand] = useState("");
  // const [interest, setInterest] = useState("");
  const [balance, setBalance] = useState(0);
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [usuario, setUsuario] = useState("");
  const [cedula, setCedula] = useState("");
  const [fechaRegistro, setFechaRegistro] = useState("");
  const [hora, setHora] = useState("");
  const [asesor, setAsesor] = useState("");
  const [idPawn, setIdPawn] = useState("");

  const [url, setUrl] = useState("");
  const [url1, setUrl1] = useState("");
  const [url2, setUrl2] = useState("");

  useEffect(() => {
    if (idPawn) {
      getArticlesImagesByIdArticle(idPawn)
        .then((response) => {
          setUrl1(response[0].url);
          setUrl2(response[1].url);
        })
        .catch((e) => console.log(e));
    }
  }, [idPawn]);

  useEffect(() => {
    setDatos();
  }, []);

  const setDatos = async () => {
    try {
      await getPaymetsDetail(idCustomerPayments.customerPaymentsId)
        .then((response) => {
          setAmount(response.amount);
          setModel(response.model);
          setBrand(response.brand);
          setBalance(response.balance);
          setUsuario(response.usuario);
          setIdPawn(response.idPawn);
          setUrl(response.url);
          setStatus(response.status);
          setAsesor(response.asesor);
          setCedula(response.cedula);
          setFechaRegistro(
            format(new Date(response.fechaRegistro), "dd 'de' MMMM 'de' yyyy", {
              locale: es,
            })
          );
          setHora(
            format(new Date(response.fechaRegistro), "HH:mm:ss", {
              locale: es,
            })
          );

          setType(response.type);
          // getArticlesImagesByIdArticle(idPawn)
          //   .then((response) => {
          //     console.log(response);
          //     setUrl1(response[0].url);
          //     setUrl2(response[1].url);
          //   })
          //   .catch((e) => console.log(e));
        })
        .catch((e) => console.log(e));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
      <div
        className="float-right h-screen dark:text-gray-200  bg-white dark:bg-[#484B52] w-400"
        style={{ overflow: "scroll" }}
      >
        <div className="flex justify-between items-center pt-4 ml-4">
          <p className="font-bold text-lg">Detalle Pagos de clientes</p>
          <button
            type="button"
            onClick={() => setFlagDetalle(false)}
            style={{ color: "rgb(153, 171, 180)", borderRadius: "50%" }}
            className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
          >
            <MdOutlineCancel />
          </button>
        </div>
        {/* TODO: Datos del usuario */}
        <div style={{ margin: "1rem" }}>
          <p style={{ fontWeight: "bold", color: "#0087f6" }}>
            Datos de la Compraventa
          </p>
          <hr />

          <TextDescripcion
            tittle1="Modelo"
            description1={model}
            tittle2="Marca"
            description2={brand}
          />

          <div style={{ margin: "10px" }}>
            <p style={{ fontWeight: "bolder" }}> Asesor</p>
            <p style={{ marginLeft: "10px" }}> {asesor} </p>
          </div>
        </div>
        <div style={{ margin: "1rem" }}>
          <p style={{ fontWeight: "bold", color: "#0087f6" }}>
            Datos de Cliente
          </p>
          <hr />
          <TextDescripcion
            tittle1="Cedula"
            description1={cedula}
            tittle2="Nombre"
            description2={usuario}
          />
        </div>
        <div style={{ margin: "1rem" }}>
          <p style={{ fontWeight: "bold", color: "#0087f6" }}>Datos del Pago</p>
          <hr />
          <TextDescripcion
            tittle1="Día "
            description1={fechaRegistro}
            tittle2="Hora "
            description2={hora}
          />
          <TextDescripcion
            tittle1="Tipo "
            description1={
              status === "perReview"
                ? "Por Revisar"
                : status === "paymentConfirmed"
                ? "Pago Confirmado"
                : status === "pending"
                ? "Pendiente"
                : status === "receivable"
                ? "Recibido"
                : status === "paymentDeclined"
                ? "Pago Rechazado"
                : null
            }
            tittle2="Estado"
            description2={
              type === "current"
                ? "Corriente"
                : type === "refinanced"
                ? "Refinanciado"
                : type === "down_payment"
                ? "Depósito"
                : type === "entrance"
                ? "Entrada"
                : null
            }
          />

          <TextDescripcion
            tittle1="Monto"
            description1={amount.toFixed(2) + " $"}
            tittle2="Balance"
            description2={balance.toFixed(2) + " $"}
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={url1}
            alt="Imagen centrada"
            width="200"
            height="150"
            style={{ borderRadius: "10px" }}
          />

          <img
            src={url2}
            alt="Imagen centrada"
            width="200"
            height="150"
            style={{ borderRadius: "10px", marginTop: "10px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default DetalleLateralCustomerPayments;
