import React, { useEffect, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { Header } from "../components";
import { PacmanLoader } from "react-spinners";
import { getPaymetsTable } from "../services/api/payments";
import SearchIcon from "@mui/icons-material/Search";
import { DetalleLateralCustomerPayments } from "./descripcion/lateralDetalleCustomerPayments";

import {
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	TableBody,
	TablePagination,
	TextField,
	Box,
	FormControl,
	Grid,
	Paper,
	MenuItem,
	Table,
	IconButton,
	Select,
} from "@mui/material/";

import TuneIcon from "@mui/icons-material/Tune";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styles } from "./format";
import "./css/format.css";

const CustomerPayments = () => {
	const [customerPaymentsId, setCustomerPaymentsId] = useState("");
	const [loading, setLoading] = useState(true);
	const [search, setSearch] = useState("");
	const [payments, setPayments] = useState([]);
	const [estado, setEstado] = useState(true);
	const { currentColor, currentCountry, setFlagDetalle, flagDetalle } =
		useStateContext();

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	useEffect(() => {
		setDatos();
	}, [estado, search, currentCountry]);

	async function setDatos() {
		const data = await getPaymetsTable(
			estado,
			search,
			localStorage.getItem("country")
		);
		setPayments(data);
		setLoading(false);
	}

	const handleNativeSelectChange = (event) => {
		setEstado(event.target.value);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleSearchChange = (event) => {
		setSearch(event.target.value);
		setDatos();
	};

	return (
		<div class="border">
			<div class="border-div">
				<Header category="Pagos de clientes" />
				<div class="border-div-div">
					<h1>
						<b>Estado:</b>
					</h1>
					<FormControl sx={{ minWidth: 120 }} size="small">
						<Select
							labelId="demo-select-small-label"
							id="demo-select-small"
							value={estado}
							onChange={handleNativeSelectChange}
							style={{ backgroundColor: "#ffffff", borderRadius: "8px" }}>
							<MenuItem value={true}>Activo</MenuItem>
							<MenuItem value={false}>Eliminados</MenuItem>
						</Select>
					</FormControl>
				</div>
			</div>

			<div style={{ display: "flex", flexDirection: "row" }}>
				<TextField
					label="Busca entre tus clientes"
					id="outlined-size-small"
					size="small"
					onChange={handleSearchChange}
					value={search}
					style={{ backgroundColor: "#ffffff" }}
					InputProps={{
						endAdornment: (
							<div style={styles.textField}>
								<SearchIcon sx={styles.icon} />
							</div>
						),
					}}
					sx={styles.fullWidthTextField}
				/>
				<div class="tune">
					<IconButton aria-label="edit">
						<TuneIcon />
					</IconButton>
				</div>
			</div>

			{loading ? (
				<div className="loader-container">
					<PacmanLoader color={currentColor} size={30} />
				</div>
			) : (
				<Grid item xs={12} md={12} lg={12}>
					<TableContainer component={Paper}>
						<Table
							sx={{ minWidth: 650 }}
							size="small"
							aria-label="a dense table">
							<TableHead>
								<TableRow>
									<TableCell align="center">ID</TableCell>
									<TableCell align="center">Cliente</TableCell>
									<TableCell align="center">Tipo</TableCell>
									<TableCell align="center">F. Pago</TableCell>
									<TableCell align="center">Monto</TableCell>
									<TableCell align="center">Balance</TableCell>
									<TableCell align="center">Modelo</TableCell>
									<TableCell align="center">Marca</TableCell>
									<TableCell align="center">Imagen</TableCell>
									<TableCell align="center" style={{ width: "50px" }}>
										Acciones
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{payments && payments.length > 0 ? (
									payments
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row) => (
											<TableRow key={row.id}>
												<TableCell align="center" component="th" scope="row">
													{row.id}
												</TableCell>
												<TableCell align="center" component="th" scope="row">
													{row.name}
												</TableCell>
												<TableCell align="center" component="th" scope="row">
													{row.type === "current"
														? "Corriente"
														: row.type === "refinanced"
														? "refinanciado"
														: row.type === "entrance"
														? "Entrada"
														: row.type === "down_payment"
														? "Depósito"
														: null}
												</TableCell>
												<TableCell align="center" component="th" scope="row">
													{row.registro}
												</TableCell>
												<TableCell align="center" component="th" scope="row">
													{localStorage.getItem("country") === "Ecuador"
														? row.amount.toFixed(2) + " $"
														: localStorage.getItem("country") === "Chile"
														? row.amount.toFixed(0) + " CLP"
														: null}
												</TableCell>
												<TableCell align="center" component="th" scope="row">
													{localStorage.getItem("country") === "Ecuador"
														? row.balance.toFixed(2) + " $"
														: localStorage.getItem("country") === "Chile"
														? row.balance.toFixed(0) + " CLP"
														: null}
												</TableCell>
												<TableCell align="center" component="th" scope="row">
													{row.model}
												</TableCell>
												<TableCell align="center" component="th" scope="row">
													{row.brand}
												</TableCell>
												<TableCell align="center">
													<div
														style={{
															display: "flex",
															justifyContent: "center",
															alignItems: "center",
															height: "100%",
														}}>
														<img
															src={row.url}
															alt="Imagen"
															style={{
																borderRadius: "20px",
																width: "60px",
																height: "60px",
																backgroundSize: "cover",
															}}
														/>
													</div>
												</TableCell>
												<TableCell align="center">
													<IconButton
														aria-label="open"
														onClick={() => {
															setCustomerPaymentsId(row.id);
															setFlagDetalle(true);
															setDatos();
														}}>
														<ArrowForwardIosIcon></ArrowForwardIosIcon>
													</IconButton>
												</TableCell>
											</TableRow>
										))
								) : (
									<TableRow>
										<TableCell colSpan={8} align="center">
											No hay Compraventa disponibles.
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25]}
							component="div"
							count={payments ? payments.length : 0}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={(event) => {
								setRowsPerPage(parseInt(event.target.value, 10));
								setPage(0);
							}}
						/>
					</TableContainer>
				</Grid>
			)}
			{flagDetalle && (
				<DetalleLateralCustomerPayments
					idCustomerPayments={{ customerPaymentsId }}
				/>
			)}
		</div>
	);
};
export default CustomerPayments;
