export const styles = {
  textField: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    color: "gray", // Cambia el color del icono según tus preferencias.
  },
  fullWidthTextField: {
    width: "100%",
  },
};
