import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { getArticlesImagesByIdArticle } from "../services/api/articles";
import { getSaleDetail } from "../services/api/sales";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const DetailSale = () => {
  const { id } = useParams();
  const [avatar0, setAvatar0] = useState("");
  const [avatar1, setAvatar1] = useState("");
  const [articleName, setArticleName] = useState("");
  const [description, setDescription] = useState("");
  const [model, setModel] = useState("");
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");
  const [newPrice, setNewPrice] = useState("");
  const [base, setBase] = useState("");
  const [asesor, setAsesor] = useState("");

  const [capital, setCapital] = useState(0);
  const [interest, setInteres] = useState(0);
  const [valor, setValor] = useState(0);
  useEffect(() => {
    setDatos();
  }, []);

  const setDatos = async () => {
    try {
      await getSaleDetail(id)
        .then((response) => {
          console.log(response);
          setCapital(response.base);
          setInteres(response.interest);
          setValor(response.base + response.interest);

          setBase(response.base);
          setArticleName(response.type);
          setDescription(response.description);
          setCategory(response.name);
          setNewPrice(response.price);
          setAsesor(response.asesor);
          setModel(response.model);
          setBrand(response.brand);

          getArticlesImagesByIdArticle(response.articleId) // Colocar Imagenes
            .then((response) => {
              setAvatar0(response[0].url);
              setAvatar1(response[1].url);
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => console.log(e));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="m-4 mt-1 p-5 bg-white rounded-3xl">
      <Link to="/sales" className="mb-4 ml-2">
        <ArrowBackIcon fontSize="large" />
      </Link>
      <div style={{ textAlign: "center", marginBottom: "40px" }}>
        <h1 style={{ fontWeight: "bold", fontSize: "30px" }}>
          Detalle Articulo en Venta
        </h1>
      </div>
      <div style={{ display: "flex" }}>
        <img
          src={avatar0}
          alt="Imagen 1"
          className="w-32 h-32 rounded-lg border-2 my-4 mx-auto"
        />
        <img
          src={avatar1}
          alt="Imagen 2"
          className="w-32 h-32 rounded-lg border-2 my-4 mx-auto"
        />
      </div>
      <div className="grid grid-cols-2 gap-2 text-center">
        <div>
          <div className="mb-2">
            <span className="font-bold">Marca: </span> {brand}
          </div>
          <div className="mb-2">
            <span className="font-bold">Model: </span> {model}
          </div>
          <div className="mb-2">
            <span className="font-bold">Artículo: </span> {articleName}
          </div>
          <div className="mb-2">
            <span className="font-bold">Categoría: </span> {category}
          </div>
        </div>
        <div>
          <div className="mb-2">
            <span className="font-bold">Capital: </span>
            {localStorage.getItem("country") === "Ecuador"
              ? capital.toFixed(2) + " $"
              : localStorage.getItem("country") === "Chile"
              ? capital.toFixed(0) + " CLP"
              : null}
          </div>
          <div className="mb-2">
            <span className="font-bold">Interes: </span>
            {localStorage.getItem("country") === "Ecuador"
              ? interest.toFixed(2) + " $"
              : localStorage.getItem("country") === "Chile"
              ? interest.toFixed(0) + " CLP"
              : null}
          </div>
          <div className="mb-2">
            <span className="font-bold">Valor: </span>
            {localStorage.getItem("country") === "Ecuador"
              ? valor.toFixed(2) + " $"
              : localStorage.getItem("country") === "Chile"
              ? valor.toFixed(0) + " CLP"
              : null}
          </div>
          <div className="mb-2">
            <span className="font-bold">Precio Venta: </span>
            {localStorage.getItem("country") === "Ecuador"
              ? newPrice.toFixed(2) + " $"
              : localStorage.getItem("country") === "Chile"
              ? newPrice.toFixed(0) + " CLP"
              : null}
          </div>
          <div className="mb-2">
            <span className="font-bold">Descripción: </span> {description}
          </div>
          <div className="mb-2">
            <span className="font-bold">Asesor: </span> {asesor}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailSale;
