import axios from "axios";
import API from "../../API";
import Swal from "sweetalert2";

const urlApi = API.URL + "users";

export async function insertUser(usuario) {
  try {
    const setting = {
      method: "POST",
      url: urlApi,
      headers: { "Content-Type": "application/json" },
      data: usuario,
    };

    const response = await axios(setting);
    return response.data;
  } catch (error) {
    console.error(error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.response.data.message,
    });
    return null;
  }
}

export async function obtenerUsuarios() {
  try {
    let setting = {
      method: "GET",
      url: urlApi,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(urlApi, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getAllAdministrativo(status, dato, currentRol, countrie) {
  const newUrl =
    urlApi +
    "/administrativo/" +
    status +
    "?countrie=" +
    countrie +
    "&dato=" +
    dato +
    "&rol=" +
    currentRol;
  try {
    let setting = {
      method: "GET",
      url: newUrl,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(newUrl, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
export async function getNoClients(status, dato, countrie) {
  const newUrl =
    urlApi + "/noClients/" + status + "?countrie=" + countrie + "&dato=" + dato;
  try {
    let setting = {
      method: "GET",
      url: newUrl,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(newUrl, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getClients(status, dato, countrie) {
  const newUrl =
    urlApi + "/clients/" + status + "?countrie=" + countrie + "&dato=" + dato;
  try {
    let setting = {
      method: "GET",
      url: newUrl,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(newUrl, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getCountUsers(country) {
  let byId = urlApi + "/users/count/" + country;
  try {
    let setting = {
      method: "GET",
      url: byId,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(byId, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
export async function getUsersByStatus(status, nombre) {
  let byId = urlApi + "/status/" + status + "?nombre=" + nombre;
  try {
    let setting = {
      method: "GET",
      url: byId,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(byId, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getUsersById(id) {
  let byId = urlApi + "/" + id;
  try {
    let setting = {
      method: "GET",
      url: byId,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(byId, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getEsAdministrativo(id) {
  let byId = urlApi + "/esAdministrativo/" + id;
  try {
    let setting = {
      method: "GET",
      url: byId,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(byId, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getEsCliente(id) {
  let byId = urlApi + "/esCliente/" + id;
  try {
    let setting = {
      method: "GET",
      url: byId,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(byId, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function updateUser(usuario, setActualizar, actualizar) {
  console.log(usuario);
  const url = `${urlApi}/${usuario.id}`;
  try {
    const setting = {
      method: "PUT",
      url: url,
      headers: { "Content-Type": "application/json" },
      data: usuario,
    };
    const response = await axios(setting);
    const data = response.data;
    setActualizar(++actualizar);
    console.log(actualizar);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
