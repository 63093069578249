import React, { useEffect, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../../contexts/ContextProvider";

import "../css/LateralEdit.css";
import getCountries from "../../services/api/country";
import { getMotorizedsById } from "../../services/api/motorized";
import TextDescripcion from "../components/labelDescription";
export const DetalleLateralMotorized = ({ idMotorized }) => {
  const { setFlagDetalle } = useStateContext();

  const [link, setLink] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);

  const [placa, setPlaca] = useState("");
  const [licencia, setLicencia] = useState("");

  useEffect(() => {
    setDatos();
  }, []);

  const setDatos = async () => {
    getMotorizedsById(idMotorized.motorizedId)
      .then((response) => {
        setName(response.firstName);
        setLastName(response.lastName);
        setEmail(response.email);
        setPlaca(response.placa);
        setLicencia(response.licencia);
        setPhone(response.phone);
        setCountry(response.countryId);
        setLink(response.avatar);
        console.log(response);
      })
      .catch((e) => console.log(e));

    getCountries()
      .then((response) => {
        setCountries(response);
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
      <div
        className="float-right h-screen dark:text-gray-200  bg-white dark:bg-[#484B52] w-400"
        style={{ overflow: "scroll" }}
      >
        <div className="flex justify-between items-center pt-4 ml-4">
          <p className="font-bold text-lg">Detalle Motorizado</p>
          <button
            type="button"
            onClick={() => setFlagDetalle(false)}
            style={{ color: "rgb(153, 171, 180)", borderRadius: "50%" }}
            className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
          >
            <MdOutlineCancel />
          </button>
        </div>
        {/* TODO: Datos del usuario */}
        <div style={{ margin: "1rem" }}>
          <p style={{ fontWeight: "bold", color: "#0087f6" }}>
            Datos de Usuario
          </p>
          <hr />

          <TextDescripcion
            tittle1="Nombre"
            description1={name + " " + lastName}
            tittle2="Email"
            description2={email}
          />

          <TextDescripcion
            tittle1="Celular"
            description1={phone}
            tittle2="País"
            description2={country}
          />
        </div>

        {/*  TODO: Datos del contacto */}
        <div style={{ margin: "1rem" }}>
          <p style={{ fontWeight: "bold", color: "#0087f6" }}>
            Datos del Vehículo
          </p>
          <hr />
          <TextDescripcion
            tittle1="Placa"
            description1={placa}
            tittle2="Licencia"
            description2={licencia}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <img
              src={link}
              alt="Imagen centrada"
              width="200"
              height="150"
              style={{ borderRadius: "10px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetalleLateralMotorized;
