import React, { useEffect, useState } from "react";

import { getCountPawnStatuses } from "../services/api/pawnStatus";
import { getPaymentsDashboard } from "../services/api/payments";
import { getCountUsers } from "../services/api/users";
import {
	getPawnsDashboardByCountry,
	getCountByDay,
	getCountPawnsPayments,
} from "../services/api/pawns";
import { imagenesDashboard, diasSemana } from "../data/dummy";
import { DetalleCenterChangeCountry } from "./descripcion/centerChangeCountry";

import {
	Avatar,
	IconButton,
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	TableBody,
	Table,
	Box,
	TextField,
	TablePagination,
	Grid,
	Paper,
} from "@mui/material";
import { useStateContext } from "../contexts/ContextProvider";
import PersonIcon from "@mui/icons-material/Person";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import BadgeIcon from "@mui/icons-material/Badge";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { DetalleLateralPawns } from "./descripcion/lateralDetallePawn";
import { ResponsiveLine } from "@nivo/line";
import { ResponsivePie } from "@nivo/pie";
import { Link } from "react-router-dom";
import { ResponsiveBar } from "@nivo/bar";
import PieChart from "../components/graph/PieChart";
import BarChart from "../components/graph/BarChart";
import "./css/Dashboard.css";

const Dashboard = () => {
	const {
		currentCountry,
		setFlagDetalle,
		flagDetalle,
		flagChangeCountry,
		setChangeCountry,
	} = useStateContext();

	const [pawnId, setPawnId] = useState("");
	const [typesPawns, setTypesPawns] = useState([]);
	const [typesPwansObj, setTypesPawnsObj] = useState({ label: [], value: [] });
	const [tiposDash, setTiposDash] = useState([]);
	const [userCount, setUserCount] = useState([]);
	const [pawnsDash, setPawnsDash] = useState([]);
	const [countPawnsPayments, setCountPawnsPayments] = useState([]);
	const [countPawnByDay, setCountPawnByDay] = useState([]);
	const [countPawnByDayObj, setCountPawnByDayObj] = useState({
		label: [],
		value: [],
	});

	const today = new Date();

	const currentDay = today.getDay();

	const mondayThisWeek = new Date(today);
	mondayThisWeek.setDate(
		today.getDate() - currentDay + (currentDay === 0 ? -6 : 1)
	);

	const fechasSemana = [];

	for (let i = 0; i < 6; i++) {
		const fecha = new Date(mondayThisWeek);
		fecha.setDate(mondayThisWeek.getDate() + i);
		fechasSemana.push(fecha);
	}

	const formatoFecha = {
		day: "2-digit",
		month: "2-digit",
		year: "2-digit",
	};
	const fechasFormateadas = fechasSemana.map((fecha) =>
		fecha.toLocaleDateString(undefined, formatoFecha)
	);

	useEffect(() => {
		setDatos();
	}, [currentCountry]);

	async function setDatos() {
		try {
			const pawnStatuses = await getCountPawnStatuses(
				localStorage.getItem("country")
			);
			if (pawnStatuses) {
				let pawnTypeLabel = [];
				let pawnTypeValue = [];
				setTypesPawns(pawnStatuses);

				pawnStatuses.forEach((e) => {
					pawnTypeLabel.push(e.id);
					pawnTypeValue.push(parseFloat(e.value));
				});
				setTypesPawnsObj({ label: pawnTypeLabel, value: pawnTypeValue });
			}

			// if (tiposDash.length === 0) {
			const payments = await getPaymentsDashboard(
				localStorage.getItem("country")
			);
			if (payments) setTiposDash(payments);
			// }
			// if (userCount.length === 0) {
			const users = await getCountUsers(localStorage.getItem("country"));
			if (users) setUserCount(users);
			// }

			const pawns = await getPawnsDashboardByCountry(
				localStorage.getItem("country")
			);
			if (pawns) setPawnsDash(pawns);

			// count pawns payments
			const count = await getCountPawnsPayments(
				localStorage.getItem("country")
			);
			if (count) setCountPawnsPayments(count);

			let dato,
				vector = [];

			for (let index = 0; index < fechasFormateadas.length; index++) {
				dato = await getCountByDay(
					localStorage.getItem("country"),
					fechasFormateadas[index]
				);

				vector.push({
					country: diasSemana[index].nombre,
					valor: dato.length > 0 ? dato : 0,
				});
			}

			setCountPawnByDay(tiposDash);

			let countPawnByDayLabel = [];
			let countPawnByDayValue = [];
			vector.forEach((e) => {
				countPawnByDayLabel.push(e.country);
				countPawnByDayValue.push(e.valor);
			});
			setCountPawnByDayObj({
				label: countPawnByDayLabel,
				value: countPawnByDayValue,
			});

			// console.log(count);
		} catch (error) {
			console.log(error);
		}
	}
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={6} lg={4}>
				<div
					style={{
						width: "100%",
						backgroundColor: "white",
						padding: "20px",
						height: "300px",
						borderRadius: "8px",
						boxShadow: "0px 0px 10px rgba(0,0,0,.1)",
					}}>
					<p
						style={{
							fontWeight: "bold",
							display: "flex",
							justifyContent: "space-between",
						}}>
						Por estado
						<IconButton
							style={{
								backgroundColor: "#f5f5f5",
							}}>
							<BadgeIcon style={{ color: "#3e3e3e" }} />
						</IconButton>
					</p>
					<div style={{ width: "100%" }}>
						{typesPwansObj.value.length != 0 && (
							<PieChart
								values={typesPwansObj.value}
								categories={typesPwansObj.label}
								text="Por Estado"
							/>
						)}
					</div>
				</div>
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<div
					style={{
						backgroundColor: "white",
						minWidth: "250px",
						maxHeight: "max-content !important",
						padding: "20px",
						borderRadius: "8px",
						boxShadow: "0px 0px 10px rgba(0,0,0,.1)",
						width: "100%",
						height: 300,
					}}>
					<div>
						<p
							style={{
								fontWeight: "bold",
								display: "flex",
								justifyContent: "space-between",
							}}>
							Total Dinero Prestado
							<IconButton
								style={{
									backgroundColor: "#f5f5f5",
								}}>
								<CurrencyExchangeIcon style={{ color: "#3e3e3e" }} />
							</IconButton>
						</p>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}>
							<p
								style={{
									fontSize: "30px",
									fontWeight: "bolder",
									verticalAlign: "sub",
									padding: "10px",
								}}>
								{localStorage.getItem("country") == "Ecuador" ? "$" : null}
								{countPawnsPayments[0]
									? Number(countPawnsPayments[0].count).toFixed(2)
									: ""}
								{localStorage.getItem("country") == "Chile" ? " CLP" : null}
							</p>
							{/* <p style={{ color: "#42b36f", padding: "12px" }}>3%</p> */}
							<IconButton
								style={{
									backgroundColor: "#d5f4e1",
									height: "25px",
									width: "25px",
									margin: "12px 0px",
								}}>
								<ArrowUpwardIcon
									style={{
										color: "#42b36f",
										height: "20px",
										width: "20px",
									}}
								/>
							</IconButton>
						</div>
					</div>
					<hr />
					<div
						style={{
							margin: "20px 0px",
						}}>
						<p
							style={{
								fontWeight: "bold",
								display: "flex",
								alignItems: "center",
							}}>
							{" "}
							Total Dinero Ganado
						</p>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}>
							<p
								style={{
									fontSize: "30px",
									fontWeight: "bold",
									padding: "10px",
								}}>
								{localStorage.getItem("country") == "Ecuador" ? "$" : null}

								{countPawnsPayments[1]
									? Number(countPawnsPayments[1].count).toFixed(2)
									: ""}
								{localStorage.getItem("country") == "Chile" ? " CLP" : null}
							</p>
							{/* <p style={{ color: "#df4747", padding: "12px" }}>1.5%</p> */}
							<IconButton
								style={{
									backgroundColor: "#feebeb",
									height: "25px",
									width: "25px",
									margin: "12px 0px",
								}}>
								<ArrowDownwardIcon
									style={{
										color: "#df4747",
										height: "20px",
										width: "20px",
									}}
								/>
							</IconButton>
						</div>
					</div>
				</div>
			</Grid>
			<Grid item xs={12} md={12} lg={4}>
				<div
					style={{
						width: "100%",
						height: 300,
						padding: "20px",
						backgroundColor: "white",
						borderRadius: "8px",
						boxShadow: "0px 0px 10px rgba(0,0,0,.1)",
					}}>
					<p
						style={{
							fontWeight: "bold",
							display: "flex",
							justifyContent: "space-between",
						}}>
						Compraventa por día
						<IconButton
							style={{
								backgroundColor: "#f5f5f5",
							}}>
							<MoreTimeIcon style={{ color: "#3e3e3e" }} />
						</IconButton>
					</p>
					{countPawnByDayObj.value.length != 0 && (
						<BarChart
							categories={countPawnByDayObj.label}
							values={countPawnByDayObj.value}
							text="Compraventa por día"
						/>
					)}
				</div>
			</Grid>

			<Grid item xs={12} md={6} lg={8}>
				<div
					style={{
						backgroundColor: "white",
						minWidth: "250px",
						height: "476px",
						overflow: "auto",
						maxHeight: "max-content !important",
						padding: "20px",
						borderRadius: "8px",
						boxShadow: "0px 0px 10px rgba(0,0,0,.1)",
					}}>
					<div class="div-cant-usuarios-titulo">Cantidad de usuarios</div>
					<div
						style={{
							display: "flex",
							flexWrap: "wrap",
							justifyContent: "center",
							alignItems: "center",
						}}>
						{userCount.slice(0, 4).map((item, index) => (
							<div
								class="target-users"
								style={{
									background: "white",
									borderRadius: "8px",
									border: "1px solid rgba(0,0,0,.2)",
									minWidth: "200px",
									maxWidth: "230px",
									margin: "20px",
									flexGrow: 1,
									transition: "0.3s",
								}}>
								<div style={{ width: "100%", height: "65%" }}>
									<img
										src={imagenesDashboard[index].link}
										style={{
											width: "100%",
											backgroundSize: "cover",
											height: "100%",
										}}
									/>
								</div>
								<div style={{ width: "100%" }}>
									<div
										style={{
											padding: "15px",
											display: "flex",
											flexDirection: "column",
											justifyContent: "flex-start",
											alignItems: "flex-start",
										}}>
										<span
											style={{
												fontSize: "14px",
												fontWeight: "bolder",
												letterSpacing: "1.5px",
												color: "#252525",
												fontFamily: "sans-serif",
											}}>
											{item.tipo}
										</span>
										<span style={{ fontSize: "12px", color: "#0087f6" }}>
											{item.cant} Personas
										</span>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<div
					style={{
						background: "white",
						minWidth: "350px",
						height: "476px",
						overflowY: "auto",
						flexGrow: 1,
						minHeight: "300px",
						boxShadow: "0px 0px 10px rgba(0,0,0,.1)",
						borderRadius: "8px",
					}}>
					<div style={{ padding: "20px" }}>
						<h2 style={{ fontWeight: "bolder" }}>Ultimos pagos</h2>
						<span style={{ fontSize: "12px", color: "rgba(0,0,0,.5)" }}>
							Manage cleanings assigned to this property
						</span>
					</div>
					<hr />
					<div>
						{/* TITULO */}
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "space-between",
								padding: "10px 20px",
							}}>
							<span style={{ fontSize: "14px", fontWeight: "bolder" }}>
								Cliente
							</span>
							<span style={{ fontSize: "14px", fontWeight: "bolder" }}>
								Monto
							</span>
						</div>
						<div style={{ width: "100%", padding: "5px 20px" }}>
							{tiposDash.map((payment, index) => {
								return (
									<div
										class="target-pay"
										style={{
											width: "100%",
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											background: "#FBFBFB",
											padding: "10px",
											margin: "10px 0px",
											borderRadius: "8px",
											transition: "0.3s",
										}}>
										<div
											style={{
												fontSize: "13px",
												display: "flex",
												justifyContent: "flex-start",
												alignItems: "center",
											}}>
											<Avatar
												src={payment.avatar}
												style={{ marginRight: "5px" }}
											/>
											<span>
												{payment.nombre.split(" ")[0] +
													" " +
													payment.nombre.split(" ")[1]}
											</span>
										</div>
										<span style={{ fontSize: "13px" }}>${payment.amount}</span>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</Grid>
			<Grid item xs={12} md={12} lg={12}>
				<div
					style={{
						backgroundColor: "white",
						maxHeight: "max-content !important",
						padding: "20px",
						borderRadius: "8px",
						boxShadow: "0px 0px 10px rgba(0,0,0,.1)",
					}}>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<div>
							<p
								style={{
									fontWeight: "bold",
								}}>
								Artículos Pendientes por aprobar
							</p>
						</div>
						<div>
							<IconButton
								style={{
									backgroundColor: "#f5f5f5",
								}}>
								<CurrencyExchangeIcon style={{ color: "#3e3e3e" }} />
							</IconButton>
						</div>
					</div>
					{/* TODO: AQUÍ VA LOS ULTIMOS Compraventa */}

					<TableContainer component={Paper}>
						<Table size="small" aria-label="a dense table">
							<TableHead>
								<TableRow>
									<TableCell align="center">Artículos</TableCell>
									<TableCell align="center">Cantidad</TableCell>
									<TableCell align="center">Valor asociado</TableCell>
									<TableCell align="center">Estado</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{pawnsDash.slice(0, 5).map((item, index) => (
									<TableRow>
										<TableCell align="center">
											<div
												style={{
													display: "flex",
													width: "100%",
												}}>
												<img
													src={item.url}
													style={{
														height: "40px",
														borderRadius: "10px 0 0 10px",
													}}
												/>
												<div
													style={{
														display: "flex",
														flexDirection: "column",
														alignItems: "flex-start",
														paddingLeft: 10,
													}}>
													<p
														style={{
															color: "black",
															fontWeight: "bold",
														}}>
														{item.brand} {item.model}
													</p>
													<p
														style={{
															width: "100",
															whiteSpace: "nowrap",
															overflow: "hidden",
															textOverflow: "ellipsis",
														}}>
														{item.description}
													</p>
												</div>
											</div>
										</TableCell>
										<TableCell align="center">
											{item.value}
											{localStorage.getItem("country") == "Ecuador"
												? " $"
												: " CLP"}
										</TableCell>
										<TableCell align="center">
											{item.status === "solicited"
												? "Solicitado"
												: item.status === "pending"
												? "Pendiente"
												: item.status === "toPickUp"
												? "Por retirar"
												: item.status === "toDeliver"
												? "Por entregar"
												: item.status === "ByCollect"
												? "Por Cobrar"
												: item.status === "latePayment"
												? "Pago tardío"
												: item.status === "cancelled"
												? "Cancelado"
												: item.status === "success"
												? "Exitoso"
												: item.status === "received"
												? "Recibido"
												: item.status === "finalAppraisal"
												? "Avalúo final"
												: item.status}
										</TableCell>
										<TableCell align="center">
											{/* <Link to={"/pawns/Detail/" + item.id}> */}
											<IconButton
												onClick={() => {
													setPawnId(item.id);
													setFlagDetalle(true);
												}}>
												<ArrowForwardIosIcon />
											</IconButton>
											{/* </Link> */}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					<Box mt={2}>
						<Link
							to={"/pawns/"}
							style={{ display: "flex", justifyContent: "flex-end" }}>
							<a style={{ fontSize: "10" }}> Ver todos los artículos </a>
							<NavigateNextIcon />
						</Link>
					</Box>
					{flagDetalle && <DetalleLateralPawns idPawn={{ pawnId }} />}
					{flagChangeCountry && <DetalleCenterChangeCountry />}
				</div>
			</Grid>
		</Grid>
	);
};
export default Dashboard;
