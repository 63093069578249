import axios from "axios";
import API from "../../API";

const urlApi = API.URL + "sales";

export async function insertSales(sales) {
  try {
    const setting = {
      method: "POST",
      url: urlApi,
      headers: { "Content-Type": "application/json" },
      data: sales,
    };
    const response = await axios(setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getSaleDetail(dato) {
  const newUrl = urlApi + "/detail/" + dato;
  try {
    let setting = {
      method: "GET",
      url: newUrl,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(newUrl, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getSalesByParam(status, dato, country) {
  const newUrl =
    urlApi + "/byParam/" + status + "?country=" + country + "&dato=" + dato;
  try {
    let setting = {
      method: "GET",
      url: newUrl,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(newUrl, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
