import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  me: {},
  loged: false,
  token: null,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setMe: (state, action) => {
      state.me = action.payload.user;
      state.loged = true;
      state.token = action.payload.token;
    },
    setLoged: (state, action) => {
      state.loged = action.payload;
    },
    Logout: (state, action) => {
      state.loged = false;
      state.me = {};
      state.token = null;
      localStorage.removeItem("token");
    },
  },
});

export const { setMe, setLoged, Logout } = appSlice.actions;
