import React from "react";

const Header = ({ category }) => (
  <div className=" mb-1">
    <p className="text-3xl font-extrabold tracking-tight text-slate-900">
      {category}
    </p>

    <div
      style={{
        color: "#737373",
        fontSize: 14,
      }}
    >
      Administra y haz seguimiento de tus {category.toLowerCase()}.
    </div>
  </div>
);

export default Header;
