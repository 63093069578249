import React, { useEffect, useState } from "react";
import {
	TextField,
	Box,
	FormControl,
	MenuItem,
	InputLabel,
	Select,
	Table,
	TableRow,
	TableHead,
	TableContainer,
	TablePagination,
	TableCell,
	TableBody,
	Chip,
} from "@mui/material/";
import { Link } from "react-router-dom";

import { useStateContext } from "../contexts/ContextProvider";
import { getPawnsTableLatePayment } from "../services/api/pawns";
import { Header } from "../components";
import { PacmanLoader } from "react-spinners";
import SearchIcon from "@mui/icons-material/Search";

const Vencidos = () => {
	const [pawns, setPawns] = useState([]);
	const [loading, setLoading] = useState(true);
	const [estado, setEstado] = useState(true);
	const [search, setSearch] = useState("");

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const { currentColor, currentCountry } = useStateContext();

	useEffect(() => {
		setDatos();
	}, [estado, search, currentCountry]);

	async function setDatos() {
		const data = await getPawnsTableLatePayment(
			estado,
			search,
			localStorage.getItem("country")
		);
		setPawns(data);
		setLoading(false);
	}

	const handleNativeSelectChange = (event) => {
		setEstado(event.target.value);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleSearchChange = (event) => {
		setSearch(event.target.value);
		setDatos();
	};

	return (
		<div className="m-4 mt-1 p-5 bg-white rounded-3xl">
			<Header category="Vencidos" />

			{/* Inicio  */}
			<div style={{ display: "flex", justifyContent: "space-between" }}>
				<FormControl sx={{ m: 1, minWidth: 120 }}>
					<InputLabel id="demo-simple-select-helper-label">Estado</InputLabel>
					<Select
						labelId="label"
						id="demo-simple-select-helper"
						value={estado}
						label="Estado"
						onChange={handleNativeSelectChange}>
						<MenuItem value={true}>Activo</MenuItem>
						<MenuItem value={false}>Eliminados</MenuItem>
					</Select>
				</FormControl>
				<Box sx={{ display: "flex", alignItems: "flex-end" }}>
					<SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
					<TextField
						id="input-with-sx"
						label="Buscar"
						variant="standard"
						onChange={handleSearchChange}
						value={search}
					/>
				</Box>
			</div>
			{/* FIN */}
			{loading ? (
				<div className="loader-container">
					<PacmanLoader color={currentColor} size={30} />
				</div>
			) : (
				<TableContainer>
					<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
						<TableHead>
							<TableRow>
								<TableCell align="center">ID</TableCell>
								<TableCell align="center">Modelo</TableCell>
								<TableCell align="center">Marca</TableCell>
								<TableCell align="center">Usuario</TableCell>
								<TableCell align="center">Asesor</TableCell>
								<TableCell align="center">Estado</TableCell>
								<TableCell align="center">Imagen</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{pawns && pawns.length > 0 ? (
								pawns
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row) => (
										<TableRow key={row.id}>
											<TableCell component="th" scope="row">
												<Link to={"/insertVencidos/" + row.id}>{row.id}</Link>
											</TableCell>
											<TableCell component="th" scope="row">
												<Link to={"/insertVencidos/" + row.id}>
													{row.model}
												</Link>
											</TableCell>
											<TableCell component="th" scope="row">
												<Link to={"/insertVencidos/" + row.id}>
													{row.brand}
												</Link>
											</TableCell>
											<TableCell component="th" scope="row">
												<Link to={"/insertVencidos/" + row.id}>
													{row.usuario}
												</Link>
											</TableCell>
											<TableCell component="th" scope="row">
												<Link to={"/insertVencidos/" + row.id}>
													{row.asesor}
												</Link>
											</TableCell>
											<TableCell align="center">
												<Link to={"/insertVencidos/" + row.id}>
													<Chip
														label={
															row.status === "solicited"
																? "Solicitado"
																: row.status === "pending"
																? "Pendiente"
																: row.status === "toPickUp"
																? "Por retirar"
																: row.status === "toDeliver"
																? "Por entregar"
																: row.status === "ByCollect"
																? "Por Cobrar"
																: row.status === "latePayment"
																? "Pago tardío"
																: row.status === "cancelled"
																? "Cancelado"
																: row.status === "success"
																? "Exitoso"
																: row.status === "received"
																? "Recibido"
																: row.status === "finalAppraisal"
																? "Avalúo final"
																: row.status
														}
														color={
															row.status === "success"
																? "primary"
																: row.status === "cancelled"
																? "secondary"
																: row.status === "latePayment"
																? "error"
																: row.status === "pending"
																? "warning"
																: "info"
														}
													/>
												</Link>
											</TableCell>
											<TableCell align="center">
												<Link to={"/insertVencidos/" + row.id}>
													<Box sx={{ width: "120px", height: "90px" }}>
														<img
															src={row.url}
															alt="Imagen"
															style={{
																borderRadius: "20px",
																width: "120px",
																height: "90px",
																backgroundSize: "cover",
															}}
														/>
													</Box>
												</Link>
											</TableCell>
										</TableRow>
									))
							) : (
								<TableRow>
									<TableCell colSpan={9} align="center">
										No hay Compraventas disponibles.
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={pawns ? pawns.length : 0}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={(event) => {
							setRowsPerPage(parseInt(event.target.value, 10));
							setPage(0);
						}}
					/>
				</TableContainer>
			)}
		</div>
	);
};

export default Vencidos;
