import { React, useEffect, useState } from "react";
import "./css/EditUsers.css";

import { Navigate } from "react-router-dom";

import {
  getArticleById,
  updateArticle,
  getArticlesImagesByIdArticle,
} from "../services/api/articles";
import { getCategories } from "../services/api/categories";
import CategoryIcon from "@mui/icons-material/Category";
import { updateArticleImage } from "../services/api/articles";
import {
  TextField,
  Box,
  Select,
  InputLabel,
  FormControl,
  InputAdornment,
  Button,
} from "@mui/material";
import Swal from "sweetalert2";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import DescriptionIcon from "@mui/icons-material/Description";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import { uploadImageArticle } from "../services/firebase/config";
import { useParams, Link } from "react-router-dom";
import { boxStyles } from "./css/inserUsersStyle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const EditArticles = () => {
  const { id } = useParams();
  // Articulo
  // const [selectedCategorie, setSelectedCategorie] = useState("");
  const [type, setType] = useState("");
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [categorie, setCategorie] = useState("");
  const [categories, setCategories] = useState([]);
  const [description, setDescription] = useState("");

  const [id1, setId1] = useState("");
  const [id2, setId2] = useState("");
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [flag1, setFlag1] = useState(false);
  const [flag2, setFlag2] = useState(false);
  const [isInsert, setIsInsert] = useState(false);

  useEffect(() => {
    setDatos();
  }, []);

  const setDatos = async () => {
    getCategories()
      .then((response) => {
        setCategories(response);
      })
      .catch((e) => console.log(e));
    getArticleById(id)
      .then((response) => {
        setType(response.type);
        setBrand(response.brand);
        setModel(response.model);
        setDescription(response.description);
        setCategorie(response.categoryId);
        getArticlesImagesByIdArticle(id)
          .then((response) => {
            if (response[0] !== undefined) {
              setLink1(response[0].url);
              setId1(response[0].id);
            }
            if (response[1] !== undefined) {
              setLink2(response[1].url);
              setId2(response[1].id);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleBrandChange = (event) => {
    setBrand(event.target.value);
  };

  const handleModelChange = (event) => {
    setModel(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleCategorieChange = (event) => {
    setCategorie(event.target.value);
  };

  const colocarImagen1 = async (event) => {
    const selectedFile = event.target.files[0];
    setImage1(selectedFile);
    const imageURL = URL.createObjectURL(selectedFile);
    setLink1(imageURL);
    setFlag1(true);
  };

  const colocarImagen2 = async (event) => {
    const selectedFile = event.target.files[0];
    setImage2(selectedFile);
    const imageURL = URL.createObjectURL(selectedFile);
    setLink2(imageURL);
    setFlag2(true);
  };

  const guardarDatos = async () => {
    if (
      type.length > 0 &&
      brand.length > 0 &&
      model.length > 0 &&
      description.length > 0
    ) {
      if (flag1) {
        const url1 = await uploadImageArticle(image1);
        const articleImage1 = {
          id: id1,
          url: url1,
          articleId: id,
        };
        updateArticleImage(articleImage1);
      }

      if (flag2) {
        const url2 = await uploadImageArticle(image2);
        const articleImage2 = {
          id: id2,
          url: url2,
          articleId: id,
        };

        updateArticleImage(articleImage2);
      }

      const article = {
        id: id,
        type: type,
        brand: brand,
        cedula: model,
        description: description,
        categoryId: categorie,
      };

      updateArticle(article);
      setIsInsert(true);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Ingrese todos los campos!",
      });
    }
  };

  return (
    <div className="m-4 mt-1 p-5 bg-white rounded-3xl">
      {isInsert ? <Navigate to="/articles" /> : null}

      <Link to="/Articles" className="mb-4 ml-2">
        <ArrowBackIcon fontSize="large" />
      </Link>
      <div style={{ textAlign: "center", marginBottom: "40px" }}>
        <h1 style={{ fontWeight: "bold", fontSize: "30px" }}>
          Editar Artículo
        </h1>
      </div>

      <div
        className="grid grid-cols-2 gap-2 text-center"
        style={{ display: "flex", justifyContent: "space-around" }}
      >
        <div>
          <Box sx={boxStyles}>
            <TextField
              label="Tipo de Producto"
              value={type}
              onChange={handleTypeChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CardTravelIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={boxStyles}>
            <TextField
              label="Marca"
              value={brand}
              onChange={handleBrandChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BrandingWatermarkIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={boxStyles}>
            <TextField
              label="Modelo"
              value={model}
              onChange={handleModelChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CategoryIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </div>
        <div>
          <Box sx={boxStyles}>
            <TextField
              label="Descripción"
              multiline
              value={description}
              onChange={handleDescriptionChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DescriptionIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {/* TODO: ES NATIVE SELECT */}
          {/* <Box sx={{ display: "flex", alignItems: "flex-end" }}>
            <FormControl>
              <InputLabel variant="standard">Rol</InputLabel>
              <NativeSelect onChange={handleCategorieChange} value={categorie}>
                {categories.map((cate) => (
                  <option key={cate.id} value={cate.id}>
                    {cate.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </Box> */}
          <Box sx={boxStyles}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">
                Categoría
              </InputLabel>
              <Select
                native
                value={categorie}
                onChange={handleCategorieChange}
                label="Rol"
                inputProps={{
                  name: "rol",
                  id: "outlined-age-native-simple",
                }}
              >
                {categories.map((cate) => (
                  <option key={cate.id} value={cate.id}>
                    {cate.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ marginRight: "200px" }}>
          <img
            src={link1}
            alt="Imagen"
            className="w-32 h-32 rounded-lg border-2 my-4 mx-auto"
            style={{
              borderRadius: "20px",
              backgroundSize: "cover",
            }}
          />

          <Box sx={{ width: "120px", height: "90px" }}>
            <input
              type="file"
              name=""
              id=""
              onChange={colocarImagen1}
              accept="image/*"
            />
          </Box>
        </div>
        <div>
          <img
            src={link2}
            alt="Imagen"
            className="w-32 h-32 rounded-lg border-2 my-4 mx-auto"
            style={{
              borderRadius: "20px",
              backgroundSize: "cover",
            }}
          />

          <Box sx={{ width: "120px", height: "90px" }}>
            <input
              type="file"
              name=""
              id=""
              onChange={colocarImagen2}
              accept="image/*"
            />
          </Box>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={guardarDatos}
          variant="contained"
          style={{ marginRight: "100px" }}
        >
          Aceptar
        </Button>
        <Link to="/Articles">
          <Button variant="contained">Cancelar</Button>
        </Link>
      </div>
    </div>
  );
};

export default EditArticles;
