import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { Header } from "../components";
import {
  getUsersById,
  getEsCliente,
  getEsAdministrativo,
} from "../services/api/users";

const DetailUser = () => {
  const { id } = useParams();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [cedula, setCedula] = useState("");
  const [celular, setCelular] = useState("");
  const [rol, setRol] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [avatar, setAvatar] = useState("");
  const [esCliente, setEsCliente] = useState("");
  const [esAdmin, setEsAdmin] = useState("");

  useEffect(() => {
    setDatos();
  }, []);

  const setDatos = async () => {
    try {
      const response = await getUsersById(id);
      setAvatar(response.avatar);
      console.log(response.avatar);
      setCedula(response.cedula);
      setFirstName(response.firstName);
      setLastName(response.lastName);
      setEmail(response.email);
      setCelular(response.phone);
      setBirthDay(
        format(new Date(response.birthDay), "dd 'de' MMMM 'de' yyyy", {
          locale: es,
        })
      );
      setRol(response.rol.rol);

      let cl = await getEsCliente(id);
      setEsCliente(cl[0].escliente);

      let ad = await getEsAdministrativo(id);
      setEsAdmin(ad[0].esadministrativo);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="m-4 mt-1 p-5 bg-white rounded-3xl">
      {esCliente ? (
        <Link to="/clients" className="mb-4 ml-2">
          <ArrowBackIcon fontSize="large" />
        </Link>
      ) : esAdmin ? (
        <Link to="/administrativo" className="mb-4 ml-2">
          <ArrowBackIcon fontSize="large" />
        </Link>
      ) : (
        <Link to="/users" className="mb-4 ml-2">
          <ArrowBackIcon fontSize="large" />
        </Link>
      )}

      <Header category="Administrador" title="Esto es el usuario" />
      <div className="flex flex-col items-center">
        <img
          src={avatar}
          alt="Imagen"
          className="w-32 h-32 rounded-lg border-2 my-4"
        />
        <div className="grid grid-cols-2 gap-2 text-center">
          <div>
            <div className="mb-2">
              <span className="font-bold">Cedula:</span> {cedula}
            </div>
            <div className="mb-2">
              <span className="font-bold">Nombre:</span> {firstName}
            </div>
            <div className="mb-2">
              <span className="font-bold">Apellido:</span> {lastName}
            </div>
            <div className="mb-2">
              <span className="font-bold">Email:</span> {email}
            </div>
          </div>
          <div>
            <div className="mb-2">
              <span className="font-bold">Celular:</span> {celular}
            </div>
            <div className="mb-2">
              <span className="font-bold">Fecha Nac.:</span> {birthDay}
            </div>
            <div className="mb-2">
              <span className="font-bold">Rol:</span> {rol}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailUser;
