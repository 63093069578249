import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../../contexts/ContextProvider";
import { useParams, Link } from "react-router-dom";
import {
	TextField,
	Select,
	FormControl,
	InputLabel,
	Button,
	OutlinedInput,
	InputAdornment,
	IconButton,
} from "@mui/material";
import { styles } from "../format";
import { uploadImageArticle } from "../../services/firebase/config";
import InputFile from "./InputFile";
import "../css/LateralEdit.css";
import {
	updateArticle,
	updateArticleImage,
	getArticleById,
	getArticlesImagesByIdArticle,
} from "../../services/api/articles";
import { getPawnById, updatePawn } from "../../services/api/pawns";
import { getPawnStatus } from "../../services/api/pawnStatus";
import { getCategories } from "../../services/api/categories";
import { getContractByPawnId } from "../../services/api/contracts";
import {
	getContractByPawnsId,
	updateDocumentPawn,
	insertPawnDocument,
} from "../../services/api/pawnDocument";

export const EditLateralArticles = ({ idArticle }) => {
	useEffect(() => {
		// console.log(idArticle);
		setDatos();
	}, []);
	const { setFlagUpdateArticle } = useStateContext();

	const [type, setType] = useState("");
	const [brand, setBrand] = useState("");
	const [model, setModel] = useState("");
	const [categorie, setCategorie] = useState("");
	const [categories, setCategories] = useState([]);
	const [description, setDescription] = useState("");

	const [id1, setId1] = useState("");
	const [id2, setId2] = useState("");
	const [image1, setImage1] = useState(null);
	const [image2, setImage2] = useState(null);
	const [link1, setLink1] = useState("");
	const [link2, setLink2] = useState("");
	const [flag1, setFlag1] = useState(false);
	const [flag2, setFlag2] = useState(false);
	const [isInsert, setIsInsert] = useState(false);

	const handleTypeChange = (event) => {
		setType(event.target.value);
	};
	const handleBrandChange = (event) => {
		setBrand(event.target.value);
	};
	const handleCategorieChange = (event) => {
		setCategorie(event.target.value);
	};
	const handleModelChange = (event) => {
		setModel(event.target.value);
	};
	const handleDescriptionChange = (event) => {
		setDescription(event.target.value);
	};

	const guardarDatos = async () => {
		if (
			type.length > 0 &&
			brand.length > 0 &&
			model.length > 0 &&
			description.length > 0
		) {
			// if (flag1) {
			//   const url1 = await uploadImageArticle(image1);
			//   const articleImage1 = {
			//     id: id1,
			//     url: url1,
			//     articleId: idArticle.articleId,
			//   };
			//   updateArticleImage(articleImage1);
			// }

			// if (flag2) {
			//   const url2 = await uploadImageArticle(image2);
			//   const articleImage2 = {
			//     id: id2,
			//     url: url2,
			//     articleId: idArticle.articleId,
			//   };

			//   updateArticleImage(articleImage2);
			// }

			const article = {
				id: idArticle.articleId,
				type: type,
				brand: brand,
				cedula: model,
				description: description,
				categoryId: categorie,
			};

			updateArticle(article);
			setFlagUpdateArticle(false);
		} else {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Ingrese todos los campos!",
			});
		}
	};

	const colocarImagen1 = async (event) => {
		const selectedFile = event.target.files[0];
		setImage1(selectedFile);
		const imageURL = URL.createObjectURL(selectedFile);
		setLink1(imageURL);
		setFlag1(true);
	};

	const colocarImagen2 = async (event) => {
		const selectedFile = event.target.files[0];
		setImage2(selectedFile);
		const imageURL = URL.createObjectURL(selectedFile);
		setLink2(imageURL);
		setFlag2(true);
	};

	const setDatos = async () => {
		getCategories()
			.then((response) => {
				setCategories(response);
			})
			.catch((e) => console.log(e));
		getArticleById(idArticle.articleId)
			.then((response) => {
				console.log(response);
				setType(response.type);
				setBrand(response.brand);
				setModel(response.model);
				setDescription(response.description);
				setCategorie(response.categoryId);
				// getArticlesImagesByIdArticle(id)
				//   .then((response) => {
				//     if (response[0] !== undefined) {
				//       setLink1(response[0].url);
				//       setId1(response[0].id);
				//     }
				//     if (response[1] !== undefined) {
				//       setLink2(response[1].url);
				//       setId2(response[1].id);
				//     }
				//   })
				//   .catch((e) => {
				//     console.log(e);
				//   });
			})
			.catch((e) => {
				console.log(e);
			});
	};

	return (
		<div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
			<div
				className="float-right h-screen dark:text-gray-200  bg-white dark:bg-[#484B52] w-400"
				style={{ overflow: "scroll" }}>
				<div className="flex justify-between items-center pt-4 ml-4">
					<p className="font-bold text-lg">Editar Article</p>
					<button
						type="button"
						onClick={() => setFlagUpdateArticle(false)}
						style={{ color: "rgb(153, 171, 180)", borderRadius: "50%" }}
						className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray">
						<MdOutlineCancel />
					</button>
				</div>
				{/* TODO: Datos del usuario */}
				<div style={{ margin: "1rem" }}>
					<p style={{ fontWeight: "bold", color: "#0087f6" }}>
						Datos del Artículo
					</p>
					<hr />

					{/*  tipo */}
					<div class="espacio-textfield">
						<p> Tipo de Producto</p>
						<TextField
							label="Ingrese una tipo de producto"
							value={type}
							size="small"
							onChange={handleTypeChange}
							sx={styles.fullWidthTextField}
						/>
					</div>

					{/*  brand */}
					<div class="espacio-textfield">
						<p> Marca </p>
						<TextField
							value={brand}
							label="Ingrese una marca"
							size="small"
							onChange={handleBrandChange}
							sx={styles.fullWidthTextField}
						/>
					</div>

					{/*  model */}
					<div class="espacio-textfield">
						<p> Modelo </p>
						<TextField
							value={model}
							label="Ingrese un modelo"
							size="small"
							onChange={handleModelChange}
							sx={styles.fullWidthTextField}
						/>
					</div>

					<div class="espacio-textfield">
						<p> Imágenes </p>
						<div style={{ marginBottom: "30px" }}>
							<InputFile
								file={image1}
								onChange={colocarImagen1}
								fileType="imagen"
								nameArchive={model + ".jpg"}
								style={{ padding: "10px" }}
							/>
						</div>
						<div style={{ marginBottom: "30px" }}>
							<InputFile
								file={image2}
								onChange={colocarImagen2}
								fileType="imagen"
								nameArchive={model + ".jpg"}
							/>
						</div>
					</div>
				</div>

				{/*  TODO: Datos del contacto */}
				<div style={{ margin: "1rem" }}>
					<p style={{ fontWeight: "bold", color: "#0087f6" }}>
						Datos de la Compraventa
					</p>
					<hr />

					<div
						style={{ flex: 1, marginRight: "4px" }}
						class="espacio-textfield">
						<p>Descripción</p>
						<TextField
							label="Observación "
							value={description}
							style={{ width: "100%" }}
							multiline
							onChange={handleDescriptionChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start"></InputAdornment>
								),
							}}
						/>
					</div>
					<div
						style={{ flex: 1, marginRight: "4px" }}
						class="espacio-textfield">
						<p>Descripción</p>
						<FormControl
							variant="outlined"
							size="small"
							style={{ width: "100%" }}>
							<InputLabel htmlFor="outlined-age-native-simple">
								Categoría
							</InputLabel>
							<Select
								native
								value={categorie}
								onChange={handleCategorieChange}
								label="Categorías"
								inputProps={{
									name: "rol",
									id: "outlined-age-native-simple",
								}}>
								{categories.map((cate) => (
									<option key={cate.id} value={cate.id}>
										{cate.name}
									</option>
								))}
							</Select>
						</FormControl>
					</div>

					<Button
						style={{
							marginTop: "15px",
							borderRadius: "15px",
							width: "100%",
							height: "40px",
						}}
						variant="contained"
						onClick={guardarDatos}>
						Confirmar
					</Button>
				</div>
			</div>
		</div>
	);
};

export default EditLateralArticles;
