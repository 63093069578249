import React, { useEffect, useState } from "react";

import { format } from "date-fns";
import { es } from "date-fns/locale";
import { useParams, Link } from "react-router-dom";
import { getPaymetsDetail } from "../services/api/payments";
import { getArticlesImagesByIdArticle } from "../services/api/articles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const DetailCustomerPayments = () => {
  const { id } = useParams();
  const [amount, setAmount] = useState(0);
  const [model, setModel] = useState("");
  const [brand, setBrand] = useState("");
  // const [interest, setInterest] = useState("");
  const [balance, setBalance] = useState(0);
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [usuario, setUsuario] = useState("");
  const [cedula, setCedula] = useState("");
  const [fechaRegistro, setFechaRegistro] = useState("");
  const [hora, setHora] = useState("");
  const [asesor, setAsesor] = useState("");
  const [idPawn, setIdPawn] = useState("");

  const [url, setUrl] = useState("");
  const [url1, setUrl1] = useState("");
  const [url2, setUrl2] = useState("");

  useEffect(() => {
    if (idPawn) {
      getArticlesImagesByIdArticle(idPawn)
        .then((response) => {
          setUrl1(response[0].url);
          setUrl2(response[1].url);
        })
        .catch((e) => console.log(e));
    }
  }, [idPawn]);

  useEffect(() => {
    setDatos();
  }, []);

  const setDatos = async () => {
    try {
      await getPaymetsDetail(id)
        .then((response) => {
          setAmount(response.amount);
          setModel(response.model);
          setBrand(response.brand);
          setBalance(response.balance);
          setUsuario(response.usuario);
          setIdPawn(response.idPawn);
          setUrl(response.url);
          setStatus(response.status);
          setAsesor(response.asesor);
          setCedula(response.cedula);
          setFechaRegistro(
            format(new Date(response.fechaRegistro), "dd 'de' MMMM 'de' yyyy", {
              locale: es,
            })
          );
          setHora(
            format(new Date(response.fechaRegistro), "HH:mm:ss", {
              locale: es,
            })
          );

          setType(response.type);
          // getArticlesImagesByIdArticle(idPawn)
          //   .then((response) => {
          //     console.log(response);
          //     setUrl1(response[0].url);
          //     setUrl2(response[1].url);
          //   })
          //   .catch((e) => console.log(e));
        })
        .catch((e) => console.log(e));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="m-4 mt-1 p-5 bg-white rounded-3xl">
      <Link to="/customerPayments" className="mb-4 ml-2">
        <ArrowBackIcon fontSize="large" />
      </Link>
      <div style={{ textAlign: "center", marginBottom: "40px" }}>
        <h1 style={{ fontWeight: "bold", fontSize: "30px" }}>
          Detalle Pagos de Clientes
        </h1>
      </div>
      <div style={{ display: "flex" }}>
        <img
          src={url1}
          alt="Imagen"
          className="w-60 h-52 rounded-lg border-2 my-4 mx-auto"
        />

        <img
          src={url2}
          alt="Imagen"
          className="w-60 h-52 rounded-lg border-2 my-4 mx-auto"
        />
      </div>
      <div className="grid grid-cols-2 gap-2 text-center">
        <div>
          <div className="mb-2">
            <span className="font-bold">Modelo:</span> {model}
          </div>
          <div className="mb-2">
            <span className="font-bold">Marca:</span> {brand}
          </div>
          <div className="mb-2">
            <span className="font-bold">Cedula Usuario:</span> {cedula}
          </div>
          <div className="mb-2">
            <span className="font-bold">Nombre Cliente:</span> {usuario}
          </div>
          <div className="mb-2">
            <span className="font-bold">Asesor:</span> {asesor}
          </div>
        </div>
        <div>
          <div className="mb-2">
            <span className="font-bold">Dia de Pago:</span> {fechaRegistro}
          </div>
          <div className="mb-2">
            <span className="font-bold">Hora de Pago:</span> {hora}
          </div>
          <div className="mb-2">
            <span className="font-bold">Estado:</span>{" "}
            {status === "perReview"
              ? "Por Revisar"
              : status === "paymentConfirmed"
              ? "Pago Confirmado"
              : status === "pending"
              ? "Pendiente"
              : status === "receivable"
              ? "Recibido"
              : status === "paymentDeclined"
              ? "Pago Rechazado"
              : null}
          </div>
          <div className="mb-2">
            <span className="font-bold">Tipo de Pago:</span>{" "}
            {type === "current"
              ? "Corriente"
              : type === "refinanced"
              ? "Refinanciado"
              : type === "down_payment"
              ? "Depósito"
              : type === "entrance"
              ? "Entrada"
              : null}
          </div>
          <div className="mb-2">
            <span className="font-bold">Monto:</span> {amount.toFixed(2) + " $"}
          </div>
          <div className="mb-2">
            <span className="font-bold">Balance:</span>{" "}
            {balance.toFixed(2) + " $"}
          </div>
        </div>
      </div>

      {url !== null ? (
        <div style={{ display: "flex" }}>
          <img
            src={url}
            alt="Imagen"
            className="w-100 h-100 rounded-lg border-2 my-4 mx-auto"
          />
        </div>
      ) : null}
    </div>
  );
};
export default DetailCustomerPayments;
