export const boxStyles = {
  display: "flex",
  alignItems: "flex-end",
  marginBottom: "15px",
};

export const accountCircleStyles = {
  color: "action.active",
  marginRight: 1,
  marginBottom: 0.5,
};
