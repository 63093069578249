import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { getMotorizedById } from "../services/api/motorized";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const DetailMotorized = () => {
  const { id } = useParams();
  const [avatar, setAvatar] = useState("");
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [placa, setPlaca] = useState("");
  const [licencia, setLicencia] = useState("");

  useEffect(() => {
    setDatos();
  }, []);

  const setDatos = async () => {
    try {
      await getMotorizedById(id)
        .then((response) => {
          setAvatar(response.avatar);
          setNombre(response.firstName + " " + response.lastName);
          setEmail(response.email);
          setPlaca(response.placa);
          setLicencia(response.licencia);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="m-4 mt-1 p-5 bg-white rounded-3xl">
      <Link to="/motorist" className="mb-4 ml-2">
        <ArrowBackIcon fontSize="large" />
      </Link>
      <div style={{ textAlign: "center", marginBottom: "40px" }}>
        <h1 style={{ fontWeight: "bold", fontSize: "30px" }}>
          Detalle Motorizado
        </h1>
      </div>
      <div style={{ display: "flex" }}>
        <img
          src={avatar}
          alt="Imagen 2"
          className="w-32 h-32 rounded-lg border-2 my-4 mx-auto"
        />
      </div>
      <div className="grid grid-cols-2 gap-2 text-center">
        <div>
          <div className="mb-2">
            <span className="font-bold">Nombre: </span> {nombre}
          </div>
          <div className="mb-2">
            <span className="font-bold">Placa: </span> {placa}
          </div>
        </div>
        <div>
          <div className="mb-2">
            <span className="font-bold">Correo electrónico: </span> {email}
          </div>
          <div className="mb-2">
            <span className="font-bold">Licencia: </span> {licencia}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailMotorized;
