import React, { useEffect, useState } from "react";
import "./css/EditUsers.css";
import {
  getUsersById,
  updateUser,
  getEsCliente,
  getEsAdministrativo,
} from "../services/api/users";
import getRols from "../services/api/rols";
import getCountries from "../services/api/country";
import Swal from "sweetalert2";

import {
  TextField,
  Box,
  InputLabel,
  Select,
  FormControl,
  InputAdornment,
  Button,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import { Navigate } from "react-router-dom";

import "react-image-crop/dist/ReactCrop.css";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { useParams, Link } from "react-router-dom";
import { boxStyles } from "./css/inserUsersStyle";
import { useStateContext } from "../contexts/ContextProvider";
import { uploadImageUser } from "../services/firebase/config";
import dayjs from "dayjs";

const EditUsers = () => {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [cedula, setCedula] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [avatar, setAvatar] = useState("");
  const [avatarDef, setAvatarDef] = useState("");
  const [email, setEmail] = useState("");
  const [rol, setRol] = useState("");
  const [file, setFile] = useState(null);
  const [roles, setRoles] = useState([]);
  const [country, setCountry] = useState();
  const [countries, setCountries] = useState([]);

  const [fechaSeleccionada, setFechaSeleccionada] = useState(null);
  const [esCliente, setEsCliente] = useState("");
  const [esAdmin, setEsAdmin] = useState("");
  const [isUpateClient, setIsUpdateClient] = useState(false);
  const [isUpdateUser, setIsUpdateUser] = useState(false);
  const [isUpdateAdmin, setIsUpdateAdmin] = useState(false);

  const { currentColor } = useStateContext();

  useEffect(() => {
    getRols()
      .then((response) => {
        setRoles(response);
      })
      .catch((e) => console.log(e));
    getCountries()
      .then((response) => {
        setCountries(response);
      })
      .catch((e) => console.log(e));
    setDatos();
  }, []);

  const setDatos = async () => {
    try {
      await getUsersById(id)
        .then((response) => {
          setAvatar(response.avatar);
          setAvatarDef(response.avatar);
          setName(response.firstName);
          setEmail(response.email);
          setLastName(response.lastName);
          setCedula(response.cedula);
          setFechaSeleccionada(response.birthDay);
          setAddress(response.address);
          setCountry(response.countrieId);
          console.log(response.countrieId);
          setPhone(response.phone);
          setRol(response.rolId);
        })
        .catch((e) => console.log(e));

      let cl = await getEsCliente(id);
      setEsCliente(cl[0].escliente);

      let ad = await getEsAdministrativo(id);
      setEsAdmin(ad[0].esadministrativo);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleCedulaChange = (event) => {
    setCedula(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleFechaChange = (event) => {
    setFechaSeleccionada(event);
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handleRoleChange = (event) => {
    setRol(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const colocarImagen = async (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    const imageURL = URL.createObjectURL(selectedFile);
    setAvatar(imageURL);
  };

  const guardarDatos = async () => {
    if (
      name.length > 0 &&
      lastName.length > 0 &&
      cedula.length > 0 &&
      phone.length > 0 &&
      address.length > 0
    ) {
      const today = dayjs(); // Obtener la fecha actual
      const age = today.diff(fechaSeleccionada, "year"); // Calcular la diferencia de años

      if (age > 18) {
        let urlAvatar = " ";
        if (avatar !== avatarDef) urlAvatar = await uploadImageUser(file);
        else urlAvatar = avatarDef;

        console.log(urlAvatar);

        const usuario = {
          id: id,
          avatar: urlAvatar,
          firstName: name,
          lastName: lastName,
          email: email,
          cedula: cedula,
          phone: phone,
          birthDay: fechaSeleccionada,
          address: address,
          rolId: rol,
          countrieId: country,
        };
        updateUser(usuario);
        if (esAdmin) setIsUpdateAdmin(true);
        if (esCliente) setIsUpdateClient(true);
        else setIsUpdateUser(true);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Debes tener al menos 18 años para registrarte",
        });
        return; // Salir de la función si la edad es menor a 18
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Ingrese todos los campos!",
      });
    }
  };

  return (
    <div className="m-4 mt-1 p-5 bg-white rounded-3xl">
      {isUpateClient ? <Navigate to="/clients" /> : null}
      {isUpdateUser ? <Navigate to="/users" /> : null}
      {isUpdateAdmin ? <Navigate to="/administrativo" /> : null}

      {esCliente ? (
        <Link to="/clients" className="mb-4 ml-2">
          <ArrowBackIcon fontSize="large" />
        </Link>
      ) : (
        <Link to="/Users" className="mb-4 ml-2">
          <ArrowBackIcon fontSize="large" />
        </Link>
      )}

      <div style={{ textAlign: "center", marginBottom: "40px" }}>
        <h1 style={{ fontWeight: "bold", fontSize: "30px" }}>
          Editar Usuarios
        </h1>
      </div>

      <div
        className="grid grid-cols-2 gap-2 text-center"
        style={{ display: "flex", justifyContent: "space-around" }}
      >
        <div>
          <Box sx={boxStyles}>
            <TextField
              id="inputNombre"
              label="Nombre"
              value={name}
              onChange={handleNameChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={boxStyles}>
            <TextField
              id="inputApellido"
              label="Apellido"
              value={lastName}
              onChange={handleLastNameChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={boxStyles}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={fechaSeleccionada ? dayjs(fechaSeleccionada) : null}
                onChange={handleFechaChange}
              />
            </LocalizationProvider>
          </Box>
        </div>
        <div>
          <Box sx={boxStyles}>
            <TextField
              id="inputCedula"
              label="Cedula"
              value={cedula}
              onChange={handleCedulaChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BrandingWatermarkIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={boxStyles}>
            <TextField
              id="inputCelular"
              label="Celular"
              value={phone}
              onChange={handlePhoneChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SmartphoneIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={boxStyles}>
            <TextField
              id="inputDireccion"
              label="Direccion"
              value={address}
              onChange={handleAddressChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MyLocationIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={boxStyles}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">Rol</InputLabel>
              <Select
                native
                value={rol}
                onChange={handleRoleChange}
                label="Rol"
                inputProps={{
                  name: "rol",
                  id: "outlined-age-native-simple",
                }}
              >
                {roles.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.rol}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={boxStyles}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">País</InputLabel>
              <Select
                native
                value={country}
                onChange={handleCountryChange}
                label="Country"
                inputProps={{
                  name: "country",
                  id: "outlined-age-native-simple",
                }}
              >
                {countries.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.country}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
      </div>

      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <img
          src={avatar}
          alt="Imagen"
          style={{
            borderRadius: "20px",
            height: "250px",
            backgroundSize: "cover",
          }}
        />
      </Box>

      {/* cambiar imagen */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "25px",
        }}
      >
        <Box sx={{ width: "120px", height: "90px" }}>
          <input type="file" name="" id="" onChange={colocarImagen} />
        </Box>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={guardarDatos}
          variant="contained"
          style={{ backgroundColor: currentColor, marginRight: "100px" }}
        >
          Guardar
        </Button>
        {esCliente ? (
          <Link to="/clients">
            <Button
              style={{ backgroundColor: currentColor }}
              variant="contained"
            >
              Cancelar
            </Button>
          </Link>
        ) : esAdmin ? (
          <Link to="/administrativo">
            <Button
              style={{ backgroundColor: currentColor }}
              variant="contained"
            >
              Cancelar
            </Button>
          </Link>
        ) : (
          <Link to="/users">
            <Button
              style={{ backgroundColor: currentColor }}
              variant="contained"
            >
              Cancelar
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default EditUsers;
