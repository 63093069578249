import axios from "axios";
import API from "../../API";

const urlApi = API.URL + "payments/";

export async function getCuentasPorCobrar(estado, country, valor) {
  const urlAp =
    urlApi +
    "accountReceivable/" +
    estado +
    "?country=" +
    country +
    "&dato=" +
    valor;
  try {
    let setting = {
      method: "GET",
      url: urlAp,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(urlAp, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getTotalPaymentsByMonth() {
  const urlAp = urlApi + "totalByMonth/";
  try {
    let setting = {
      method: "GET",
      url: urlAp,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(urlAp, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getPaymetsByPawn(pawnId) {
  const urlAp = urlApi + "byPawnId/" + pawnId;
  try {
    let setting = {
      method: "GET",
      url: urlAp,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(urlAp, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getPaymetsTable(estado, valor, country) {
  const urlAp =
    urlApi + "table/" + estado + "?country=" + country + "&dato=" + valor;
  try {
    let setting = {
      method: "GET",
      url: urlAp,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(urlAp, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getPaymetsDetail(id) {
  const urlAp = urlApi + "detail/" + id;
  try {
    let setting = {
      method: "GET",
      url: urlAp,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(urlAp, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getAccountsReceivableDetailById(id) {
  const urlAp = urlApi + "accountsReceivable/detail/" + id;
  try {
    let setting = {
      method: "GET",
      url: urlAp,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(urlAp, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getPaymentsDashboard(country) {
  const urlAp = urlApi + "dashboard/" + country;
  try {
    let setting = {
      method: "GET",
      url: urlAp,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(urlAp, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getPaymentsByMonth(month) {
  let urlAp = "";
  if (month !== "0") urlAp = urlApi + "byMonth/" + month;
  else urlAp = urlApi + "byMon/";

  try {
    let setting = {
      method: "GET",
      url: urlAp,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(urlAp, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
