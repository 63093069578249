import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../../contexts/ContextProvider";
import {
	TextField,
	Select,
	FormControl,
	InputLabel,
	Button,
	InputAdornment,
} from "@mui/material";
import { styles } from "../format";

import "../css/LateralEdit.css";
import { AttachMoney } from "@material-ui/icons";
import {
	updateArticle,
	getArticlesImagesByIdArticle,
} from "../../services/api/articles";
import { getPawnById, updatePawn } from "../../services/api/pawns";
import { getPawnStatus } from "../../services/api/pawnStatus";
import { getCategories } from "../../services/api/categories";
import InputFile from "./InputFile";

export const EditPawns = ({ idPawn }) => {
	useEffect(() => {
		console.log(idPawn);
		setDatos();
	}, []);
	const { setFlagUpdatePawn } = useStateContext();

	// Compraventa
	const [observation, setObservation] = useState("");
	const [estado, setEstado] = useState("");
	const [estados, setEstados] = useState([]);
	const [category, setCategory] = useState("");
	const [categories, setCategories] = useState([]);

	const [urlDocument, setUrlDocument] = useState("");
	const [flag, setFlag] = useState(false);

	const [documentFile, setDocumentFile] = useState(null);
	const [isInsert, setIsInsert] = useState(false);

	const [amount, setAmount] = useState("");
	const [base, setBase] = useState("");
	const [interes, setInteres] = useState("");

	//articulo
	const [type, setType] = useState("");
	const [brand, setBrand] = useState("");
	const [model, setModel] = useState("");
	const [articleId, setArticleId] = useState("");
	//IMAGENES
	const [id1, setId1] = useState("");
	const [id2, setId2] = useState("");
	const [image1, setImage1] = useState(null);
	const [image2, setImage2] = useState(null);
	const [link1, setLink1] = useState("");
	const [link2, setLink2] = useState("");
	const [flag1, setFlag1] = useState(false);
	const [flag2, setFlag2] = useState(false);

	const setDatos = async () => {
		await getPawnById(idPawn.pawnId)
			.then((response) => {
				console.log(response);
				setObservation(response.article.description);
				setCategory(response.article.categoryId);
				setEstado(response.statusId);
				setModel(response.article.model);
				setBrand(response.article.brand);
				setType(response.article.type);

				setArticleId(response.article.id);
				getArticlesImagesByIdArticle(response.article.id)
					.then((res) => {
						console.log(res);
						if (res[0] !== undefined) {
							setLink1(res[0].url);
							setId1(res[0].id);
						}
						if (res[1] !== undefined) {
							setLink2(res[1].url);
							setId2(res[1].id);
						}
					})
					.catch((e) => {
						console.log(e);
					});

				if (response.base) {
					if (localStorage.getItem("country") === "Ecuador")
						setBase(response.base.toFixed(2));
					if (localStorage.getItem("country") === "Chile")
						setBase(response.base.toFixed(0));
				}
				if (response.value) {
					if (localStorage.getItem("country") === "Ecuador")
						setAmount(response.value.toFixed(2));
					if (localStorage.getItem("country") === "Chile")
						setAmount(response.value.toFixed(0));
				}
				if (response.interest) {
					if (localStorage.getItem("country") === "Ecuador")
						setInteres(response.interest.toFixed(2));
					if (localStorage.getItem("country") === "Chile")
						setInteres(response.interest.toFixed(0));
				}
			})
			.catch((e) => console.log());
		await getPawnStatus()
			.then((response) => {
				setEstados(response);
			})
			.catch((e) => console.log(e));
		// await getContractByPawnsId(id)
		//   .then((response) => {
		//     if (!response) {
		//       setExisteDocument(true);
		//     }
		//     setUrlDocument(response.url);
		//     setPawnDocumentId(response.id);
		//   })
		//   .catch((e) => console.log(e));

		await getCategories()
			.then((response) => {
				setCategories(response);
			})
			.catch((e) => console.log(e));
		// await getContractByPawnId(id)
		//   .then((response) => {
		//     if (response.id !== undefined) {
		//       setContractId(response.id);
		//       setExisteContract(true);
		//     }
		//   })
		//   .catch((e) => {
		//     console.log(e);
		//   });
	};

	const handleBaseChange = (event) => {
		const inputValue = event.target.value;
		if (!isNaN(inputValue)) {
			setBase(inputValue);
		}
	};

	const handleAmountChange = (event) => {
		const inputValue = event.target.value;
		if (!isNaN(inputValue)) {
			setAmount(inputValue);
		}
	};

	const handleInterestChange = (event) => {
		const inputValue = event.target.value;
		if (!isNaN(inputValue)) {
			setInteres(inputValue);
		}
	};

	const handleObservationChange = (event) => {
		setObservation(event.target.value);
	};

	const handleTypeChange = (event) => {
		setType(event.target.value);
	};

	const handleModelChange = (event) => {
		setModel(event.target.value);
	};

	const handleBrandChange = (event) => {
		setBrand(event.target.value);
	};

	const handleCategorieChange = (event) => {
		setCategory(event.target.value);
	};

	const handleEstadoChange = (event) => {
		setEstado(event.target.value);
	};

	const colocarImagen1 = async (event) => {
		const selectedFile = event.target.files[0];
		setImage1(selectedFile);
		const imageURL = URL.createObjectURL(selectedFile);
		setLink1(imageURL);
		setFlag1(true);
	};

	const colocarImagen2 = async (event) => {
		const selectedFile = event.target.files[0];
		setImage2(selectedFile);
		const imageURL = URL.createObjectURL(selectedFile);
		setLink2(imageURL);
		setFlag2(true);
	};

	const colocarDocumento = async (event) => {
		const selectedFile = event.target.files[0];
		setDocumentFile(selectedFile);
		const imageURL = URL.createObjectURL(selectedFile);

		setFlag(true);
	};

	const guardarDatos = async () => {
		if (observation.length > 0) {
			if (amount >= 0 && base >= 0 && interes >= 0) {
				let pawnId = await getPawnById(idPawn.pawnId);
				pawnId.statusId = estado;
				pawnId.observation = observation;
				pawnId.interest = interes;
				pawnId.base = base;
				pawnId.value = amount;
				updatePawn(pawnId);

				//TODO: CONDICIONAL DE UPDATE O INSERT
				// if (flag) {
				//   const newUrl = await uploadPawnDocument(documentFile);

				//   const pawnDocument = {
				//     id: pawnDocumentId,
				//     url: newUrl,
				//     contractId: contractId,
				//   };
				//   if (existeDocument) {
				//     insertPawnDocument(pawnDocument);
				//   } else {
				//     updateDocumentPawn(pawnDocument);
				//   }
				// }
			} else {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Ingrese un valor correcto!",
				});
			}
		} else {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Ingrese todos los campos!",
			});
		}

		if (
			type.length > 0 &&
			brand.length > 0 &&
			model.length > 0 &&
			observation.length > 0
		) {
			// if (flag1) {
			//   const url1 = await uploadImageArticle(image1);
			//   const articleImage1 = {
			//     id: id1,
			//     url: url1,
			//     articleId: articleId,
			//   };
			//   updateArticleImage(articleImage1);
			// }

			// if (flag2) {
			//   const url2 = await uploadImageArticle(image2);
			//   const articleImage2 = {
			//     id: id2,
			//     url: url2,
			//     articleId: articleId,
			//   };

			//   updateArticleImage(articleImage2);
			// }

			const article = {
				id: articleId,
				type: type,
				brand: brand,
				cedula: model,
				description: observation,
				categoryId: category,
			};

			updateArticle(article);
			setIsInsert(true);
			setFlagUpdatePawn(false);
		} else {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: "Ingrese todos los campos!",
			});
		}
	};

	return (
		<div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
			<div
				className="float-right h-screen dark:text-gray-200  bg-white dark:bg-[#484B52] w-400"
				style={{ overflow: "scroll" }}>
				<div className="flex justify-between items-center pt-4 ml-4">
					<p className="font-bold text-lg">Ingresar Compraventa</p>
					<button
						type="button"
						onClick={() => setFlagUpdatePawn(false)}
						style={{ color: "rgb(153, 171, 180)", borderRadius: "50%" }}
						className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray">
						<MdOutlineCancel />
					</button>
				</div>
				{/* TODO: Datos del usuario */}
				<div style={{ margin: "1rem" }}>
					<p style={{ fontWeight: "bold", color: "#0087f6" }}>
						Datos del Artículo
					</p>
					<hr />

					{/*  tipo */}
					<div class="espacio-textfield">
						<p> Tipo de Producto</p>
						<TextField
							label="Ingrese una tipo de producto"
							value={type}
							size="small"
							onChange={handleTypeChange}
							sx={styles.fullWidthTextField}
						/>
					</div>

					{/*  brand */}
					<div class="espacio-textfield">
						<p> Marca </p>
						<TextField
							value={brand}
							label="Ingrese una marca"
							size="small"
							onChange={handleBrandChange}
							sx={styles.fullWidthTextField}
						/>
					</div>

					{/*  model */}
					<div class="espacio-textfield">
						<p> Modelo </p>
						<TextField
							value={model}
							label="Ingrese un modelo"
							size="small"
							onChange={handleModelChange}
							sx={styles.fullWidthTextField}
						/>
					</div>

					{/* <div class="espacio-textfield">
						<p> Estado </p>
						<FormControl
							variant="outlined"
							size="small"
							style={{ width: "100%" }}>
							<InputLabel htmlFor="outlined-state-native-simple">
								Estados
							</InputLabel>
							<Select
								native
								value={estado}
								onChange={handleEstadoChange}
								label="Estado"
								inputProps={{
									name: "rol",
									id: "outlined-state-native-simple",
								}}>
								{estados.map((est) => (
									<option key={est.id} value={est.id}>
										{est.status === "success"
											? "Exitoso"
											: est.status === "solicited"
											? "Solicitado"
											: est.status === "pending"
											? "Pendiente"
											: est.status === "toPickUp"
											? "Por retirar"
											: est.status === "toDeliver"
											? "Por entregar"
											: est.status === "ByCollect"
											? "Por Cobrar"
											: est.status === "inWarehouse"
											? "En Bodega"
											: est.status === "latePayment"
											? "Pago tardío"
											: est.status === "cancelled"
											? "Cancelado"
											: est.status === "received"
											? "Recibido"
											: est.status === "finalAppraisal"
											? "Avalúo final"
											: est.status}
									</option>
								))}
							</Select>
						</FormControl>
					</div> */}

					<div class="espacio-textfield">
						<p> Categoría </p>
						<FormControl
							variant="outlined"
							size="small"
							style={{ width: "100%" }}>
							<InputLabel htmlFor="outlined-age-native-simple">
								Categorías
							</InputLabel>
							<Select
								native
								value={categories}
								onChange={handleEstadoChange}
								label="Estado"
								inputProps={{
									name: "rol",
									id: "outlined-state-native-simple",
								}}>
								{categories.map((cate) => (
									<option key={cate.id} value={cate.id}>
										{cate.name}
									</option>
								))}
							</Select>
						</FormControl>
					</div>

					<div class="espacio-textfield">
						<p> Imágenes </p>
						<div style={{ marginBottom: "30px" }}>
							<InputFile
								file={image1}
								onChange={colocarImagen1}
								fileType="imagen"
								nameArchive={model + ".jpg"}
								style={{ padding: "10px" }}
							/>
						</div>
						<div style={{ marginBottom: "30px" }}>
							<InputFile
								file={image2}
								onChange={colocarImagen2}
								fileType="imagen"
								nameArchive={model + ".jpg"}
							/>
						</div>
					</div>
				</div>

				{/*  TODO: Datos del contacto */}
				<div style={{ margin: "1rem" }}>
					<p style={{ fontWeight: "bold", color: "#0087f6" }}>
						Datos de la Compraventa
					</p>
					<hr />

					<div
						style={{ display: "flex", flexDirection: "row" }}
						class="espacio-textfield">
						{/* email */}
						<div style={{ flex: 1, marginRight: "4px" }}>
							<p> Base</p>
							<TextField
								label="Base"
								size="small"
								value={base}
								onChange={handleBaseChange}
								InputProps={{
									endAdornment: (
										<div style={styles.textField}>
											<AttachMoney sx={styles.icon} />
										</div>
									),
								}}
								sx={styles.fullWidthTextField}
							/>
						</div>
						{/* celular */}
						<div style={{ flex: 1, marginRight: "4px" }}>
							<p> Interes</p>
							<TextField
								label="Interes"
								value={interes}
								size="small"
								onChange={handleInterestChange}
								InputProps={{
									endAdornment: (
										<div style={styles.textField}>
											<AttachMoney sx={styles.icon} />
										</div>
									),
								}}
								sx={styles.fullWidthTextField}
							/>
						</div>
					</div>

					<div
						style={{ display: "flex", flexDirection: "row" }}
						class="espacio-textfield">
						<div style={{ flex: 1, marginRight: "4px" }}>
							<p> Monto</p>
							<TextField
								label="Monto"
								size="small"
								value={amount}
								onChange={handleAmountChange}
								InputProps={{
									endAdornment: (
										<div style={styles.textField}>
											<AttachMoney sx={styles.icon} />
										</div>
									),
								}}
								sx={styles.fullWidthTextField}
							/>
						</div>
						<div style={{ flex: 1, marginRight: "4px" }}>
							<p> Estado</p>
							<FormControl
								variant="outlined"
								size="small"
								style={{ width: "100%" }}>
								<InputLabel htmlFor="outlined-age-native-simple">
									Estado
								</InputLabel>
								<Select
									native
									value={estado}
									onChange={handleEstadoChange}
									label="Estado"
									inputProps={{
										name: "rol",
										id: "outlined-state-native-simple",
									}}>
									{estados.map((est) => (
										<option key={est.id} value={est.id}>
											{est.status === "success"
												? "Exitoso"
												: est.status === "solicited"
												? "Solicitado"
												: est.status === "pending"
												? "Pendiente"
												: est.status === "toPickUp"
												? "Por retirar"
												: est.status === "toDeliver"
												? "Por entregar"
												: est.status === "ByCollect"
												? "Por Cobrar"
												: est.status === "inWarehouse"
												? "En Bodega"
												: est.status === "latePayment"
												? "Pago tardío"
												: est.status === "cancelled"
												? "Cancelado"
												: est.status === "received"
												? "Recibido"
												: est.status === "finalAppraisal"
												? "Avalúo final"
												: est.status}
										</option>
									))}
								</Select>
							</FormControl>
						</div>
					</div>
					<div
						style={{ flex: 1, marginRight: "4px" }}
						class="espacio-textfield">
						<p>Descripción</p>
						<TextField
							label="Observación "
							value={observation}
							style={{ width: "100%" }}
							multiline
							onChange={handleObservationChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start"></InputAdornment>
								),
							}}
						/>
					</div>

					<Button
						onClick={guardarDatos}
						style={{
							marginTop: "15px",
							borderRadius: "15px",
							width: "100%",
							height: "40px",
						}}
						variant="contained">
						Confirmar
					</Button>
				</div>
			</div>
		</div>
	);
};

export default EditPawns;
