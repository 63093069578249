import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "../slices/userSlice";
import { appSlice } from "../slices/app.slice";

export const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    app: appSlice.reducer,
  },
});
