import React, { useEffect, useState } from "react";
import getCountries from "../../services/api/country";
import CloseIcon from "@mui/icons-material/Close";
import PinDropIcon from "@mui/icons-material/PinDrop";
import "../css/LateralEdit.css";
import { FormControl, IconButton, Select, MenuItem } from "@mui/material/";
import { useStateContext } from "../../contexts/ContextProvider";

export const DetalleCenterChangeCountry = () => {
  useEffect(() => {
    setDatos();
  }, []);
  const { flagChangeCountry, setChangeCountry, setCountry } = useStateContext();

  const [country, setCurrentCountry] = useState("");
  const [countries, setCountries] = useState([]);

  async function setDatos() {
    setCurrentCountry(localStorage.getItem("country"));

    getCountries()
      .then((response) => {
        setCountries(response);
        console.log(response);
      })
      .catch((e) => console.log(e));
  }

  const handleCountryChange = (event) => {
    setCurrentCountry(event.target.value);
    setCountry(event.target.value);
  };

  return (
    <div
      style={{
        background: "rgba(0, 0, 0, 0.5)",
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          height: "25vh",
          width: "30vw",
          color: "#333",
          background: "#fff",
          padding: "20px",
          borderRadius: "10px",
        }}
      >
        <div style={{ height: "100%", display: "flex", flexWrap: "wrap" }}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <PinDropIcon />
              </div>
              <div>
                <p
                  style={{
                    fontWeight: "bolder",
                    color: "#000",
                    fontSize: "16px",
                    fontStyle: "normal",
                  }}
                >
                  Cambiar de País
                </p>
                <p
                  style={{
                    fontFamily: "Inter, sans-serif",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "133.33%",
                    color: "#737373",
                  }}
                >
                  Elige el país que estás operando
                </p>
              </div>
            </div>

            <IconButton
              onClick={() => setChangeCountry(!flagChangeCountry)}
              aria-label="edit"
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <FormControl>
              {/* <InputLabel variant="standard">País</InputLabel> */}
              <Select onChange={handleCountryChange} value={country}>
                <MenuItem key="Seleccione" value="Seleccione">
                  Seleccione
                </MenuItem>
                {countries.map((countr) => (
                  <MenuItem key={countr.country} value={countr.country}>
                    {countr.country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetalleCenterChangeCountry;
