import axios from "axios";
import API from "../../API";

const urlApi = API.URL + "articles";

export async function getArticlesImagesByIdArticle(idArticle) {
  const newUrl = urlApi + "Images/byId/" + idArticle;
  try {
    let setting = {
      method: "GET",
      url: newUrl,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(newUrl, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getArticles() {
  try {
    let setting = {
      method: "GET",
      url: urlApi,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(urlApi, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getArticlesByName(estado, nombre, country) {
  const urlSearch =
    urlApi + "/ByParam/" + estado + "?country=" + country + "&dato=" + nombre;
  try {
    let setting = {
      method: "GET",
      url: urlSearch,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(urlSearch, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getArticleById(id) {
  let byId = urlApi + "/ById/" + id;
  try {
    let setting = {
      method: "GET",
      url: byId,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(byId, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function updateArticle(article) {
  const url = `${urlApi}/${article.id}`;
  try {
    const setting = {
      method: "PUT",
      url: url,
      headers: { "Content-Type": "application/json" },
      data: article,
    };
    const response = await axios(setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function updateArticleImage(articleImage) {
  const url = `${urlApi + "Images"}/${articleImage.id}`;
  try {
    const setting = {
      method: "PUT",
      url: url,
      headers: { "Content-Type": "application/json" },
      data: articleImage,
    };
    const response = await axios(setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
