import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  TextField,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  TablePagination,
  FormControl,
  MenuItem,
  InputLabel,
  Table,
  Paper,
  Grid,
  Select,
  Chip,
  Box,
  IconButton,
} from "@mui/material/";
import SearchIcon from "@mui/icons-material/Search";
import { EditLateralArticles } from "./components/editArticle";
import {
  getArticlesByName,
  getArticleById,
  updateArticle,
} from "../services/api/articles";
import InputAdornment from "@mui/material/InputAdornment";
import { Link } from "react-router-dom";
import { Header } from "../components";
import { PacmanLoader } from "react-spinners";
import { useStateContext } from "../contexts/ContextProvider";
import { DetalleCenterChangeCountry } from "./descripcion/centerChangeCountry";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from "@mui/icons-material/Restore";
import EditIcon from "@mui/icons-material/Edit";
import TuneIcon from "@mui/icons-material/Tune";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./css/Usuarios.css";
import "./css/format.css";
import { styles } from "./format";
import DetalleLateralArticles from "./descripcion/lateralDetalleArticles";

const Articles = () => {
  const {
    currentColor,
    currentCountry,
    flagUpdateArticle,
    setFlagUpdateArticle,
    setFlagDetalle,
    flagDetalle,
    flagChangeCountry,
    setChangeCountry,
  } = useStateContext();
  const [articles, setArticles] = useState([]);
  const [estado, setEstado] = useState(true);
  const [loading, setLoading] = useState(true);
  const [articleId, setArticleId] = useState("");
  const [search, setSearch] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    setLoading(true);
    setDatos();
  }, [estado, currentCountry, flagChangeCountry, flagUpdateArticle]);

  const deleteArticle = (id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "No se puede revertir",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Si, Elimínalo!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Eliminado!", "El registro fue elminado.", "success");
        //cambiar estado
        getArticleById(id)
          .then(async (response) => {
            response.status = !estado;
            await updateArticle(response);
            await setDatos();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  async function setDatos() {
    const data = await getArticlesByName(
      estado,
      search,
      localStorage.getItem("country")
    );
    setArticles(data);
    setLoading(false);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleNativeSelectChange = (event) => {
    setEstado(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setDatos();
  };

  return (
    <div class="border">
      <div class="border-div">
        <Header category="Artículos" />
        <div class="border-div-div">
          <h1>
            <b>Estado:</b>
          </h1>
          <FormControl sx={{ minWidth: 120 }} size="small">
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={estado}
              onChange={handleNativeSelectChange}
              style={{ backgroundColor: "#ffffff", borderRadius: "8px" }}
            >
              <MenuItem value={true}>Activo</MenuItem>
              <MenuItem value={false}>Eliminados</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <TextField
          label="Busca entre tus clientes"
          id="outlined-size-small"
          size="small"
          onChange={handleSearchChange}
          value={search}
          style={{ backgroundColor: "#ffffff" }}
          InputProps={{
            endAdornment: (
              <div style={styles.textField}>
                <SearchIcon sx={styles.icon} />
              </div>
            ),
          }}
          sx={styles.fullWidthTextField}
        />
        <div class="tune">
          <IconButton aria-label="edit">
            <TuneIcon />
          </IconButton>
        </div>
      </div>

      {loading ? (
        <div className="loader-container">
          <PacmanLoader color={currentColor} size={30} />
        </div>
      ) : (
        <Grid item xs={12} md={12} lg={12}>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">ID</TableCell>
                  <TableCell align="center">Imagen</TableCell>
                  <TableCell align="center">Marca</TableCell>
                  <TableCell align="center">Modelo</TableCell>
                  <TableCell align="center">Categoría</TableCell>
                  <TableCell align="center">Descipción</TableCell>
                  <TableCell align="center" style={{ width: "160px" }}>
                    Acciones
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {articles && articles.length > 0 ? (
                  articles
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell align="center">
                          <img
                            src={row.url}
                            alt="Imagen"
                            style={{
                              borderRadius: "20px",
                              height: "60px",
                            }}
                          />
                        </TableCell>

                        <TableCell align="center">{row.brand}</TableCell>
                        <TableCell align="center">{row.model}</TableCell>
                        <TableCell align="center">
                          <Chip
                            label={row.name}
                            style={{ backgroundColor: "#fdddb7" }}
                          />
                        </TableCell>
                        <TableCell align="center">{row.description}</TableCell>
                        <TableCell align="center">
                          <IconButton
                            aria-label="open"
                            onClick={() => {
                              setArticleId(row.id);
                              setFlagDetalle(true);
                            }}
                          >
                            <ArrowForwardIosIcon></ArrowForwardIosIcon>
                          </IconButton>

                          {/* <Link to={"/editArticles/" + row.id}> */}
                          <IconButton
                            onClick={() => {
                              setArticleId(row.id);
                              setFlagUpdateArticle(true);
                            }}
                            aria-label="edit"
                          >
                            <EditIcon />
                          </IconButton>
                          {/* </Link> */}
                          <IconButton
                            aria-label="delete"
                            onClick={() => deleteArticle(row.id)}
                          >
                            {estado ? <DeleteIcon /> : <RestoreIcon />}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      No hay usuarios disponibles.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={articles ? articles.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={(event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
            />
          </TableContainer>
        </Grid>
      )}
      {flagUpdateArticle && <EditLateralArticles idArticle={{ articleId }} />}
      {flagDetalle && <DetalleLateralArticles idArticle={{ articleId }} />}
      {flagChangeCountry && <DetalleCenterChangeCountry />}
    </div>
  );
};

export default Articles;
