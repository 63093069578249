import axios from "axios";
import API from "../../API";

const urlApi = API.URL + "categories";

export async function getCategories() {
  try {
    let setting = {
      method: "GET",
      url: urlApi,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(urlApi, setting);
    const data = response.data;
    return data;
  } catch (error) {
    // Manejar el error en caso de que la solicitud falle
    console.error(error);
    return null;
  }
}
