import axios from "axios";
import API from "../../API";
const urlApi = API.URL + "pawnsDocuments";

export async function getContractByPawnsId(pawnId) {
  const newUrl = urlApi + "/ByPawnId/" + pawnId;
  try {
    let setting = {
      method: "GET",
      url: newUrl,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(newUrl, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function insertPawnDocument(pawnDocument) {
  try {
    const setting = {
      method: "POST",
      url: urlApi,
      headers: { "Content-Type": "application/json" },
      data: pawnDocument,
    };
    const response = await axios(setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function updateDocumentPawn(documentPawn) {
  const url = `${urlApi}/${documentPawn.id}`;
  try {
    const setting = {
      method: "PUT",
      url: url,
      headers: { "Content-Type": "application/json" },
      data: documentPawn,
    };
    const response = await axios(setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
