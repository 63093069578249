import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../../contexts/ContextProvider";
import {
  TextField,
  Select,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import { uploadImageUser } from "../../services/firebase/config";
import { styles } from "../format";
import InputFile from "./InputFile";
import "../css/LateralEdit.css";
import getCountries from "../../services/api/country";
import { getMotorizedsById } from "../../services/api/motorized";
import { updateMotorized } from "../../services/api/motorized";

export const UpdateLateralMotorized = ({ idMotorized }) => {
  const {
    setFlagInsertMotorized,
    flagInsertMorotized,
    flagUpdateMorotized,
    setFlagUpdateMotorized,
  } = useStateContext();

  const [link, setLink] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);

  const [avatar, setAvatar] = useState("");
  const [file, setFile] = useState(null);
  const [placa, setPlaca] = useState("");
  const [licencia, setLicencia] = useState("");

  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("");

  useEffect(() => {
    setDatos();
  }, []);

  const setDatos = async () => {
    getMotorizedsById(idMotorized.motorizedId)
      .then((response) => {
        setName(response.firstName);
        setLastName(response.lastName);
        setEmail(response.email);
        setPlaca(response.placa);
        setLicencia(response.licencia);
        setPhone(response.phone);
        setCountry(response.countryId);
        setLink(response.avatar);
        console.log(response);
      })
      .catch((e) => console.log(e));

    getCountries()
      .then((response) => {
        setCountries(response);
      })
      .catch((e) => console.log(e));
  };

  const guardarDatos = async () => {
    if (
      name.length !== 0 ||
      lastName.length !== 0 ||
      email.length !== 0 ||
      placa.length !== 0 ||
      phone.length !== 0 ||
      licencia.length !== 0
    ) {
      const urlImg = await uploadImageUser(image);
      const motorizado = {
        id: idMotorized.motorizedId,
        avatar: urlImg,
        firstName: name,
        lastName: lastName,
        email: email,
        placa: placa,
        countryId: country,
        licencia: licencia,
        status: true,
        phone: phone,
      };
      console.log(motorizado);
      const nueva = await updateMotorized(motorizado);
      if (nueva) {
        setFlagUpdateMotorized(false);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Ingrese todos los campos!",
      });
    }
  };

  const colocarImagen = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      console.log("Archivo seleccionado:", selectedImage.name);
      setImage(selectedImage);
      setImageName(selectedImage.name);
    }
  };

  const handleLicenciaChange = (event) => {
    setLicencia(event.target.value);
  };

  const handlePlacaChange = (event) => {
    setPlaca(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };
  return (
    <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
      <div
        className="float-right h-screen dark:text-gray-200  bg-white dark:bg-[#484B52] w-400"
        style={{ overflow: "scroll" }}
      >
        <div className="flex justify-between items-center pt-4 ml-4">
          <p className="font-bold text-lg">Actualizar Motorizado</p>
          <button
            type="button"
            onClick={() => setFlagUpdateMotorized(false)}
            style={{ color: "rgb(153, 171, 180)", borderRadius: "50%" }}
            className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
          >
            <MdOutlineCancel />
          </button>
        </div>
        {/* TODO: Datos del usuario */}
        <div style={{ margin: "1rem" }}>
          <p style={{ fontWeight: "bold", color: "#0087f6" }}>
            Datos de Usuario
          </p>
          <hr />

          <div class="espacio-textfield">
            <p> Nombre </p>
            <TextField
              label="Ingrese un nombre"
              value={name}
              size="small"
              onChange={handleNameChange}
              sx={styles.fullWidthTextField}
            />
          </div>

          <div class="espacio-textfield">
            <p> Apellido </p>
            <TextField
              value={lastName}
              label="Ingrese un apellido"
              size="small"
              onChange={handleLastNameChange}
              sx={styles.fullWidthTextField}
            />
          </div>

          <div class="espacio-textfield">
            <p> Email </p>
            <TextField
              value={email}
              label="Ingrese un Email"
              size="small"
              onChange={handleEmailChange}
              sx={styles.fullWidthTextField}
            />
          </div>

          <div class="espacio-textfield">
            <p> Celular </p>
            <TextField
              value={phone}
              label="Ingrese un Celular"
              size="small"
              onChange={handlePlacaChange}
              sx={styles.fullWidthTextField}
            />
          </div>

          <div class="espacio-textfield">
            <p> Imagen </p>

            <InputFile
              file={image}
              onChange={colocarImagen}
              fileType="imagen"
              nameArchive={imageName || name + lastName + ".jpg"}
            />
          </div>
        </div>

        {/*  TODO: Datos del contacto */}
        <div style={{ margin: "1rem" }}>
          <p style={{ fontWeight: "bold", color: "#0087f6" }}>
            Datos del Vehículo
          </p>
          <hr />

          <div class="espacio-textfield">
            <p>Placa</p>
            <TextField
              label="Placa "
              value={placa}
              style={{ width: "100%" }}
              size="small"
              onChange={handlePlacaChange}
            />
          </div>

          <div class="espacio-textfield">
            <p>Licencia</p>
            <TextField
              label="Licencia"
              value={licencia}
              style={{ width: "100%" }}
              size="small"
              onChange={handleLicenciaChange}
            />
          </div>

          <div
            style={{ flex: 1, marginRight: "4px" }}
            class="espacio-textfield"
          >
            <p>Pais</p>
            <FormControl
              variant="outlined"
              size="small"
              style={{ width: "100%" }}
            >
              <InputLabel htmlFor="outlined-age-native-simple">Pais</InputLabel>
              <Select
                native
                value={countries}
                onChange={handleCountryChange}
                label="País"
                inputProps={{
                  name: "rol",
                  id: "outlined-age-native-simple",
                }}
              >
                {countries.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.country}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>

          <Button
            style={{
              marginTop: "15px",
              borderRadius: "15px",
              width: "100%",
              height: "40px",
            }}
            variant="contained"
            onClick={guardarDatos}
          >
            Confirmar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UpdateLateralMotorized;
