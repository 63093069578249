import axios from "axios";
import API from "../../API";
import Swal from "sweetalert2";
const urlApi = API.URL + "motorized";

export async function getMotorizedsTable(estado, parametro, country) {
  const newUrl =
    urlApi + "/table/" + estado + "?country=" + country + "&dato=" + parametro;
  try {
    let setting = {
      method: "GET",
      url: newUrl,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(newUrl, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getMotorizedById(motorizedId) {
  const newUrl = urlApi + "/" + motorizedId;
  try {
    let setting = {
      method: "GET",
      url: newUrl,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(newUrl, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function updateMotorized(motorized) {
  const url = `${urlApi}/${motorized.id}`;
  try {
    const setting = {
      method: "PUT",
      url: url,
      headers: { "Content-Type": "application/json" },
      data: motorized,
    };
    const response = await axios(setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getMotorizedsById(id) {
  let byId = urlApi + "/" + id;
  try {
    let setting = {
      method: "GET",
      url: byId,
      headers: { Accept: "application/json" },
    };
    const response = await axios.get(byId, setting);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function insertMotorized(motorizado) {
  try {
    const setting = {
      method: "POST",
      url: urlApi,
      headers: { "Content-Type": "application/json" },
      data: motorizado,
    };

    const response = await axios(setting);
    return response.data;
  } catch (error) {
    console.error(error);
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: error.response.data.message,
    });
    return null;
  }
}
