import React, { useEffect, useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../../contexts/ContextProvider";
import { Link } from "react-router-dom";
import "../css/LateralEdit.css";
import { getPawnDetail } from "../../services/api/pawns";
import { getPaymetsByPawn } from "../../services/api/payments";
import { getArticlesImagesByIdArticle } from "../../services/api/articles";
import { getContractByPawnsId } from "../../services/api/pawnDocument";
import TextDescripcion from "../components/labelDescription";
import { DetalleLateralCustomerPayments } from "./lateralDetalleCustomerPayments";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import {
	Table,
	TableRow,
	TableHead,
	TableContainer,
	TableCell,
	TableBody,
} from "@mui/material/";

import { TileLayer, Marker, Popup } from "react-leaflet";
import { MapContainer } from "react-leaflet";

export const DetalleLateralPawns = ({ idPawn }) => {
	const { setFlagDetalle, flagDetalle1, setFlagDetalle1 } = useStateContext();

	const [imagen1, setImagen1] = useState("");
	const [imagen2, setImagen2] = useState("");

	const [status, setStatus] = useState("");
	const [model, setModel] = useState("");
	const [brand, setBrand] = useState("");
	const [usuario, setUsuario] = useState("");
	const [type, setType] = useState("");
	const [asesor, setAsesor] = useState("");
	const [description, setDescription] = useState("");
	const [createdAt, setcreatedAt] = useState("");
	const [locationX, setLocationX] = useState("");
	const [locationY, setLocationY] = useState("");
	const [urlDocument, setUrlDocument] = useState("");
	const [base, setBase] = useState(0);
	const [amount, setAmount] = useState(0);
	const [interest, setInterest] = useState(0);
	const [flagPago, setFlagPago] = useState(false);

	const [payments, setPayments] = useState([]);

	useEffect(() => {
		if (idPawn.pawnId) {
			getArticlesImagesByIdArticle(idPawn.pawnId)
				.then((response) => {
					setImagen1(response[0].url);
					setImagen2(response[1].url);
				})
				.catch((e) => console.log(e));
		}

		setDatos();
	}, []);

	const setDatos = async () => {
		try {
			const response = await getPawnDetail(idPawn.pawnId);
			setModel(response.model);
			setDescription(response.description);
			setBrand(response.brand);
			setType(response.type);

			setStatus(response.status);
			setUsuario(response.usuario);
			setAsesor(response.asesor);
			setBase(response.base);
			setInterest(response.interest);
			setAmount(response.value);
			setcreatedAt(
				format(new Date(response.createdAt), "dd 'de' MMMM 'de' yyyy", {
					locale: es,
				})
			);

			if (response.location) {
				setLocationY(response.location.split(",")[1]);
				setLocationX(response.location.split(",")[0]);
			}

			await getPaymetsByPawn(idPawn.pawnId)
				.then((res) => {
					setPayments(res);
					if (res.length > 0) setFlagPago(true);
					else setFlagPago(false);
				})
				.catch((e) => console.log(e));

			await getArticlesImagesByIdArticle(response.articleid)
				.then((respon) => {
					setImagen1(respon[0].url);
					setImagen2(respon[1].url);
				})
				.catch((e) => console.log(e));

			await getContractByPawnsId(idPawn.pawnId)
				.then((response) => {
					setUrlDocument(response.documentContract);
				})
				.catch((e) => console.log(e));
		} catch (error) {
			console.log(error);
		}
	};

	const MapG1 = () => {
		return (
			<MapContainer
				center={[locationY, locationX]}
				zoom={14}
				scrollWheelZoom={false}
				style={{ height: "400px", width: "100%" }}>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				<Marker position={[locationY, locationX]}>
					<Popup>Ubicación de la Compraventa</Popup>
				</Marker>
			</MapContainer>
		);
	};
	return (
		<div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
			<div
				className="float-right h-screen dark:text-gray-200 bg-white dark:bg-[#484B52]"
				style={{ overflow: "scroll", width: "50%" }}>
				<div className="flex justify-between items-center pt-4 ml-4">
					<p className="font-bold text-lg">Detalle de la Compraventa</p>
					<button
						type="button"
						onClick={() => setFlagDetalle(false)}
						style={{ color: "rgb(153, 171, 180)", borderRadius: "50%" }}
						className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray">
						<MdOutlineCancel />
					</button>
				</div>
				{/* TODO: Datos del usuario */}
				<div style={{ margin: "1rem" }}>
					<p style={{ fontWeight: "bold", color: "#0087f6" }}>
						Datos del Artículo
					</p>
					<hr />

					<TextDescripcion
						tittle1="Modelo"
						description1={model}
						tittle2="Marca"
						description2={brand}
					/>

					<TextDescripcion
						tittle1="Tipo"
						description1={type}
						tittle2="Estado"
						description2={
							status === "solicited"
								? "Solicitado"
								: status === "pending"
								? "Pendiente"
								: status === "toPickUp"
								? "Por retirar"
								: status === "toDeliver"
								? "Por entregar"
								: status === "ByCollect"
								? "Por Cobrar"
								: status === "latePayment"
								? "Pago tardío"
								: status === "cancelled"
								? "Cancelado"
								: status === "success"
								? "Exitoso"
								: status === "received"
								? "Recibido"
								: status === "inWarehouse"
								? "En Bodega"
								: status === "finalAppraisal"
								? "Avalúo final"
								: status
						}
					/>

					<div style={{ flex: "1" }}>
						<p style={{ fontWeight: "bolder" }}> Descripción</p>
						<p style={{ marginLeft: "10px" }}> {description} </p>
					</div>
				</div>
				<div style={{ margin: "1rem" }}>
					<p style={{ fontWeight: "bold", color: "#0087f6" }}>
						Datos de la Compraventa
					</p>
					<hr />
					<TextDescripcion
						tittle1="Cliente"
						description1={usuario}
						tittle2="Asesor"
						description2={asesor}
					/>

					<TextDescripcion
						tittle1="Base"
						description1={
							base
								? localStorage.getItem("country") === "Ecuador"
									? base.toFixed(2) + " $"
									: localStorage.getItem("country") === "Chile"
									? base.toFixed(0) + " CPL "
									: null
								: "No Asignado"
						}
						tittle2="Interes"
						description2={
							interest
								? localStorage.getItem("country") === "Ecuador"
									? interest.toFixed(2) + " $"
									: localStorage.getItem("country") === "Chile"
									? interest.toFixed(0) + " CPL "
									: null
								: "No Asignado"
						}
					/>

					<TextDescripcion
						tittle1="Fecha de la Compraventa"
						description1={createdAt}
						tittle2="Monto"
						description2={
							amount
								? localStorage.getItem("country") === "Ecuador"
									? base.toFixed(2) + " $"
									: localStorage.getItem("country") === "Chile"
									? base.toFixed(0) + " CPL "
									: null
								: "No Asignado"
						}
					/>
				</div>

				{payments.length > 0 ? (
					<div style={{ margin: "1rem" }}>
						<p style={{ fontWeight: "bold", color: "#0087f6" }}>Pagos</p>
						<hr />
						<TableContainer>
							<Table
								sx={{ minWidth: 650 }}
								size="small"
								aria-label="a dense table">
								<TableHead>
									<TableRow>
										<TableCell align="center">ID</TableCell>
										<TableCell align="center">Fecha De Pago</TableCell>
										<TableCell align="center">Estado</TableCell>
										<TableCell align="center">Monto</TableCell>
										<TableCell align="center">Balance</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{payments && payments.length > 0
										? payments.map((row) => (
												<TableRow key={row.id}>
													<TableCell align="center" component="th" scope="row">
														<Link to={"/pawns/Detail/" + row.id}>{row.id}</Link>
													</TableCell>
													<TableCell align="center" component="th" scope="row">
														<Link to={"/pawns/Detail/" + row.id}>
															{format(
																new Date(row.fechaPago),
																"dd 'de' MMMM 'del' yyyy",
																{
																	locale: es,
																}
															)}
														</Link>
													</TableCell>
													<TableCell align="center" component="th" scope="row">
														<Link to={"/pawns/Detail/" + row.id}>
															{row.status === "pending"
																? "Pendiente"
																: row.status === "perReview"
																? "Por revisión"
																: row.status === "paymentConfirmed"
																? "Pago Confirmado"
																: row.status === "paymentDeclined"
																? "Pago Rechazado"
																: null}
														</Link>
													</TableCell>
													<TableCell align="center" component="th" scope="row">
														<Link to={"/pawns/Detail/" + row.id}>
															{localStorage.getItem("country") === "Ecuador"
																? row.amount.toFixed(2) + " $"
																: localStorage.getItem("country") === "Chile"
																? row.amount.toFixed(0) + " CLP"
																: null}
														</Link>
													</TableCell>
													<TableCell align="center" component="th" scope="row">
														<Link to={"/pawns/Detail/" + row.id}>
															{localStorage.getItem("country") === "Ecuador"
																? row.balance.toFixed(2) + " $"
																: localStorage.getItem("country") === "Chile"
																? row.balance.toFixed(0) + " CLP"
																: null}
														</Link>
													</TableCell>
												</TableRow>
										  ))
										: null}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
				) : null}

				<div style={{ margin: "1rem" }}>
					<p style={{ fontWeight: "bold", color: "#0087f6" }}>Imágenes</p>
					<hr />
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "row",
						}}>
						<img
							src={imagen1}
							alt="Imagen centrada"
							width="200"
							height="150"
							style={{ borderRadius: "10px" }}
						/>

						<img
							src={imagen2}
							alt="Imagen centrada"
							width="200"
							height="150"
							style={{ borderRadius: "10px", marginTop: "10px" }}
						/>
					</div>
				</div>

				{urlDocument ? (
					<div style={{ margin: "1rem" }}>
						<p style={{ fontWeight: "bold", color: "#0087f6" }}>Contrato</p>
						<hr />

						<div>
							<iframe
								title="Documento PDF"
								src={urlDocument}
								width="100%"
								height="500px">
								El navegador no admite la visualización de documentos PDF.
								Puedes descargarlo aquí:
								<a href={urlDocument}>Descargar PDF</a>
							</iframe>
						</div>
					</div>
				) : null}

				{locationX ? (
					<div style={{ margin: "1rem" }}>
						<p style={{ fontWeight: "bold", color: "#0087f6" }}>Ubicación</p>
						<hr />

						<div>
							<div className="h-400px w-full outline-none">
								<MapG1 />
							</div>
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default DetalleLateralPawns;
