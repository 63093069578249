import React, { useState } from "react";
import Chart from "react-apexcharts";
export default function BarChart(props) {
  console.log(props);

  const [series, setSeries] = useState(props.values);
  const [options, setOptions] = useState({
    colors: ["#F39522", "#0087F6", "#6ED591", "#BA6ED5"],

    chart: {
      height: 500,
      type: "line",

      toolbar: {
        show: true,
      },
    },
    stroke: {
      curve: "smooth",
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    dataLabels: {
      enabled: true,

      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },

    xaxis: {
      categories: props.categories,
      title: {
        text: "Meses",
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yaxis: {
      title: {
        text: "Dinero",
      },
      labels: {
        show: true,
        formatter: function (val) {
          return "$" + val;
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      floating: true,
      offsetY: -7,
    },
    title: {
      text: props.text,
      floating: true,
      offsetY: 0,
      align: "center",
      style: {
        color: "#444",
      },
    },
  });

  return (
    <Chart
      options={options}
      series={series}
      height={350}
      type="line"
      width="100%"
      style={{ padding: "10px", width: "100%" }}
    />
  );
}
