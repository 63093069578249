import React, { useState, useContext } from "react";

import Chart from "react-apexcharts";
export default function BarChart(props) {
  console.log(props);
  const [opciones, setOpciones] = React.useState([]);
  const [series, setSeries] = useState(props.values);
  const [options, setOptions] = useState({
    colors: ["#0087F6", "#77B5FC", "#4C72F9", "#60C2E1"],
    chart: {
      type: "donut",
    },
    labels: props.categories,

    title: {
      text: props.text,

      offsetY: 0,
      align: "center",
    },
  });

  return (
    <Chart
      options={options}
      series={series}
      type="donut"
      width="100%"
      height="100%"
      style={{ padding: "10px" }}
    />
  );
}
