import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { Navbar, Footer, Sidebar, ThemeSettings } from "./components";
import { Login } from "./pages";

import "./App.css";

import { useStateContext } from "./contexts/ContextProvider";
import { useState } from "react";
import Routers from "./Routes";
import { store } from "./utils/redux/store";
import { Box, Toolbar } from "@mui/material";

const App = () => {
	const dataToken = localStorage.getItem("token");
	const [loged, setLoged] = useState(false);
	const {
		setCurrentColor,
		setCurrentMode,
		currentMode,
		activeMenu,
		currentColor,
		themeSettings,
		screenSize,
		setThemeSettings,
	} = useStateContext();

	useEffect(() => {
		if (localStorage.getItem("country")) console.log("No hay país");
		const currentCountry = localStorage.getItem("country");
		console.log(currentCountry);
		const currentThemeColor = localStorage.getItem("colorMode");
		const currentThemeMode = localStorage.getItem("themeMode");
		dataToken
			? setLoged(JSON.parse(localStorage.getItem("token")))
			: setLoged(false);

		if (currentThemeColor && currentThemeMode) {
			setCurrentColor(currentThemeColor);
			setCurrentMode(currentThemeMode);
		}
	}, [setCurrentColor, setCurrentMode, dataToken]);

	return (
		<Provider store={store}>
			<div className={currentMode === "Dark" ? "dark" : ""}>
				<BrowserRouter>
					{!loged ? (
						<Login />
					) : (
						<div className=" ">
							<div
								className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full "
								style={{ width: "100%" }}>
								<Navbar />
							</div>

							<div style={{ display: "flex" }}>
								<div
									style={{
										width: screenSize < 1000 ? "50px" : "20%",
										height: "100%",
									}}>
									<Sidebar />
								</div>
								<div
									style={{
										backgroundColor: "#f4f4f4",
										width: "100%",
										padding: 10,
									}}>
									<Toolbar sx={{ display: { xs: "auto", md: "none" } }} />
									<Box sx={{ display: { xs: "none", md: "auto" } }}>
										{themeSettings && <ThemeSettings />}
									</Box>
									<Routers />
								</div>
							</div>
						</div>
					)}
				</BrowserRouter>
			</div>
		</Provider>
	);
};

export default App;
