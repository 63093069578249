import React, { useEffect, useState } from "react";
import {
	TextField,
	FormControl,
	MenuItem,
	IconButton,
	Select,
	Table,
	TableRow,
	TableHead,
	TableContainer,
	TablePagination,
	TableCell,
	TableBody,
	Grid,
	Paper,
	Menu,
	ListItemText,
	ListItemIcon,
	Chip,
} from "@mui/material/";

import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { getPawnStatus } from "../services/api/pawnStatus";
import { EditPawns } from "./components/editPawns";
import Check from "@mui/icons-material/Check";

import { DetalleLateralPawns } from "./descripcion/lateralDetallePawn";
import { DetalleCenterChangeCountry } from "./descripcion/centerChangeCountry";
import { useStateContext, currentCountry } from "../contexts/ContextProvider";
import {
	getPawnsTable,
	updatePawn,
	getPawnDetail,
} from "../services/api/pawns";
import { Header } from "../components";
import { PacmanLoader } from "react-spinners";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from "@mui/icons-material/Restore";
import SearchIcon from "@mui/icons-material/Search";

import TuneIcon from "@mui/icons-material/Tune";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { styles } from "./format";
import "./css/format.css";

const Pawns = () => {
	const [pawns, setPawns] = useState([]);
	const [pawnId, setPawnId] = useState("");
	const {
		currentColor,
		currentCountry,
		flagUpdatePawn,
		setFlagDetalle,
		flagDetalle,
		setFlagUpdatePawn,
		flagChangeCountry,
		setChangeCountry,
	} = useStateContext();
	const [loading, setLoading] = useState(true);
	const [estado, setEstado] = useState(true);
	const [search, setSearch] = useState("");

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const [estados, setEstados] = useState([]);
	const [currentStatus, setCurrentStatus] = useState("Todos");

	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleMenuFilterChange = (status) => {
		// const selectedValue = event.currentTarget.textContent;
		// console.log(event);
		// console.log(status);
		// setCurrentStatus(status);
		setAnchorEl(null);
	};

	useEffect(() => {
		setFlagUpdatePawn(false);
		setDatos();
	}, [estado, search, currentCountry, currentStatus]);

	async function setDatos() {
		const data = await getPawnsTable({
			estado,
			currentStatus,
			search,
			country: localStorage.getItem("country"),
		});
		setPawns(data);
		setLoading(false);

		await getPawnStatus()
			.then((response) => {
				for (let i = 0; i < response.length; i++) {
					if (response[i].status === "solicited")
						response[i].estado = "Solicitado";
					if (response[i].status === "inWarehouse")
						response[i].estado = "En Bodega";
					if (response[i].status === "pending")
						response[i].estado = "Pendiente";
					if (response[i].status === "toPickUp")
						response[i].estado = "Por retirar";
					if (response[i].status === "toDeliver")
						response[i].estado = "Por entregar";
					if (response[i].status === "ByCollect")
						response[i].estado = "Por Cobrar";
					if (response[i].status === "latePayment")
						response[i].estado = "Pago tardío";
					if (response[i].status === "cancelled")
						response[i].estado = "Cancelado";
					if (response[i].status === "success") response[i].estado = "Exitoso";
					if (response[i].status === "received")
						response[i].estado = "Recibido";
					if (response[i].status === "finalAppraisal")
						response[i].estado = "Avalúo final";
				}
				setEstados(response);
			})
			.catch((e) => console.log(e));
	}

	const handleNativeSelectChange = (event) => {
		setEstado(event.target.value);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleSearchChange = (event) => {
		setSearch(event.target.value);
		setDatos();
	};

	const deleteUser = (id) => {
		Swal.fire({
			title: "¿Estás seguro?",
			text: "No se puede revertir",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "¡Si, Elimínalo!",
			cancelButtonText: "Cancelar",
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire("Eliminado!", "El registro fue elminado.", "success");
				//cambiar estado
				getPawnDetail(id)
					.then(async (response) => {
						response.status = !estado;
						await updatePawn(response);
						await setDatos();
					})
					.catch((error) => {
						console.log(error);
					});
			}
		});
	};

	return (
		<div class="border">
			<div class="border-div">
				<Header category="Compraventas" />
				<div class="border-div-div">
					<h1>
						<b>Estado:</b>
					</h1>
					<FormControl sx={{ minWidth: 120 }} size="small">
						<Select
							labelId="demo-select-small-label"
							id="demo-select-small"
							value={estado}
							onChange={handleNativeSelectChange}
							style={{ backgroundColor: "#ffffff", borderRadius: "8px" }}>
							<MenuItem value={true}>Activo</MenuItem>
							<MenuItem value={false}>Eliminados</MenuItem>
						</Select>
					</FormControl>
				</div>
			</div>

			<div style={{ display: "flex", flexDirection: "row" }}>
				<TextField
					label="Busca entre tus compraventas"
					id="outlined-size-small"
					size="small"
					onChange={handleSearchChange}
					value={search}
					style={{ backgroundColor: "#ffffff" }}
					InputProps={{
						endAdornment: (
							<div style={styles.textField}>
								<SearchIcon sx={styles.icon} />
							</div>
						),
					}}
					sx={styles.fullWidthTextField}
				/>
				<div class="tune">
					<IconButton aria-label="edit" onClick={handleClick}>
						<TuneIcon />
					</IconButton>
					<Menu
						id="basic-menu"
						anchorEl={anchorEl}
						open={open}
						onClose={handleClose}
						MenuListProps={{
							"aria-labelledby": "basic-button",
						}}>
						{currentStatus != "Todos" ? (
							<MenuItem
								onClick={(e) => {
									setCurrentStatus("Todos");
								}}>
								<ListItemText inset>Todos</ListItemText>
							</MenuItem>
						) : (
							<MenuItem>
								<ListItemIcon>
									<Check />
								</ListItemIcon>
								Todos
							</MenuItem>
						)}
						{estados.map((row) =>
							currentStatus == row.status ? (
								<MenuItem>
									<ListItemIcon>
										<Check />
									</ListItemIcon>
									{row.estado}
								</MenuItem>
							) : (
								<MenuItem
									onClick={(e) => {
										setCurrentStatus(row.status);
									}}>
									<ListItemText inset>{row.estado}</ListItemText>
								</MenuItem>
							)
						)}
					</Menu>
				</div>
			</div>

			{/* FIN */}
			{loading ? (
				<div className="loader-container">
					<PacmanLoader color={currentColor} size={30} />
				</div>
			) : (
				<Grid item xs={12} md={12} lg={12}>
					<TableContainer component={Paper}>
						<Table
							sx={{ minWidth: 650 }}
							size="small"
							aria-label="a dense table">
							<TableHead>
								<TableRow>
									<TableCell align="center">ID</TableCell>
									<TableCell align="center">Modelo</TableCell>
									<TableCell align="center">Marca</TableCell>
									<TableCell align="center">Usuario</TableCell>
									<TableCell align="center">Asesor</TableCell>
									<TableCell align="center">Estado</TableCell>
									<TableCell align="center">Imagen</TableCell>
									<TableCell align="center" style={{ width: "160px" }}>
										Acciones
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{pawns && pawns.length > 0 ? (
									pawns
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row) => (
											<TableRow key={row.id}>
												<TableCell component="th" scope="row">
													{row.id}
												</TableCell>
												<TableCell component="th" scope="row">
													{row.model}
												</TableCell>
												<TableCell component="th" scope="row">
													{row.brand}
												</TableCell>
												<TableCell component="th" scope="row">
													{row.usuario}
												</TableCell>
												<TableCell component="th" scope="row">
													{row.asesor}
												</TableCell>
												<TableCell align="center">
													<Chip
														label={
															row.status === "solicited"
																? "Solicitado"
																: row.status === "pending"
																? "Pendiente"
																: row.status === "inWarehouse"
																? "En Bodega"
																: row.status === "toPickUp"
																? "Por retirar"
																: row.status === "toDeliver"
																? "Por entregar"
																: row.status === "ByCollect"
																? "Por Cobrar"
																: row.status === "latePayment"
																? "Pago tardío"
																: row.status === "cancelled"
																? "Cancelado"
																: row.status === "success"
																? "Exitoso"
																: row.status === "received"
																? "Recibido"
																: row.status === "finalAppraisal"
																? "Avalúo final"
																: row.status
														}
														style={{ backgroundColor: "#fdddb7" }}
													/>
												</TableCell>
												<TableCell align="center">
													<div
														style={{
															display: "flex",
															justifyContent: "center",
															alignItems: "center",
															height: "100%",
														}}>
														<img
															src={row.url}
															alt="Imagen"
															style={{
																borderRadius: "20px",
																width: "60px",
																height: "60px",
																backgroundSize: "cover",
															}}
														/>
													</div>
												</TableCell>

												<TableCell align="center">
													<IconButton
														aria-label="open"
														onClick={() => {
															setPawnId(row.id);
															setFlagDetalle(true);
															setDatos();
														}}>
														<ArrowForwardIosIcon></ArrowForwardIosIcon>
													</IconButton>
													<IconButton
														aria-label="edit"
														onClick={() => {
															setPawnId(row.id);
															setFlagUpdatePawn(true);
															setDatos();
														}}>
														<EditIcon />
													</IconButton>
													<IconButton
														aria-label="delete"
														onClick={() => deleteUser(row.id)}>
														{estado ? <DeleteIcon /> : <RestoreIcon />}
													</IconButton>
												</TableCell>
											</TableRow>
										))
								) : (
									<TableRow>
										<TableCell colSpan={8} align="center">
											No hay Compraventas disponibles.
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25]}
							component="div"
							count={pawns ? pawns.length : 0}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={(event) => {
								setRowsPerPage(parseInt(event.target.value, 10));
								setPage(0);
							}}
						/>
					</TableContainer>
				</Grid>
			)}
			{flagUpdatePawn && <EditPawns idPawn={{ pawnId }} />}
			{flagDetalle && <DetalleLateralPawns idPawn={{ pawnId }} />}
			{flagChangeCountry && <DetalleCenterChangeCountry />}
		</div>
	);
};

export default Pawns;
