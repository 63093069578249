import React from "react";
import "../css/InputFile.css";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
const InputFile = ({ file, onChange, fileType, nameArchive }) => {
  const tipoArchivo = (fileType) => {
    if (fileType === "imagen") {
      return "image/*";
    } else if (fileType === "documento") {
      return ".pdf,.doc,.docx,.txt";
    } else {
      return "*/*"; // Valor predeterminado, acepta cualquier tipo de archivo
    }
  };
  return (
    <div className="file-select" id="src-file1">
      <div className="inputContent">
        <span
          style={{
            color: "#1C1B1F",
            display: "inline-block",
            width: "60%",
            overflow: "hidden",
          }}
        >
          {file?.name ? file.name : nameArchive}
        </span>
        <input
          type="file"
          name="src-file1"
          aria-label="Archivo"
          onChange={onChange}
          accept={tipoArchivo(fileType)}
        />
        <button className="btn-upload">
          <DriveFolderUploadIcon style={{ color: "#1C1B1F" }} />
        </button>
      </div>
    </div>
  );
};
export default InputFile;
