import { React, useEffect, useState } from "react";
import "./css/EditUsers.css";
import Swal from "sweetalert2";
import { useParams, Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { insertMotorized, updateMotorized } from "../services/api/motorized";
import {
  TextField,
  Box,
  Button,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useStateContext } from "../contexts/ContextProvider";
import CalendarViewDayIcon from "@mui/icons-material/CalendarViewDay";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import getCountries from "../services/api/country";
import { getMotorizedsById } from "../services/api/motorized";

import { boxStyles } from "./css/inserUsersStyle";
import { uploadImageUser } from "../services/firebase/config";

const EditMotorizeds = () => {
  const { id } = useParams();

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [placa, setPlaca] = useState("");
  const [licencia, setLicencia] = useState("");

  const [image, setImage] = useState(null);
  const [link, setLink] = useState("");
  const [flag, setFlag] = useState(false);

  const [country, setCountry] = useState();
  const [countries, setCountries] = useState([]);

  const { currentColor } = useStateContext();

  useEffect(() => {
    setDatos();
  }, []);

  const setDatos = async () => {
    getMotorizedsById(id)
      .then((response) => {
        setName(response.firstName);
        setLastName(response.lastName);
        setEmail(response.email);
        setPlaca(response.placa);
        setLicencia(response.licencia);
        setPhone(response.phone);
        setCountry(response.countryId);
        setLink(response.avatar);
        console.log(response);
      })
      .catch((e) => console.log(e));

    getCountries()
      .then((response) => {
        setCountries(response);
      })
      .catch((e) => console.log(e));
  };
  const handleLicenciaChange = (event) => {
    setLicencia(event.target.value);
  };

  const handlePlacaChange = (event) => {
    setPlaca(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const colocarImagen = async (event) => {
    const selectedFile = event.target.files[0];
    setImage(selectedFile);
    const imageURL = URL.createObjectURL(selectedFile);
    setLink(imageURL);
  };

  const guardarDatos = async () => {
    if (
      name.length !== 0 ||
      lastName.length !== 0 ||
      email.length !== 0 ||
      placa.length !== 0 ||
      phone.length !== 0 ||
      licencia.length !== 0
    ) {
      const urlImg = await uploadImageUser(image);
      const motorizado = {
        avatar: urlImg,
        firstName: name,
        lastName: lastName,
        email: email,
        placa: placa,
        countryId: country,
        licencia: licencia,
        status: true,
        phone: phone,
      };
      console.log(motorizado);
      const nueva = await updateMotorized(motorizado);
      if (nueva) {
        setFlag(true);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Ingrese todos los campos!",
      });
    }
  };

  return (
    <div className="m-4 mt-1 p-5 bg-white rounded-3xl">
      {flag ? <Navigate to="/motorist" /> : null}
      <Link to="/Users" className="mb-4 ml-2">
        <ArrowBackIcon fontSize="large" />
      </Link>
      <div style={{ textAlign: "center", marginBottom: "40px" }}>
        <h1 style={{ fontWeight: "bold", fontSize: "30px" }}>
          Editar Motorizado
        </h1>
      </div>

      <div
        className="grid grid-cols-2 gap-2 text-center"
        style={{ display: "flex", justifyContent: "space-around" }}
      >
        <div>
          <Box sx={boxStyles}>
            <TextField
              id="inputNombre"
              label="Nombre"
              value={name}
              onChange={handleNameChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={boxStyles}>
            <TextField
              id="inputApellido"
              label="Apellido"
              value={lastName}
              onChange={handleLastNameChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={boxStyles}>
            <TextField
              id="inputEmail"
              label="Email"
              value={email}
              onChange={handleEmailChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AlternateEmailIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </div>
        <div>
          <Box sx={boxStyles}>
            <TextField
              id="inputPlaca"
              label="Placa"
              value={placa}
              onChange={handlePlacaChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BrandingWatermarkIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={boxStyles}>
            <TextField
              id="inputLicencia"
              label="Licencia"
              value={licencia}
              onChange={handleLicenciaChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarViewDayIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={boxStyles}>
            <TextField
              id="inputCelular"
              label="Celular"
              value={phone}
              onChange={handlePhoneChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SmartphoneIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={boxStyles}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="outlined-age-native-simple">País</InputLabel>
              <Select
                native
                value={country}
                onChange={handleCountryChange}
                label="Country"
                inputProps={{
                  name: "country",
                  id: "outlined-age-native-simple",
                }}
              >
                {countries.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.country}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
      </div>

      <div className="container">
        <img
          src={link}
          alt="Imagen"
          className="w-32 h-32 rounded-lg border-2 my-4 mx-auto"
          style={{
            borderRadius: "20px",
            backgroundSize: "cover",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "25px",
        }}
      >
        <Box sx={boxStyles}>
          <input type="file" name="" id="" onChange={colocarImagen} />
        </Box>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          style={{ backgroundColor: currentColor, marginRight: "100px" }}
          onClick={guardarDatos}
          variant="contained"
        >
          Aceptar
        </Button>
        <Link to="/motorist">
          <Button style={{ backgroundColor: currentColor }} variant="contained">
            Cancelar
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default EditMotorizeds;
