import React, { useEffect, useState } from "react";

import { MdOutlineCancel } from "react-icons/md";
import { useStateContext } from "../../contexts/ContextProvider";
import {
  TextField,
  Select,
  FormControl,
  InputLabel,
  Button,
  Drawer,
} from "@mui/material";
import Swal from "sweetalert2";
import { styles } from "../format";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import InputFile from "./InputFile";

import getRols from "../../services/api/rols";
import { getUsersById, updateUser } from "../../services/api/users";
import getCountries from "../../services/api/country";
import "../css/LateralEdit.css";
import { uploadImageUser } from "../../services/firebase/config";
import { EmailOutlined, PhoneAndroid } from "@material-ui/icons";

export const EditLateralUsers = ({
  actualizar,
  setActualizar,
  idUsers,
  flagUpdateUser,
  toggleDrawer,
}) => {
  const { setFlagUpdateUser } = useStateContext();
  const [showPassword, setShowPassword] = useState(false);
  const [name, setName] = useState("");
  const [avatarDef, setAvatarDef] = useState("");

  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [cedula, setCedula] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [rol, setRol] = useState("1");
  const [roles, setRoles] = useState([]);
  const [pass1, setPass1] = useState("");
  const [fechaSeleccionada, setFechaSeleccionada] = useState(null);
  const [pass2, setPass2] = useState("");
  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);
  const [avatar, setAvatar] = useState("");
  const [imageName, setImageName] = useState("");

  const [flag, setFlag] = useState(false);

  const [country, setCountry] = useState();
  const [countries, setCountries] = useState([]);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    getRols()
      .then((response) => {
        setRoles(response);
      })
      .catch((e) => console.log(e));
    getCountries()
      .then((response) => {
        setCountries(response);
      })
      .catch((e) => console.log(e));
    setDatos();
  }, []);

  const setDatos = async () => {
    try {
      await getUsersById(idUsers.userId)
        .then((response) => {
          setAvatar(response.avatar);
          setAvatarDef(response.avatar);
          setName(response.firstName);
          setEmail(response.email);
          setLastName(response.lastName);
          setCedula(response.cedula);
          setFechaSeleccionada(response.birthDay);
          setAddress(response.address);
          setCountry(response.countrieId);
          console.log("el país es " + response.countrieId);
          setPhone(response.phone);
          setRol(response.rolId);
        })
        .catch((e) => console.log(e));

      // let cl = await getEsCliente(id);
      // setEsCliente(cl[0].escliente);

      // let ad = await getEsAdministrativo(id);
      // setEsAdmin(ad[0].esadministrativo);
    } catch (error) {
      console.log(error);
    }
  };

  const guardarDatos = async () => {
    if (
      name.length > 0 &&
      lastName.length > 0 &&
      cedula.length > 0 &&
      phone.length > 0 &&
      address.length > 0
    ) {
      const today = dayjs();
      const age = today.diff(fechaSeleccionada, "year");

      if (age > 18) {
        let urlAvatar = " ";
        if (avatar !== avatarDef) urlAvatar = await uploadImageUser(file);
        else urlAvatar = avatarDef;

        // console.log(urlAvatar);

        const usuario = {
          id: idUsers.userId,
          avatar: urlAvatar,
          firstName: name,
          lastName: lastName,
          email: email,
          cedula: cedula,
          phone: phone,
          birthDay: fechaSeleccionada,
          address: address,
          rolId: rol,
          countrieId: country,
        };
        updateUser(usuario, setActualizar, actualizar);

        setFlagUpdateUser(false);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Debes tener al menos 18 años para registrarte",
        });
        return;
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Ingrese todos los campos!",
      });
    }
  };

  const colocarImagen = (event) => {
    const selectedImage = event.target.files[0];
    if (selectedImage) {
      console.log("Archivo seleccionado:", selectedImage.name);
      setAvatar(selectedImage);
      setImageName(selectedImage.name);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleCountryChange = (event) => {
    const selectedCountryValue = event.target.value;
    const selectedCountryObject = countries.find(
      (country) => country.country === selectedCountryValue
    );
    setCountry(selectedCountryObject);
  };
  const handleCedulaChange = (event) => {
    setCedula(event.target.value);
  };
  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };
  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };
  const handlePass1Change = (event) => {
    setPass1(event.target.value);
  };
  const handlePass2Change = (event) => {
    setPass2(event.target.value);
  };
  const handleFechaChange = (event) => {
    setFechaSeleccionada(event);
  };
  const handleRoleChange = (event) => {
    setRol(event.target.value);
  };
  return (
    <Drawer
      anchor={"right"}
      open={flagUpdateUser}
      onClose={toggleDrawer(false)}
    >
      <div>
        <div
          className="h-screen dark:text-gray-200  bg-white dark:bg-[#484B52] w-400"
          style={{ overflow: "scroll" }}
        >
          <div className="flex justify-between items-center pt-4 ml-4">
            <p className="font-bold text-lg">Editar Usuario</p>
            <button
              type="button"
              onClick={() => setFlagUpdateUser(false)}
              style={{ color: "rgb(153, 171, 180)", borderRadius: "50%" }}
              className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
            >
              <MdOutlineCancel />
            </button>
          </div>
          {/* TODO: Datos del usuario */}
          <div style={{ margin: "1rem" }}>
            <p style={{ fontWeight: "bold", color: "#0087f6" }}>
              Datos del usuario
            </p>
            <hr />

            {/* Nombre */}
            <div class="espacio-textfield">
              <p style={{ fontWeight: "bolder" }}> Nombre</p>
              <TextField
                label="Ingresa un Nombre"
                size="small"
                value={name}
                onChange={handleNameChange}
                sx={styles.fullWidthTextField}
              />
            </div>

            {/*  Apellido */}
            <div class="espacio-textfield">
              <p style={{ fontWeight: "bolder" }}> Apellidos</p>
              <TextField
                label="Ingresa los apellidos"
                size="small"
                value={lastName}
                onChange={handleLastNameChange}
                sx={styles.fullWidthTextField}
              />
            </div>

            {/*  cedula */}
            <div class="espacio-textfield">
              <p style={{ fontWeight: "bolder" }}> Cédula de Indentidad</p>
              <TextField
                label="Ingresa una cedula"
                size="small"
                value={cedula}
                onChange={handleCedulaChange}
                sx={styles.fullWidthTextField}
              />
            </div>

            {/* Fecha Nacimiento y Rol */}
            <div
              style={{ display: "flex", flexDirection: "row" }}
              class="espacio-textfield"
            >
              <div style={{ flex: 1, marginRight: "4px" }}>
                <p style={{ fontWeight: "bolder" }}> Fecha de Nacimiento</p>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={fechaSeleccionada ? dayjs(fechaSeleccionada) : null}
                    onChange={handleFechaChange}
                    format="DD/MM/YYYY"
                  />
                </LocalizationProvider>
              </div>

              <div style={{ flex: 1 }}>
                <p style={{ fontWeight: "bolder" }}> Rol</p>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Rol
                  </InputLabel>
                  <Select
                    native
                    value={rol}
                    onChange={handleRoleChange}
                    label="Rol"
                    inputProps={{
                      name: "rol",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    {roles.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.rol}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            {/*  Imagen */}
            <div class="espacio-textfield">
              <p style={{ fontWeight: "bolder" }}> Imagen</p>

              <InputFile
                file={image}
                onChange={colocarImagen}
                fileType="imagen"
                nameArchive={imageName || cedula + ".jpg"}
              />
            </div>
          </div>
          {/*  TODO: Datos del contacto */}
          <div style={{ margin: "1rem" }}>
            <p style={{ fontWeight: "bold", color: "#0087f6" }}>
              Datos del contacto
            </p>
            <hr />

            <div
              style={{ display: "flex", flexDirection: "row" }}
              class="espacio-textfield"
            >
              {/* email */}
              <div style={{ flex: 1, marginRight: "4px" }}>
                <p style={{ fontWeight: "bolder" }}> Email</p>
                <TextField
                  label="Email"
                  size="small"
                  value={email}
                  onChange={handleEmailChange}
                  InputProps={{
                    endAdornment: (
                      <div style={styles.textField}>
                        <EmailOutlined sx={styles.icon} />
                      </div>
                    ),
                  }}
                  sx={styles.fullWidthTextField}
                />
              </div>
              {/* celular */}
              <div style={{ flex: 1, marginRight: "4px" }}>
                <p style={{ fontWeight: "bolder" }}> Celular</p>
                <TextField
                  label="Celular"
                  size="small"
                  value={phone}
                  onChange={handlePhoneChange}
                  InputProps={{
                    endAdornment: (
                      <div style={styles.textField}>
                        <PhoneAndroid sx={styles.icon} />
                      </div>
                    ),
                  }}
                  sx={styles.fullWidthTextField}
                />
              </div>
            </div>

            <div class="espacio-textfield">
              <p style={{ fontWeight: "bolder" }}> País</p>
              <FormControl style={{ width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-age-native-simple">
                  País
                </InputLabel>
                <Select
                  native
                  value={country}
                  onChange={handleCountryChange}
                  label="Country"
                  inputProps={{
                    name: "country",
                    id: "outlined-age-native-simple",
                  }}
                >
                  {countries.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.country}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </div>
            {/* Dirección */}
            <div class="espacio-textfield">
              <p> Dirección</p>
              <TextField
                label="Ingrese una dirección"
                size="small"
                value={address}
                onChange={handleAddressChange}
                sx={styles.fullWidthTextField}
              />
            </div>
            <Button
              style={{ marginTop: "15px", borderRadius: "15px", width: "100%" }}
              variant="contained"
              onClick={() => {
                guardarDatos(setActualizar);
              }}
            >
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default EditLateralUsers;
