import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Button,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch } from "react-redux";
import { LoginUser } from "../utils/redux/thunks/login.thunk";
import { setMe } from "../utils/redux/slices/app.slice";
import getCountries from "../services/api/country";
import { useStateContext } from "../contexts/ContextProvider";

const Login = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [form, setForm] = useState("");
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    setDatos();
  }, []);

  async function setDatos() {
    getCountries()
      .then((response) => {
        setCountries(response);
      })
      .catch((e) => console.log(e));
  }

  const { flagChangeCountry, setChangeCountry, setCountry } = useStateContext();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      iniciar();
    }
  };

  const iniciar = async () => {
    console.log(form);
    dispatch(LoginUser(form));
    setCountry(countries[0]?.country);
  };

  const findToken = localStorage.getItem("token")
    ? JSON.parse(localStorage.getItem("token"))
    : false;
  useEffect(() => {
    if (findToken) {
      dispatch(setMe({ user: findToken.user, token: findToken.token }));
    }
  }, [findToken, dispatch]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        background: "#f1f1f1",
      }}
    >
      <Box
        sx={{
          width: 400,
          p: 4,
          borderRadius: 4,
          boxShadow: 1,
          background: "#ffffff",
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Iniciar sesión
        </Typography>
        <TextField
          sx={{ mb: 2 }}
          id="outlined-required"
          label="Correo electrónico"
          name="email"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          variant="outlined"
          fullWidth
        />
        <FormControl sx={{ mb: 2 }} variant="outlined" fullWidth>
          <InputLabel htmlFor="outlined-adornment-password">
            Contraseña
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            name="password"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Contraseña"
          />
        </FormControl>
        <Button variant="contained" fullWidth onClick={iniciar} sx={{ mb: 2 }}>
          Ingresar
        </Button>
      </Box>
    </Box>
  );
};

export default Login;
