import axios from "axios";
import API from "../../../API";
import Swal from "sweetalert2";
import { setMe } from "../slices/app.slice";

export const LoginUser = (form) => (dispatch) => {
  axios
    .post(API.URL + "users/loginDashboard", form)
    .then((response) => {
      localStorage.setItem(
        "token",
        JSON.stringify({ user: response.data.user, token: response.data.token })
      );
      dispatch(setMe({ user: response.data.user, token: response.data.token }));
      Swal.fire({
        icon: "success",
        title: "Bienvenido" + response.data.user.firstName,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload(false);
        }
      });
    })
    .catch((error) => {
      console.error(error);
      Swal.fire({ icon: "error", title: "Login error" });
    });
};
